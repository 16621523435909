<template>
<!--<modal-confirm title="Are you sure?" message="Please confirm you're about to delete the field." ref="confirm-popup" :showHeader="false" submitButtonText="Delete">
<slot></slot>
  </modal-confirm>-->
  <modal-content :width="450" ref="delete-modal" name="delete-modal" :showFooter="false" title="Are you sure you want to delete this field?">
  <div class="pt-3 flex justify-between my-4 mx-3 ">
  <Button class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 w-96" type="button" @click="removeEntity()">Yes</Button>
  <Button class="text-blue-900 bg-white border border-blue-900 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 w-96" type="button" @click="closeModal()">No</Button>
  </div>
  </modal-content>
</template>
<script>
import modalContent from "@shared/modal-content";
// const modalConfirm = () => import("@shared/modal-confirm");

export default {
    props:{
        index: {
            type: Number,
        },
        deleteData: {
            type: Object,
            default: () => {}
        }
    },
    components:{
        modalContent,
        // modalConfirm
    },
    mounted(){
    },
    methods:{
        showModal(){
            this.$refs["delete-modal"].showModal();
        },
         
        removeEntity() {
            this.$emit('removeEntity',this.removeEntity, this.deleteData);
            this.closeModal();
        },
        closeModal() {
            this.$refs["delete-modal"].hideModal();
        }
    }
}
</script>

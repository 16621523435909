export default {
  name: "icon-list",
  props: {
    icons: {
      type: Array,
      default: [],
    },
    onSelect: {
      type: Function,
      default: () => {},
    },
    handleClearCallActiveLog: Function,
    activeIcon: {
      type: String,
      default: "",
    },
    activity_count:{
      type:Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  computed: {
    active() {
      return this.activeIcon;
    },
  },
  methods: {
    getStyles(icon) {
      this.handleClearCallActiveLog()
      this.onSelect(icon);
    },
  },
};

<template>

<div>
    <!-- component -->
<!-- This is an example component -->
      <div class="bg-white rounded-lg overflow-hidden shadow">
        <div class="text-center border-b py-6 px-4">
            <div class="flex justify-center">
                <!-- <img src="" class="rounded-full w-28" alt="profile picture" srcset=""> -->

            </div>
            <div class="p-6 pt-0 flex flex-col gap-2">
                <p class="pt-2 text-lg font-semibold">{{data.name}}</p>
                <p class="text-sm text-gray-600">
                    {{data.primaryEmail}}
                </p>
                <p class="text-gray-600 text-sm"><span class="text-semibold text-gray-800">Age Range:</span> {{data.ageRange}}</p>

            </div>
            <div class="flex flex-col justify-center items-center">
                <h3 class="font-semibold text-lg">Score</h3>
                <RadialProgress :value="data.score" :showTotal="true" :total=100 color="success" :size=120 />
            </div>
        </div>


        <!-- <div class="">
            <div class="bg-gray-200 py-2 flex justify-center items-center">
                Request Details
            </div> 
            <div>
                <div class="w-full flex gap-4 border-b border-gray-200 px-2 py-1 justify-between">
                    <div class="w-1/2 text-gray-700 font-semibold text-sm">
                        Environment
                    </div>
                     <div class="w-1/2 text-gray-500 text-sm">
                        telent Uk
                    </div>
                </div>
                <div class="w-full flex gap-4 border-b border-gray-200 px-2 py-1 justify-between">
                    <div class="w-1/2 text-gray-700 font-semibold text-sm">
                        Data requested
                    </div>
                     <div class="w-1/2 text-gray-500 text-sm">
                        telent Uk
                    </div>
                </div>
                <div class="w-full flex gap-4 border-b border-gray-200 px-2 py-1 justify-between">
                    <div class="w-1/2 text-gray-700 font-semibold text-sm">
                        Data Completed
                    </div>
                     <div class="w-1/2 text-gray-500 text-sm">
                        telent Uk
                    </div>
                </div>
                <div class="w-full flex gap-4 border-b border-gray-200 px-2 py-1 justify-between">
                    <div class="w-1/2 text-gray-700 font-semibold text-sm">
                        Expire Date
                    </div>
                     <div class="w-1/2 text-gray-500 text-sm">
                        telent Uk
                    </div>
                </div>

                
                
            </div>
            
        </div> -->
      </div>
</div>
    
</template>

<style scoped>

</style>

<script>
import RadialProgress from "../../radial-progress"
export default {
    name:"profileCardHooyu",
    props:['data'],
    components:{
        RadialProgress,
    },
}
</script>
<style lang="">
    
</style>

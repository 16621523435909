import CloseIcon from "@/assets/icons/email-admin/icon-crossbt.svg";

export default {
  name: 'Diolog',
  components: {
    CloseIcon
  },
  props: {
    title: String,
    open: Boolean,
    toggleModal: Function,
  },
  watch: {
    open: function (newVal) {
      if (newVal) {
        this.$refs.browsdir.showModal();
      } else {
        this.$refs.browsdir.close();
      }
    },
  }
};

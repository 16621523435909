<template>
<div class="field-modal flex flex-col gap-2">
  <div v-if="title" class="field-modal__label text-sm font-bold">{{title}}</div>
  <label
    class="field-wrapper h-10 rounded-xl flex items-center gap-1.5 px-1 text-sm"
    :class="fieldClass"
  >
    <div
      v-if="$slots.icon"
      class="field-modal__icon rounded-full w-8 h-8 bg-gray-200 flex-shrink-0 flex items-center justify-center"
    >
      <slot name="icon"/>
    </div>
    <div class="field-modal__input w-full">
      <component
        v-if="fieldConfig[type]"
        :is="fieldConfig[type].tag"
        v-bind="{ ...fieldConfig[type].props, ...$attrs }"
        class="w-full relative bg-transparent pl-1"
        :placeholder="placeholder"
        v-model="modelValue"
        :disabled="disabled"
        ref="datepicker"
        @change="handleTime"
      />
      <input
        v-else
        class="w-full relative bg-transparent pl-1"
        :type="type"
        :placeholder="placeholder"
        v-model="modelValue"
        :disabled="disabled"
      />
    </div>
  </label>
</div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';

export default {
  name: 'field-modal',
  props: {
    value: [String, Number, Array, Object],
    placeholder: String,
    title: String,
    type: {
      type: String,
      default: 'text',
    },
    fieldClass: [Array, Object, String],
    disabled: Boolean,
  },
  data() {
    return {
      fieldConfig: {
        'date': {
          tag: DatePicker,
          props: {
            type: 'date',
            format: 'DD MMM YYYY',
            'value-type': 'YYYY-MM-DD',
            'icon-calendar': '',
            'append-to-body': true,
          },
        },
        'time': {
          tag: DatePicker,
          props: {
            type: 'time',
            format:"hh:mm a"

          },
        },
      },
    }
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    }
  },
  methods:{
     handleTime(){
      this.$refs.datepicker.closePopup();

     }
  }
}
</script>

<style lang="scss" scoped>
.field-modal {
  &__label {
    color: #282828;
  }
  &__icon {
    &>* {
      @apply w-3/5 h-3/5;
    }
    * {
      fill: #5D5D67;
    }
  }
}
.field-wrapper {
  background-color: #F2F2F2;
}
::v-deep {
  input {
    padding: 0;
    background: transparent;
    // @apply font-bold;
    color: #282828;
    outline: none;
    border: none;
  }
  .mx-datepicker {
    width: 100%;
  }
    .mx-time-column .mx-time-list::after {

      height: auto;
    }
  .mx-icon-calendar {
    display: none;
  }
}
</style>
<template>
  <label class="search-field relative">
    <Field
      v-bind="$attrs"
      v-model="modelValue"
    />
    <SearchIcon class="search-icon absolute w-5 h-5 right-4" />
  </label>
</template>

<script>
import Field from "@/components/sc-journey/field-modal.vue";
import SearchIcon from "@/assets/icons/sc-journey/search.svg";

export default {
  name: 'search-field',
  components: {
    Field,
    SearchIcon,
  },
  props: ['value'],
  data() {
    return {
    }
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.search-icon {
  transform: translateY(-50%);
  top: 50%;
}
::v-deep {
  input {
    @apply pr-12;
  }
}
</style>
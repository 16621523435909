<template>

<div class="flex-shrink-0 rounded-xl px-4 py-2 text-white-text font-bold shadow" :class="classList">
    {{status}}
</div>
    
</template>

<style scoped>

</style>

<script>
export default {
    name:"statusHooyu",
    props:['status', 'classList']
}
</script>

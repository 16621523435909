<template>
<modal
  name="pdf-view-modal"
  ref="pdf-view-modal"
  width="60%"
  height="65%"
  class="rounded-full"
  v-bind="$attrs"
  v-on="$listeners"
>
  <div class="flex flex-col justify-between min-h-full h-full bg-white">
    <header
      class="modal-header h-10 2xl:h-12 flex items-center justify-between px-10 border-b border-0 border-solid border-gray-200">
      <span class="font-semibold text-base 2xl:text-xl tracking-wide text-white-text flex items-center justify-center gap-3"><span>
        </span> Report </span>
        <CloseIcon class="cursor-pointer w-4 h-4 2xl:w-6 2xl:h-6" @click="closeModal" />
    </header>
    <div class="container flex-grow w-full max-w-full overflow-y-auto scroll-bar bg-card-bg">
        <div class="w-full h-full flex items-center justify-center bg-white" v-if="isLoading">
			<Loader height="16px" width="16px" :loading="isLoading" />
		</div>
        <div v-else class="min-h-full flex">
            <pdfView :urlLink="pdfUrl" />
        </div>
    </div>
    <footer v-if="showFooter" class="flex items-end justify-between py-4 px-7 bg-card-bg">
        <div class="ml-auto relative">
            <button
            type="submit"
            class="transition rounded-lg bg-primary hover:bg-primary-focus focus:bg-primary border-0 text-white text-sm h-9 font-semibold px-4"
            @click="handleSubmit"
            >{{footerBtnText}}</button>
        </div>
    </footer>
  </div>
</modal>
</template>

<script>
// import Button from "@/components/button/index.vue";
import CloseIcon from "@shared/assets/close-icon.svg";
import Loader from "../loader"
// import TimeIcon from "@/assets/icons/dashboard/clock.svg";
// import { validatePhoneNumber } from "@/plugins/functions";
import pdfView from "./index.vue"
export default {
  name: 'pdf-view-modal',
  components: {
    CloseIcon,
    Loader,
    pdfView
  },
  data() {
    return {

    }
  },
  props:{
      pdfUrl:{
          type:String,
          default:""
      },
      isLoading:{
          type:Boolean,
          default:false
      },
      showFooter:{
          type:Boolean,
          default: true
      },
      footerBtnText: {
        type: String,
        default: "Submit"
      }
  },
  mounted(){
      this.closeModal();
  },
  methods: {
    closeModal() {
      this.$modal.hide('pdf-view-modal');
    },
    handleSubmit(){
        this.$emit('pdfviewsubmit')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-header {
  &__icon {
    fill: white;
    * {
      fill: white;
    }
  }
}
</style>
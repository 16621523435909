<template>
  <div class="w-full h-full">
    <div
      class="
        w-full
        py-16
        shadow
        flex
        items-center
        justify-center
        bg-white
        flex-1
        rounded-lg
      "
      v-if="isLoading"
    >
      <Loader
        height="16px"
        width="16px"
        :loading="isLoading"
      />
    </div>
    <div class="w-full h-full flex flex-col gap-6 layout rounded-lg overflow-hidden" v-else>
        <div class="bg-custom-gray border rounded-lg overflow-y-auto" style="width: 700px">
            <div class="flex items-center justify-between py-1.5 bg-blue-600 rounded-t-lg text-white-text px-4 font-bold"> 
                Search Response 
                <div class="w-60">
                    <vue-multiselect 
                        :multiple="false" 
                        :loading="isLoading" 
                        :checkboxes="false" 
                        :taggable="false" 
                        :close-on-select="false" 
                        :clear-on-select="true" 
                        :preserve-search="true" 
                        placeholder="Select Report Version" 
                        :show-labels="false" 
                        :maxHeight="200" 
                        :options="allReportVersions" 
                        selectLabel="" 
                        track-by="id" 
                        :allow-empty="false"
                        label="label"
                        v-model="selectedReportVersion"
                        @input="updateSelectedReport($event)"> 
                    </vue-multiselect>
                </div>
            </div>
            <div class="p-4 overflow-y-auto scroll-bar flex flex-col gap-2">
                <div class="px-2 py-1 bg-white rounded-lg flex justify-between">
                    <span class="font-semibold px-2 w-3/5"> Search Reference </span>
                    <span class="px-2 rounded-lg bg-custom-gray text-center w-2/5"> 
                        <span v-if="transunionData"> {{ transunionData?.data?.SearchResult?.yourreference }} </span>
                    </span>
                </div>

                <div class="px-2 py-1 bg-white rounded-lg flex justify-between">
                    <span class="font-semibold px-2 w-3/5"> Address Confirmation Source </span>
                    <span class="px-2 rounded-lg bg-custom-gray text-center w-2/5">
                       <span v-if="transunionData"> Electoral Roll </span>
                    </span>
                </div>

                <div class="px-2 py-1 bg-white rounded-lg flex justify-between">
                    <span class="font-semibold px-2 w-3/5"> Score (Fair Isacc) </span>
                    <span class="px-2 rounded-lg bg-custom-gray text-center w-2/5"> 
                        <span v-if="transunionData"> {{ transunionData?.data?.SearchResult?.creditreport?.applicant?.creditscores?.creditscore?.[0]?.score?.["#text"] }} </span>
                    </span>
                </div>

                <div class="px-2 py-1 bg-white rounded-lg flex justify-between">
                    <span class="font-semibold px-2 w-3/5"> Currently Insolvent </span>
                    <span class="px-2 rounded-lg bg-custom-gray text-center w-2/5"> 
                        <span v-if="transunionData"> {{ parseInt(transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.bais?.currentlyinsolvent, 10) ? "Yes" : "No" }} </span> 
                        </span>
                </div>

                <div class="px-2 py-1 bg-white rounded-lg flex justify-between">
                    <span class="font-semibold px-2 w-3/5"> Discharged Insolvent (6yrs) </span>
                    <span class="px-2 rounded-lg bg-custom-gray text-center w-2/5"> 
                        <span v-if="transunionData"> {{ transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.bais?.totaldischarged }} </span>
                    </span>
                </div>

                <div class="px-2 py-1 bg-white rounded-lg flex justify-between">
                    <span class="font-semibold px-2 w-3/5"> Number of Active CCJs</span>
                    <span class="px-2 rounded-lg bg-custom-gray text-center w-2/5"> 
                        <span v-if="transunionData"> {{ transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.judgments?.totalactive }} </span>
                    </span>
                </div>

                <div class="px-2 py-1 bg-white rounded-lg flex justify-between">
                    <span class="font-semibold px-2 w-3/5"> Number of Satisfied CCJs</span>
                    <span class="px-2 rounded-lg bg-custom-gray text-center w-2/5"> 
                        <span v-if="transunionData"> {{ transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.judgments?.totalsatisfied }} </span>
                    </span>
                </div>

                <div class="px-2 py-1 bg-white rounded-lg flex justify-between">
                    <span class="font-semibold px-2 w-3/5"> Total Value of Active CCJs </span>
                    <span class="px-2 rounded-lg bg-custom-gray text-center w-2/5"> 
                        <span v-if="transunionData"> {{ transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.judgments?.totalactiveamount }} </span>
                    </span>
                </div>

                <div class="px-2 py-1 bg-white rounded-lg flex justify-between">
                    <span class="font-semibold px-2 w-3/5"> Undeclared Addresses</span>
                    <span class="px-2 rounded-lg bg-custom-gray text-center w-2/5"> 
                        <span v-if="transunionData"> {{ transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.links?.totalundecaddressessearched }} </span>  
                    </span>
                </div>

                <div class="px-2 py-1 bg-white rounded-lg flex justify-between">
                    <span class="font-semibold px-2 w-3/5"> Undeclared Aliases</span>
                    <span class="px-2 rounded-lg bg-custom-gray text-center w-2/5"> 
                        <span v-if="transunionData"> {{ transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.links?.totalundecaliases }} </span>
                    </span>
                </div>

                <div class="px-2 py-1 bg-white rounded-lg flex justify-between">
                    <span class="font-semibold px-2 w-3/5"> Notice of Correction Present</span>
                    <span class="px-2 rounded-lg bg-custom-gray text-center w-2/5"> 
                        <span v-if="transunionData"> {{ parseInt(transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.notices?.nocflag, 10) ? "Yes": "No" }} </span>
                    </span>
                </div>

                <div class="px-2 py-1 bg-white rounded-lg flex justify-between">
                    <span class="font-semibold px-2 w-3/5"> Number of Notices of Dispute</span>
                    <span class="px-2 rounded-lg bg-custom-gray text-center w-2/5"> 
                        <span v-if="transunionData"> {{ transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.notices?.totaldisputes }} </span>
                    </span>
                </div>
            </div>
        </div>

        <div class="flex items-center" style="width: 700px">
            <button @click="runTransUnion" class="border border-primary text-primary py-2 px-3 rounded-lg" v-if="!addressLinks?.length && checkPermission('case.read_write_check_screen')">
                Run Check
            </button>
            <button @click="handleModal" :disabled="!transunionData" 
            :class="!transunionData ? 'cursor-auto bg-gray-200 border-gray-200 text-gray-400': 'border-primary text-primary '" 
            class="border py-2 px-3 rounded-lg ml-auto">
                View Report
            </button>
        </div>
        <div class="bg-white rounded-lg border border-1 border-gray-200 address-links-table" 
        style="width: 700px" 
        v-if="addressLinks?.length">
            <div class="flex items-center">
                <div class="font-semibold border-r border-gray-200 px-4 py-2 w-3/4"> Address </div>
                <div class="font-semibold px-4 py-2 w-1/4">Verify Address</div>
            </div>

            <div class="border-t border-gray-200 flex items-center" v-for="(address, ai) in addressLinks" :key="address.id+'add_links'+ai">
                <div class="border-r border-gray-200 px-4 py-2 w-3/4"> {{ address.address }} </div>
                <div class="border-r border-gray-200 px-4 py-2 w-1/4">
                    <div class="mx-auto w-5">
                        <Checkbox
                            :disabled="address.verify_status"
                            :title="!address.verify_status ? 'Address verified': ''"
                            :value="selectedLinks.includes(address.id)"
                            @input="updateSelectedAddress(address, $event)"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="flex items-center" style="width: 700px" v-if="addressLinks?.length && checkPermission('case.read_write_check_screen')">
            <button @click="verifyAddresses" :disabled="!selectedAddressLinks.length" 
            :class="!selectedAddressLinks.length ? 'border-gray-200 text-gray-200': 'border-primary text-primary '"
            class="border py-2 px-3 rounded-lg ml-auto">
                Run Check
            </button>
        </div>
      <!-- <div class="flex justify-center items-center min-h-full flex-1">
        No result Found
      </div> -->
    </div>

    <modal name="transunion-report" width="65%" height="90%">
      <div class="header bg-primary py-4 px-8 text-white-text text-lg font-bold flex items-center justify-between">
        <span>Report</span>
        <font-awesome-icon
          icon="times"
          class="cursor-pointer"
          @click="$modal.hide('transunion-report')"
        />
      </div>
      <div class="w-full h-full flex items-center justify-center bg-white flex-1" v-if="isLoading">
        <Loader
          height="16px"
          width="16px"
          :loading="isLoading"
        />
      </div>
      <div class="w-full bg-white flex-1  flex-col xl:flex-row  justify-center flex overflow-y-auto scroll-bar" style="height: calc(100% - 120px)" v-else>
        <figure class="px-4 pt-28 xl:pt-8 self-center xl:self-start  mb-4 xl:mb-0  max-w-xs" v-if="getTULogoLink">
            <img :src="getTULogoLink" alt="logo" class="" />
        </figure>
        <div class="bg-white flex-1 self-center max-w-xl w-2/3 p-4 h-full">
            <div class="font-semibold border-b border-gray-300 py-2">
                <div class="flex gap-2 items-center text-lg">
                    <span> Credit Report </span>
                    <span> : </span>
                    <span class="font-bold">
                        {{ transunionData?.data?.SearchResult?.creditreport?.applicant?.["@reporttitle"] }}
                    </span>
                </div>
            </div>

            <!-- Input Details -->
            <div class="border-b border-gray-300 py-2">
                <span class="font-semibold"> Input Details </span>
                <div class="flex gap-1">
                    <div class="w-1/4 text-red-800 font-semibold">Name</div>
                    <div class="font-semibold uppercase">
                        {{ transunionData?.data?.SearchResult?.creditrequest?.applicant?.name?.title }} 
                        {{ transunionData?.data?.SearchResult?.creditrequest?.applicant?.name?.forename }}
                        {{ transunionData?.data?.SearchResult?.creditrequest?.applicant?.name?.othernames }}
                        {{ transunionData?.data?.SearchResult?.creditrequest?.applicant?.name?.surname }}
                    </div>
                </div>
                <div class="flex gap-1">
                    <div class="w-1/4 text-red-800 font-semibold">Current Address</div>
                    <div class="font-semibold uppercase">
                        {{ transunionData?.data?.SearchResult?.creditreport?.applicant?.addressconfs?.addressconf?.[0]?.address?.["#text"] }}
                    </div>
                </div>
                <div class="flex gap-1">
                    <div class="w-1/4 text-red-800">Date of Birth</div>
                    <div class="">
                        {{ getFormattedDate(transunionData?.data?.SearchResult?.creditrequest?.applicant?.dob, 'dd/MM/yyyy') }}
                    </div>
                </div>
                <div class="flex gap-1">
                    <div class="w-1/4 text-red-800">Credit Search Ref</div>
                    <div class="">
                        {{ transunionData?.data?.SearchResult?.creditreport?.["@searchid"] }}
                    </div>
                </div>
                <div class="flex gap-1">
                    <div class="w-1/4 text-red-800">Date & Time of Search</div>
                    <div class="">
                        {{ getFormattedDate(transunionData?.data?.SearchResult?.jobdetails?.searchdate, 'dd/MM/yyyy hh:mm:ss') }}
                    </div>
                </div>
            </div>

            <!-- Overall Summary -->
            <div class="border-b border-gray-300 py-2 flex flex-col">
                <span class="font-semibold"> Overall Summary </span>
                <span class="font-semibold pl-2"> Address Confirmation </span>
                <div class="flex w-full">
                    <div class="w-1/2 font-semibold underline">Address</div>
                    <div class="w-1/4 font-semibold underline">Level</div>
                    <div class="w-1/4 font-semibold underline">Source</div>
                </div>
                <div class="flex w-full" v-for="(address, ai) in transunionData?.data?.SearchResult?.creditreport?.applicant?.addresslinks?.addresses?.address" :key="'indi_address'+ai">
                    <div class="w-1/2 text-red-800 flex"> 
                        <span class="font-bold w-12"> {{ getAddressType(address)?.short_type }} </span>
                        {{ address?.["#text"] }}
                    </div>
                    <div class="w-1/4 text-red-800 font-semibold">Individual</div>
                    <div class="w-1/4 text-red-800 font-semibold">Electoral Roll</div>
                </div>
                <span class="font-semibold pl-2"> Public Information </span>
                <div>
                    <div class="flex gap-1 w-full justify-between">
                        <span class="text-red-800"> Current BAI Record </span>
                        <span class="text-red-500 font-bold" v-if="parseInt(transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.bais?.currentlyinsolvent, 10)"> 
                            Yes
                        </span>
                        <span v-else> No </span>
                    </div>
                    <div class="flex gap-1 w-full justify-between">
                        <span class="text-red-800"> Currently Restricted </span>
                        <span class="text-red-500 font-bold" v-if="parseInt(transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.bais?.restricted, 10)"> 
                            Yes
                        </span>
                        <span v-else> No </span>
                    </div>
                    <div class="flex gap-1 w-full justify-between">
                        <span class="text-red-800"> Number of Discharged BAIs in Last 6 Years </span>
                        <span> {{ transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.bais?.totaldischarged }} </span>
                    </div>
                    <div class="flex gap-1 w-full justify-between">
                        <span class="text-red-800"> Number of Active Judgments </span>
                        <span class="text-red-500 font-bold"> {{ transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.judgments?.totalactive }} </span>
                    </div>
                    <div class="flex gap-1 w-full justify-between">
                        <span class="text-red-800"> Number of Satisfied Judgments </span>
                        <span> {{ transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.judgments?.totalsatisfied }} </span>
                    </div>
                    <div class="flex gap-1 w-full justify-between">
                        <span class="text-red-800"> Total Value of Active Judgments </span>
                        <span> £ {{ transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.judgments?.totalactiveamount }} </span>
                    </div>
                </div>
                <span class="font-semibold pl-2"> Credit Searches </span>
                <div>
                    <div class="flex gap-1 w-full justify-between">
                        <span class="text-red-800"> NB. These counts are based on checking credit applications and business searches </span>
                    </div>
                    <div class="flex gap-1 w-full justify-between">
                        <span class="text-red-800"> Number of Searches in Last 3 Months </span>
                        <span> {{ transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.searches?.totalsearches3months }} </span>
                    </div>
                    <div class="flex gap-1 w-full justify-between">
                        <span class="text-red-800"> Number of Searches in Last 12 Months </span>
                        <span> {{ transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.searches?.totalsearches12months }} </span>
                    </div>
                    <div class="flex gap-1 w-full justify-between">
                        <span class="text-red-800"> Number of Home Credit Searches in Last 3 Months </span>
                        <span> {{ transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.searches?.totalhomecreditsearches3months }} </span>
                    </div>
                </div>
                <span class="font-semibold pl-2"> Undeclared Links </span>
                <div>
                    <div class="flex gap-1 w-full justify-between">
                        <span class="text-red-800">Number of Undeclared Addresses – Searched </span>
                        <span> {{ transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.links?.totalundecaddressessearched }} </span>
                    </div>
                    <div class="flex gap-1 w-full justify-between">
                        <span class="text-red-800"> Number of Undeclared Addresses – Not Searched </span>
                        <span> {{ transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.links?.totalundecaddressesunsearched }} </span>
                    </div>
                    <div class="flex gap-1 w-full justify-between">
                        <span class="text-red-800"> Number of Undeclared Aliases </span>
                        <span> {{ transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.links?.totalundecaliases }}   </span>
                    </div>
                </div>
                <span class="font-semibold pl-2"> Notices </span>
                <div>
                    <div class="flex gap-1 w-full justify-between">
                        <span class="text-red-800"> Notice of Correction Present </span>
                        <span> {{ parseInt(transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.notices?.nocflag, 10) ? "Yes" : "No" }}   </span>
                    </div>
                    <div class="flex gap-1 w-full justify-between">
                        <span class="text-red-800"> Number of Notices of Dispute </span>
                        <span> {{ transunionData?.data?.SearchResult?.creditreport?.applicant?.summary?.notices?.totaldisputes }}   </span>
                    </div>
                </div>
            </div>

            <!-- Credit Score -->
            <div class="border-b border-gray-300 py-2 justify-between flex">
                <span class="text-red-800 font-semibold"> Credit Score </span>
                <span class="font-bold">
                    {{ transunionData?.data?.SearchResult?.creditreport?.applicant?.creditscores?.creditscore?.[0]?.score?.["#text"] }}
                </span>
            </div>

            <!-- Residency Confirmation -->
            <!-- <div class="border-b border-gray-300 py-2">
                <span class="font-semibold text-blue-600"> Residency Confirmation </span>
                <div class="flex gap-1">
                </div>
            </div> -->

            <!-- Electoral Roll -->
            <div class="border-b border-gray-300 py-2">
                <span class="font-semibold text-blue-600"> Electoral Roll </span>
                <div class="flex flex-col" v-for="(add, ai) in transunionData?.data?.SearchResult?.creditreport?.applicant?.addressconfs?.addressconf" :key="'address'+ai">
                    <div class="flex gap-1">
                        <span class="w-1/3 text-red-800 font-semibold"> {{ getAddressType(add?.address)?.type }} </span>
                        <span class="w-2/3 font-semibold">
                            {{ add?.address?.["#text"] }}
                        </span>
                    </div>
                    <div class="flex gap-1" v-for="address in  add?.resident" :key="address?.['@currentname']">
                        <span class="w-1/3"> {{ address.name }} </span>
                        <span class="flex w-2/3">
                            <span class="w-1/3"> 
                                {{ getFormattedDate(address.startdate, 'MM/yyyy') }} to 
                                <span v-if="address.enddate"> {{ getFormattedDate(address.enddate, 'MM/yyyy') }} </span>
                                <span v-else> date </span>
                            </span>
                            <span class="w-1/3"> Not Opted Out </span>
                            <span class="w-1/3"> Annual Electoral Roll </span>
                        </span>
                    </div>
                </div>

                <div class="flex flex-col" v-for="(add, ai) in transunionData?.data?.SearchResult?.creditreport?.applicant?.addresslinks?.addresses?.address" :key="'add'+ai">
                    <div class="flex gap-1" v-if="add?.['@navlinkid']">
                        <span class="w-1/3 text-red-800 font-semibold"> {{ getAddressType(add)?.type }} </span>
                        <span class="w-2/3 font-semibold">
                            {{ add?.["#text"] }}
                        </span>
                    </div>
                </div>
            </div>
            
            <!-- Public Information -->
            <div class="border-b border-gray-300 py-2">
                <span class="font-semibold text-blue-600"> Public Information </span>
                <div class="flex flex-col gap-1 w-full" v-if="transunionData?.data?.SearchResult?.creditreport?.applicant?.bais">
                    <div class="w-full" v-for="(bai, bi) in transunionData?.data?.SearchResult?.creditreport?.applicant?.bais?.bai" :key="'bai'+bi">
                        <div class="flex gap-1">
                            <div class="font-semibold uppercase">
                                {{ bai.name }}
                            </div>
                        </div>
                        <div class="flex">
                            <div class="font-semibold w-1/3"> {{ getAddressType(bai?.address)?.type }} </div>
                            <div class="font-semibold uppercase">
                                {{ bai?.address?.["#text"] }}
                            </div>
                        </div>
                        <span class="font-semibold "> {{ getBAIType(bai?.ordertype) }} </span>
                        <div class="flex w-full gap-6">
                            <div class="flex gap-1 w-1/2">
                                <div class="w-1/2 text-red-800">Case Ref</div>
                                <div class="">
                                    {{ bai.caseref }}
                                </div>
                            </div>
                            <div class="flex gap-1 w-1/2">
                                <div class="w-1/2 text-red-800">Case Year</div>
                                <div class="">
                                    {{ bai.caseyear }}
                                </div>
                            </div>
                        </div>
                        <div class="flex w-full gap-6">
                        <div class="flex gap-1 w-1/2">
                                <div class="w-1/2 text-red-800">Date of Arrangement</div>
                                <div class="">
                                    {{ getFormattedDate(bai?.orderdate, 'dd/MM/yyyy') }}
                                </div>
                            </div>
                            <div class="flex gap-1 w-1/2">
                                <div class="w-1/2 text-red-800">Court</div>
                                <div class="">
                                    {{ bai.courtname }}
                                </div>
                            </div>
                        </div>
                        <div class="flex w-full gap-6">
                            <div class="flex gap-1 w-1/2">
                                <div class="w-1/2 text-red-800">Status</div>
                                <div class="">
                                    {{ getBAIStatus(bai.currentstatus, bai.ordertype) }}
                                </div>
                            </div>
                            <div class="flex gap-1 w-1/2">
                                <div class="w-1/2 text-red-800" v-if="getBAIType(bai?.ordertype) === 'IVA'">Completion Date</div>
                                <div class="w-1/2 text-red-800" v-else>Discharge Date</div>
                                <div class="">
                                    {{ getFormattedDate(bai.dischargedate, "dd/MM/yyyy") }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex gap-1 text-red-800" v-else>
                    There are no BAIs associated with this individual.
                </div>

                <div class="flex flex-col gap-1 w-full" v-if="transunionData?.data?.SearchResult?.creditreport?.applicant?.judgments">
                    <div class="w-full" v-for="(judgement, ji) in transunionData?.data?.SearchResult?.creditreport?.applicant?.judgments?.judgment" :key="'judgement'+ji">
                        <div class="flex gap-1">
                            <div class="font-semibold uppercase">
                                {{ judgement.name }}
                            </div>
                        </div>
                        <div class="flex">
                            <div class="font-semibold w-1/3">Current Address</div>
                            <div class="font-semibold uppercase">
                                {{ judgement?.address?.["#text"] }}
                            </div>
                        </div>
                        <span class="font-semibold "> County Court Judgement </span>
                        <div class="flex w-full gap-6">
                            <div class="flex gap-1 w-1/2">
                                <div class="w-1/2 text-red-800">Amount</div>
                                <div class="">
                                    £ {{ judgement.amount }}
                                </div>
                            </div>
                            <div class="flex gap-1 w-1/2">
                                <div class="w-1/2 text-red-800">Case Number</div>
                                <div class="">
                                    {{ judgement.casenumber }}
                                </div>
                            </div>
                        </div>
                        <div class="flex w-full gap-6">
                            <div class="flex gap-1 w-1/2">
                                <div class="w-1/2 text-red-800">Judgement Date</div>
                                <div class="">
                                    {{ getFormattedDate(judgement?.judgmentdate, 'dd/MM/yyyy') }}
                                </div>
                            </div>
                            <div class="flex gap-1 w-1/2">
                                <div class="w-1/2 text-red-800">Court</div>
                                <div class="">
                                    {{ judgement.courtname }}
                                </div>
                            </div>
                        </div>
                        <div class="flex w-full gap-6">
                            <div class="flex gap-1 w-1/2">
                                <div class="w-1/2 text-red-800">Status</div>
                                <div class="">
                                    {{ getJudgementStatus(judgement.status) }}
                                </div>
                            </div>
                            <div class="flex gap-1 w-1/2" v-if="judgement?.datesatisfied">
                                <div class="w-1/2 text-red-800">Satisfied Date</div>
                                <div class="">
                                    {{ getFormattedDate(judgement?.datesatisfied, 'dd/MM/yyyy') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex gap-1 text-red-800" v-else>
                    There are no CCJs associated with this individual.
                </div>
            </div>

            <!-- Notice Of Correction -->
            <div class="border-b border-gray-300 py-2">
                <span class="font-semibold text-blue-600"> Notice Of Correction </span>
                <div class="flex flex-col gap-1 w-full" v-if="transunionData?.data?.SearchResult?.creditreport?.applicant?.nocs">
                    <div class="w-full" v-for="(notice, ni) in transunionData?.data?.SearchResult?.creditreport?.applicant?.nocs?.noc" :key="'notice'+ni">
                        <div class="flex gap-1">
                            <div class="font-semibold uppercase">
                                {{ notice.name }}
                            </div>
                        </div>
                        <div class="flex gap-1">
                            <div class="font-semibold w-1/3">Current Address</div>
                            <div class="font-semibold uppercase">
                                {{ notice?.address?.["#text"] }}
                            </div>
                        </div>
                        <div class="flex w-full gap-6">
                            <div class="flex gap-1 w-1/2">
                                <div class="w-1/2 text-red-800">Date of Correction</div>
                                <div class="">
                                    {{ getFormattedDate(notice?.dateraised, 'dd/MM/yyyy') }}
                                </div>
                            </div>
                            <div class="flex gap-1 w-1/2">
                                <div class="w-1/2 text-red-800">Reference Number</div>
                                <div class="">
                                    {{ notice.refnum }}
                                </div>
                            </div>
                        </div>
                        <div class="flex gap-1">
                            <div class="w-1/4 text-red-800 mr-10">Text</div>
                            <div class="">
                                {{ notice.text }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex gap-1 text-red-800" v-else>
                    There is no Notice of Correction associated with this individual.
                </div>
            </div>

            <!-- Links -->
            <div class="border-b border-gray-300 py-2">
                <span class="font-semibold text-blue-600 font-semibold"> Links </span>
                <div class="flex flex-col px-2 gap-1">
                    <div class="flex gap-1 text-blue-600 font-semibold">Address Links</div>
                    <div class="flex flex-col gap-1 w-full" v-if="transunionData?.data?.SearchResult?.creditreport?.applicant?.addresslinks">
                        <span class="pl-2 text-blue-600"> Address Details </span>
                        <div class="w-full flex gap-6" v-for="(address, ai) in transunionData?.data?.SearchResult?.creditreport?.applicant?.addresslinks?.addresses?.address" :key="'address'+ai">
                            <span class="w-3"> {{ ai+1 }} </span>
                            <div class="flex gap-1 w-full">
                                <div class="font-semibold w-1/3">
                                    <span> {{ getAddressType(address)?.type  }} </span>
                                </div>
                                <div class="font-semibold uppercase">
                                    {{ address?.["#text"] }}
                                </div>
                            </div>
                        </div>

                        <span class="pl-2 text-blue-600"> Link Details </span>
                        <div class="flex gap-6 w-full" v-for="(link, li) in transunionData?.data?.SearchResult?.creditreport?.applicant?.addresslinks?.links?.link" :key="'link'+li">
                            <span class="w-3"> {{ li+1 }} </span>
                            <div class="w-full">
                                <div class="flex w-full gap-6">
                                    <div class="flex gap-1 w-1/2">
                                        <div class="text-red-800"> From </div>
                                        <div class="font-semibold uppercase">
                                            {{ getAddressFromID(link?.["@from"]) }}
                                        </div>
                                    </div>
                                    <div class="flex gap-1 w-1/2">
                                        <div class="w-56 text-red-800"> Most Recent Source of Link </div>
                                        <div class="font-semibold">
                                            {{ link?.supplierdetails?.suppliertypecode !== "CA" || "Callcredit" }}
                                        </div>
                                    </div>
                                </div>
                                <div class="flex w-full gap-6">
                                    <div class="flex gap-1 w-1/2">
                                        <div class="text-red-800"> To </div>
                                        <div class="font-semibold uppercase">
                                            {{ getAddressFromID(link?.["@to"]) }}
                                        </div>
                                    </div>
                                    <div class="flex gap-1 w-1/2">
                                        <div class="w-56 text-red-800"> Last Confirmation Date </div>
                                        <div class="font-semibold uppercase">
                                            {{ getFormattedDate(link?.lastconfdate, 'dd/MM/yyyy') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="flex w-full gap-6">
                                    <div class="flex gap-1 w-1/2"></div>
                                    <div class="flex gap-1 w-1/2">
                                        <div class="w-56 text-red-800"> Earliest Confirmation Date </div>
                                        <div class="font-semibold uppercase">
                                            {{ getFormattedDate(link?.creationdate, 'dd/MM/yyyy') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex gap-1 text-red-800" v-else>
                        There are no Address Links associated with this individual.
                    </div>
                </div>
                <div class="flex flex-col px-2 gap-1">
                    <div class="flex gap-1 text-blue-600 font-semibold">Associate Links</div>
                    <div class="flex flex-col gap-1 w-full" v-if="transunionData?.data?.SearchResult?.creditreport?.applicant?.associatelinks">
                        
                    </div>
                    <div class="flex gap-1 text-red-800" v-else>
                        Associate information was not available for this individual.
                    </div>
                </div>
                <div class="flex flex-col px-2 gap-1">
                    <div class="flex gap-1 text-blue-600 font-semibold">Alias Links</div>
                    <div class="flex flex-col gap-1 w-full" v-if="transunionData?.data?.SearchResult?.creditreport?.applicant?.aliaslinks">
                        <div class="flex flex-col gap-1 w-full" v-for="(link, li) in transunionData?.data?.SearchResult?.creditreport?.applicant?.aliaslinks?.aliaslink" :key="li">  
                            <div class="w-full">
                                {{link.name}} also known as {{ link.alias }}
                                <div class="flex w-full gap-6">
                                    <div class="flex gap-1 w-1/2">
                                        <div class="w-56 text-red-800"> Most Recent Source of Link </div>
                                        <div class="font-semibold">
                                            {{ link?.supplierdetails?.suppliertypecode !== "CA" || "Callcredit" }}
                                        </div>
                                    </div>
                                    <div class="flex gap-1 w-1/2">
                                        <div class="w-56 text-red-800"> Last Confirmation Date </div>
                                        <div class="font-semibold uppercase">
                                            {{ getFormattedDate(link?.lastconfdate, 'dd/MM/yyyy') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="flex w-full gap-6">
                                    <div class="flex gap-1 w-1/2">
                                        <div class="w-56 text-red-800"> Earliest Confirmation Date </div>
                                        <div class="font-semibold uppercase">
                                            {{ getFormattedDate(link?.creationdate, 'dd/MM/yyyy') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex gap-1 text-red-800" v-else>
                        There are no Alias Links associated with this individual.
                    </div>
                </div>
            </div>

            <!-- Demographic Profile -->
            <div class="border-b border-gray-300 py-2">
                <span class="font-semibold text-blue-600"> Demographic Profile </span>
                <div class="flex gap-1 font-semibold">Property</div>
                <div class="flex gap-1"> 
                    Compared with the national average (equal to 100), the house price index within this area is 
                    {{transunionData?.data?.SearchResult?.creditreport?.applicant?.demographics2006?.property?.priceindex}}. 
                    <br/>
                    The typical council tax band in these areas is 
                    {{transunionData?.data?.SearchResult?.creditreport?.applicant?.demographics2006?.property?.ctaxband}}.
                </div>
            </div>
        </div>
        <!-- <Empty text="Report not found" v-else /> -->
      </div>
    </modal>
  </div>
</template>


<script>
import Loader from "../../loader/";
import axios from "@/axios";
import { DateTime } from "luxon";
import VueMultiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Checkbox from "@/components/checkbox-alt";
import {checkPermission} from "@shared/utils/functions"

export default {
  name: "TransunionCheckResultsViewer",
  components: {
    Loader,
    VueMultiselect,
    Checkbox,
  },
  props: ["caseId"],
  data() {
    return {
        isLoading: false,
        transunionData: null,
        allReportVersions: [],
        allReportList: [],
        selectedReportVersion: null,
        addressLinks: [],
        selectedAddressLinks: [],
        selectedLinks:[],
    };
  },
  async created() {
  },
  mounted() {
    this.runCheck();
  },
  methods: {
      checkPermission,
    async runTransUnion() {
        this.isLoading = true;
        try {
            let url = `/integrations/transunion/${this.caseId}`;
            let data = await axios.post(url);
            if (data && data.status === 200) {
                this.runCheck();
            }
        } catch (error) {
            this.$toast.error(
            error.response.data.detail ||
                "Something went wrong. Please try again!"
            );
        }
        this.isLoading = false;
    },
    async runCheck() {
        if (this.caseId) {
            await this.getCheckResults();
            await this.getAddressLinks();
        }
    },
    async getCheckResults() {
        this.isLoading = true;
        try {
            let url = `/integrations/transunion/${this.caseId}`;
            let { data } = await axios.get(url);
            if (data && data.data) {
                this.allReportList = data?.data?.reverse() ?? [];
                this.allReportVersions = [];
                this.allReportVersions = this.allReportList.map((el,idx) => ({label:`Report Version ${idx+1}`, id: idx })) || []
                this.selectedReportVersion = this.allReportVersions[0];
                if (this.selectedReportVersion) this.transunionData = this.allReportList[this.selectedReportVersion.id];
            } else if (data && data.status) {
                this.$toast.warning('No Data Found')
            }
        } catch (error) {
            this.$toast.error(
            error.response.data.detail ||
                "Something went wrong. Please try again!"
            );
        }
        this.isLoading = false;
    },
    async getAddressLinks() {
        this.isLoading = true;
        try {
            let url = `/integrations/transunion/address-link/${this.caseId}`;
            let { data } = await axios.get(url);
            // to do : remove this dummy link
            if (data && data.data) {
                this.addressLinks = data?.data ?? [];
                this.selectedLinks = this.selectedLinks.concat(data.data.filter((el) => 
                    el.verify_status)
                    .map((el) => el.id)
                );
            } 
        } catch (error) {
            this.$toast.error(
            error.response.data.detail ||
                "Something went wrong. Please try again!"
            );
        }
        this.isLoading = false;
    },
    updateSelectedReport(version) {
        this.selectedReportVersion = version;
        this.transunionData = this.allReportList[version.id];
    },
    updateSelectedAddress(address, event) {
        if (event) {
            if (!this.selectedAddressLinks.some(add => add.id === address.id)) {
                this.selectedLinks.push(address.id)
                this.selectedAddressLinks.push(address);
            }
        } else {
            this.selectedAddressLinks = this.selectedAddressLinks.filter((add) => add !== address);
            this.selectedLinks = this.selectedLinks.filter( el => el !== address.id)

        }
    },
    async verifyAddresses() {
        this.isLoading = true;
        try {
            let url = `/integrations/transunion/address-link/verify`;
            let { data } = await axios.post(url, {
                search_id: this.selectedAddressLinks[0].search_id,
                case_id: this.caseId,
                nav_link_ids: this.selectedAddressLinks.map((el) => el.navlink_id),
            });
            if (data) {
                this.$toast.success(data.message || 'Address verified successfully!')
            }
            await this.runCheck();
        } catch (error) {
            this.$toast.error(
            error.response.data.detail ||
                "Something went wrong. Please try again!"
            );
        }
        this.isLoading = false;

    },
    handleModal() {
      this.$modal.show("transunion-report");
    },
    getFormattedDate(date, format) {
        return DateTime.fromISO(date).toFormat(format);
    },
    getAddressFromID(id) {
        return id ? this.transunionData?.data?.SearchResult?.creditreport?.applicant?.addresslinks?.addresses?.address.find((add) => 
            add?.["@addressid"] === id)?.["#text"]: "";
    },
    getJudgementStatus(status) {
        let value = '';
        switch (status) {
            case 'JG':
                value = 'Active';
                break;
            case 'SS':
                value = 'Satisfied';
                break;
            default:
                value = status;
                break;
        }
        return value;
    },
    getBAIType(type) {
       const values = {
           "IV": "IVA",
           "BO": "Bankruptcy Order",
           "TD": "Trust Deed",
           "ES": "Sequestration",
       };
       return values?.[type];
    },
    getBAIStatus(status, type) {
        let value = '';
        switch (status) {
            case 'A':
                value = 'Active';
                break;
            case 'D':
                value = type === 'IV' ? 'Completed': 'Discharged';
                break;
            default:
                break;
        }
        return value;
    },
    getAddressType(address) {
        let type = '';
        let short_type = '';
        if (address?.["@current"] === "1") {
            type = 'Current Address';
            short_type = 'C';
        } else {
            if (address?.["@undeclaredaddresstype"] === "3") {
                type= 'Undeclared Previous Address';
                short_type = 'UP';
            } else if (address?.["@undeclaredaddresstype"] === "5") {
                type= 'Undeclared Address';
                short_type = 'U';
            }
        }
        return { 
            type,
            short_type,
         };
    }
  },
  computed: {
      getTULogoLink() {
          let src = ""
          if (this.allReportList && this.allReportList.length) src = this.allReportList[0].data?.logo_url
          return src
      }
  }
};
</script>

<style scoped>
.bg-custom-gray {
    background-color: #f2f2f2;
}
</style>


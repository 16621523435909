<template>
<div
    :class="['card py-4 px-4 2xl:px-8 border-solid transition-all', {
        'card--compact': selected,
        'card--selected': selected && selected.id === data.id,
        'hover:shadow hover:border-primary-focus border': !selected
    }]"
>
    <div class="compact-wrapper cursor-pointer flex-1" @click="$emit('clicked',data.id)">
        <div class="controls-compact ">
            <CheckboxSide
                class="checkbox-side"
                disabled
                @click.native.stop
                @change="$emit('check', data.id)"
                :value="data.checked"
            />
        </div>
        <div class="compact-wrapper__general gap-8 lg:gap-16">
            <div class="general gap-1">
                <div class="label gap-1">
                    <div
                        class="name text-sm 2xl:text-base"
                    >{{ data.name }}</div>
                    <!-- <div class="icons gap-1">
                        <div class="count text-xs 2xl:text-sm w-4 h-4 2xl:w-5 2xl:h-5 bg-primary">{{ data.notofocations_count }}</div>
                        <infoButtonIcon class="info w-4 h-4 2xl:w-5 2xl:h-5"/>
                    </div> -->
                </div>
                <div class="status">
                    <SelectColored
                        class="text-xxs 2xl:text-xs w-24 h-6 flex items-center"
                        @click.native.stop
                        :options="statusList"
                        :value="data.status"
                        @input="$emit('statusChanged',$event, data.id)"
                        :key="data.status"
                    />
                </div>
            </div>
            <div
                class="refree gap-3 2xl:gap-4 py-2 px-4 2xl:py-4 2xl:px-5"
            >
                <profileIcon class="profileIcon"/>
                <div class="data">
                    <div class="title text-xs 2xl:text-sm">
                        <span class="prefix text-primary">Referee </span>
                        <span class="name">{{ data.refree.name }}</span>
                    </div>
                    <div class="contacts gap-2 text-xxs 2xl:text-xs">
                        <div class="phone gap-1 2xl:gap-2">
                            <phoneIcon class="phone__icon text-primary fill-current" />
                            <div class="phone__value">{{ data.refree.phone }}</div>
                        </div>
                        <div class="email gap-1 2xl:gap-2"> 
                            <emailIcon class="email__icon text-primary fill-current"/>
                            <div class="email__value" :class="data.bounceback_status == true ? 'bounceBackMail' : ''" :title="getEmailTitle" v-if="data.refree.email.length > 35">{{ `${data. refree.email.substring (0,35)+'...'}` }}</div>
                            <div class="email__value" :class="data.bounceback_status == true ? 'bounceBackMail' : ''" v-else>{{ data.refree.email }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="actions gap-2 2xl:gap-4">
        <div
            class="actions__email h-9 w-9 2xl:h-12 2xl:w-12"
            @click.stop="$emit('email')"
            title="Email"
        ><emailIcon class="icon w-5 h-5 2xl:w-6 2xl:h-6"/></div>
        <div
            class="actions__email h-9 w-9 2xl:h-12 2xl:w-12"
            @click.stop="$emit('file-manager')"
            ><file-manager class="h-5 2xl:w-6 2xl:h-6"/>
        </div>
        <div
            class="actions__phone h-9 w-9 2xl:h-12 2xl:w-12"
            @click.stop="$emit('logCall')"
            title="Call"
        ><phoneIcon class="icon w-5 h-5 2xl:w-6 2xl:h-6"/></div>
        <div
            class="actions__write h-9 w-9 2xl:h-12 2xl:w-12"
            @click.stop="$emit('note')"
            title="Note"
        ><writingIcon class="icon w-5 h-5 2xl:w-6 2xl:h-6"/></div>
        <div
            class="actions__task h-9 w-9 2xl:h-12 2xl:w-12"
            @click.stop="$emit('task')"
            title="Task"
        ><taskIcon class="icon w-5 h-5 2xl:w-6 2xl:h-6"/></div>
        <div
            class="actions__history h-9 w-9 2xl:h-12 2xl:w-12"
            @click.stop="$emit('history')"
            title="History"
        ><historyIcon class="icon w-5 h-5 2xl:w-6 2xl:h-6"/></div>
        <!-- <div
            class="actions__others h-9 w-9 2xl:h-12 2xl:w-12"
            @click.stop="$emit('other')"
        ><othersIcon class="icon w-5 h-5 2xl:w-6 2xl:h-6"/></div> -->
    </div>
    <!-- <div class="controls right-4 2xl:right-10">
        <Checkbox
            @click.native.stop
            @change="$emit('check', data.id)"
            :value="data.checked"
        />
    </div> -->
</div>
</template>

<script>
// import Checkbox from "@/components/sc-journey/checkbox.vue";
import CheckboxSide from "@/components/sc-journey/checkbox-side.vue";
import SelectColored from "@shared/sc-journey/select-colored";

// import infoButtonIcon from '@/assets/icons/sc-journey/info-button.svg';
import profileIcon from '@/assets/icons/sc-journey/profile.svg';
import phoneIcon from '@/assets/icons/sc-journey/phone.svg';
import emailIcon from '@/assets/icons/sc-journey/email.svg';
import writingIcon from '@/assets/icons/sc-journey/writing.svg';
import taskIcon from '@/assets/icons/sc-journey/task.svg';
import historyIcon from '@/assets/icons/sc-journey/history.svg';
// import othersIcon from '@/assets/icons/sc-journey/others.svg';
import FileManager from "@shared/assets/svg/file-manager.svg"

export default {
    name: 'card',
    components: {
        // Checkbox,
        // infoButtonIcon,
        profileIcon,
        phoneIcon,
        emailIcon,
        writingIcon,
        taskIcon,
        historyIcon,
        // othersIcon,
        SelectColored,
        CheckboxSide,
        FileManager
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        statusList: {
            type: Array,
            required: true,
        },
        selected: {
            type: Object,
            default: null,
        }
    },
    computed: {
        getEmailTitle() {
            return this.data.refree.email;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/functions.scss';

.card {
    background-color: #fff;
    border-radius: toRem(16px);
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow: visible;
    row-gap: toRem(15px);
    flex-wrap: wrap;
    .general {
        display: flex;
        flex-direction: column;
        min-width: toRem(148px);
        .label {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0;
            .name {
                font-weight: bold;
            }
            .icons {
                display: inline-flex;
                align-items: center;
                transform: translateY(-50%);
                .count {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    font-weight: bold;
                    border-radius: 50%;
                }
            }
        }
    }
    .refree {
        display: flex;
        align-items: flex-start;
        background-color: #DFDFDF;
        border-radius: toRem(12px);
        min-width: toRem(380px);
        .profileIcon {
            margin-top: toRem(6px);
            width: toRem(27px);
            height: toRem(28px);
        }
        .data {
            display: flex;
            flex-direction: column;
            gap: 4px;
            .title {
                font-weight: bold;
            }
            .contacts {
                display: flex;
                .phone, .email {
                    display: inline-flex;
                    align-items: center;
                    &__icon {
                        width: toRem(16px);
                        height: toRem(16px);
                    }
                }
            }
        }
    }
    .actions {
        margin: 0 auto;
        display: flex;
        align-items: center;
        &>* {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            @apply bg-primary;
            opacity: 0.8;
            border-radius: 50%;
            cursor: pointer;
            transition: 0.3s ease;
            &:hover {
                // background-color: #0D69D5;
                opacity: 1;

            }
            .icon {
                color: white;
                path {
                    fill: #fff;
                }
            }
        }
    }
    .controls {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        display: flex;
        .checkbox {
            cursor: pointer;
        }
    }
    .compact-wrapper {
        display: flex;
        align-items: center;
        border-radius: toRem(16px);
        &__general {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            row-gap: toRem(15px);
        }
        .controls-compact {
            display: none;
            .checkbox-side {
                width: 30px;
                padding: 7px;
                border-radius: 0;
            }
        }
    }
    &--compact {
        width: toRem(320px);
        padding: 0;
        background: none;
        flex-direction: column;
        align-items: stretch;
        .controls {
            display: none;
        }
        .compact-wrapper {
            background-color: #fff;
            // overflow: hidden;
            width: 100%;
            .controls-compact {
                display: initial;
                align-self: stretch;
                border-top-left-radius: toRem(16px);
                border-bottom-left-radius: toRem(16px);
                overflow: hidden;
            }
            &__general {
                padding: toRem(16px);
                flex-grow: 1;
                flex-direction: column;
                align-items: stretch;
                gap: toRem(16px);
                .general {
                    flex-direction: row;
                    gap: toRem(10px);
                    justify-content: space-between;
                    .label {
                        font-size: toRem(16px);
                    }
                    .icons {
                        .info {
                            display: none;
                        }
                    }
                }
                .refree {
                    background-color: #f2f2f2;
                    min-width: unset;
                    .profileIcon {
                        display: none;
                    }
                    .data {
                        .title {
                            font-size: toRem(14px);
                            .prefix {
                                display: none;
                            }
                        }
                        .contacts {
                            font-size: toRem(12px);
                            flex-direction: column;
                            gap: toRem(6px);
                        }
                    }
                }
            }
        }
        .actions {
            height: 0;
            margin: 0;
            opacity: 0;
            overflow: hidden;
            transition: all .2s;
            &>* {
                width: toRem(48px);
                height: toRem(48px);
                .icon {
                    width: toRem(22px);
                    height: toRem(22px);
                }
            }
        }
    }
    &--selected {
        .actions {
            height: toRem(48px);
            opacity: 1;
            gap: unset;
            justify-content: space-between;
        }
    }

    .bounceBackMail {
        color: red;
    }
}
</style>
<template>
<label
  class="checkbox-circle rounded-full"
  :class="{'checkbox-circle--checked': value}"
>
  <slot/>
  <input type="checkbox" class="hidden" v-model="modelValue">
</label>
</template>

<script>
export default {
  name: 'checkbox-circle',
  props: ['value'],
  computed: {
    modelValue: {
      get() {
        return this.checked;
      },
      set(val) {
        this.$emit('input', val);
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.checkbox-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: #282828;
  background-color: #F2F2F2;
  font-weight: bold;
  cursor: pointer;
  &--checked {
    border: 2px solid #0D69D5;
  }
}
</style>
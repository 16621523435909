<template>
  <div class="card self-start max-w-md w-full">
    <div class="bg-primary p-3 px-4 font-bold text-white-text text-md">
      {{ title }}
    </div>
    <div class="card-body rounded-b-box mb-8 bg-base-300 p-4">
      <div class="flex w-full flex-col">
        <span class="font-medium px-2">{{label}}</span>
        <template v-if="isUploading">
          <div class="py-20 flex flex-col items-center justify-center">
            <Loader class="mb-4" />
            <small v-if="uploadingMsg" class="text-gray-500">Uploading... Please Wait.</small>
          </div>
        </template>
        <template v-else>
          <div
            class="my-2  space-y-3  overflow-y-auto scroll-bar"
            style="max-height: 20rem"
            v-if="files && files.length"
          >
          <div  v-for="file in files"
            class="flex transition-colors
                hover:bg-blue-100 items-center
                p-2.5 bg-white rounded-xl last:mb-5"
              :key="file.id">
            <a
              :title="`Download ${file.name}`"
              download
              target="_blank"
              :href="file.link"
              class="
                gap-x-2
                truncate
                flex items-center
              "
            >
             
              <svg  class="text-primary opacity-70 fill-primary w-4 h-4 stroke-primary" viewBox="0 0 482.1 482.1"  xml:space="preserve">
                <path d="M315.5,0H121.8C93.5,0,70.5,23,70.5,51.3v189.8h-5c-11.4,0-20.7,9.3-20.7,20.7v125.4c0,11.4,9.3,20.7,20.7,20.7h5v23   c0,28.3,23,51.3,51.3,51.3H386c28.3,0,51.3-23,51.3-51.3V121.4L315.5,0z M386,450.7H121.8c-11,0-19.9-8.9-19.9-19.9v-23h246.3   c11.4,0,20.7-9.2,20.7-20.7V261.7c0-11.4-9.3-20.7-20.7-20.7H101.9V51.3c0-10.9,8.9-19.9,19.9-19.9l181.9-0.2v67.2   c0,19.6,15.9,35.6,35.6,35.6l65.9-0.2l0.7,296.9C405.9,441.8,397,450.7,386,450.7z"/>
                <path d="M135,319.9c0.1-18.1-10.5-27.6-27.4-27.6c-4.4,0-7.2,0.4-8.9,0.8v56.9c1.7,0.4,4.4,0.4,6.8,0.4   C123.4,350.5,135,340.7,135,319.9z"/>
                <path d="M227.7,321c0-15.6-7.5-29-21-29c-13.3,0-21,12.7-21,29.5c0,17,8,29,21.2,29C220.1,350.6,227.7,337.9,227.7,321z"/>
              </svg>
              <span class="text-sm flex-1 truncate text-primary">
                {{ file.name }}
              </span>
            </a>
            <div
              class="flex-shrink-0 cursor-pointer  ml-auto text-primary hover:text-red-600"
              @click.stop="$emit('delete', file.name)"
              v-if="allowDelete"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
              </svg>
            </div>
          </div>
          </div>
          <div class="flex items-center py-10 justify-center" v-else>
            <Empty text="No files found" />
          </div>
        </template>
        <div class="p-2 w-full  mt-4  rounded-xl">
          <FormulateInput
            ref="file"
            type="file"
            class="rounded-xl group cursor-pointer"
            @input="handleFiles"
            multiple
            :add-label="false"
            :disabled="disabled"
          >
            <template #uploadAreaMask>
              <button
                @click="clickHandler"
                class="
                  text-center
                  rounded-2xl
                  border-dashed border-2
                  py-4
                  hover:border-blue-400
                  bg-white
                  hover:bg-blue-200
                  mb-4
                  border-primary
                  h-full
                  w-full
                "
                :disabled="disabled"
                :class="{'group-hover:bg-blue-50': !disabled}"
              >
                Upload Documents
              </button>
            </template>
            <!-- <template #file></template> -->
          </FormulateInput>
        </div>
      </div>
      <div class=""></div>
    </div>
  </div>
</template>

<script>
import Loader from "@shared/loader";
import Empty from "@shared/empty";
export default {
  name: "file-uploader",
  components: {
    Loader,
    Empty,
  },
  props: {
    title: {
      default: () => "Documents by Referee",
      type: String,
    },
    label: {
      default: () => "Documents",
      type: String,
    },
    files: {
      default: () => [],
      type: Array,
    },
    isUploading: {
      default: false,
      type: Boolean,
    },
    uploadingMsg: {
      default: false,
      type: Boolean,
    },
    allowDelete: {
      default: false,
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    handleFiles(e) {
      console.log("e :>> ", e);
      this.$emit("upload", e.files);
    },
    clickHandler() {
      console.log("this.$refs.file :>> ", this.$refs.file);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .formulate-input {
    .formulate-input-element {
      max-width: 100% !important;
      .formulate-input-upload-area {
        padding: 0;
      }
    }
  }
}
</style>
import UpIcon from "@/assets/icons/email-admin/icon-up-white.svg";
import DownIcon from "@/assets/icons/email-admin/icon-down.svg";

export default {
  name: "email-days",
  components: {
    DownIcon,
    UpIcon,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    minValue: {
      type: String,
      default: "0",
    },
    index: Number,
    disabled: {
      type: Boolean,
      default: false
    },
    showError: {
      type: Boolean,
      default: false
    },
    sequenceTemplates: {
      type: Array
    }
  },
  computed: {
    count: {
      get() {
        return this.value;
      },
      set(data) {
        this.$emit("input", parseInt(data), this.index);
      },
    },
  },
  methods: {
    increment() {
      if (this.count >= this.minValue)
        this.count = this.count + 1;
    },
    decrement() {
      if (this.count > this.minValue)
        this.count = this.count - 1;
    },
    setInputData(event){
      this.$emit("inputValue", parseInt(event.target.value), this.index);
    }
  },
};

<template>
    <div
        class="entity flex items-center justify-center gap-6 min-h-16 px-7 py-3"
        :class="{
            'bg-white': !item.selected && !item.warning && !item.error,
            'bg-error': item.error && !item.selected,
            'opacity-50 pointer-events-none cursor-not-allowed': item.disabled,
            'cursor-pointer': item.selectable
        }"
        @click="onSelect"
    >
        <div class="entity__content flex items-center justify-center gap-2">
            <div
                v-if="item.type in entityTypesConfig"
                class="entity__content-underlay"
                :class="{ 'invisible': item.selected }"
            />

            <div
                v-if="item.type in entityTypesConfig"
                class="entity__icon-wrapper flex items-center justify-center relative rounded-full"
            >
                <span
                    class="flex items-center justify-center w-5 h-5 rounded-full"
                    :class="{
                        'bg-primary': !item.selected,
                        'bg-white': item.selected
                    }"
                >
                <component
                    :is="entityTypesConfig?.[item.type]?.icon"
                    class="entity__icon"
                    :class="{ 'selected': item.selected }"
                />
                </span>
            </div>
            <p
                class="entity__name mt-1 text-sm font-bold w-48 truncate"
                :title="itemName"
            >
                {{ itemName || '--' }}
            </p>
        </div>

        <div
            v-if="!readonly"
            class="entity__controls ml-auto"
            title="Delete"
        >
            <button
                class="flex items-center justify-center text-primary"
                @click.stop="$emit('remove')"
            >
                <TrashIcon class="entity__trash" :class="{ 'selected': item.selected }" />
            </button>
        </div>

        <div
            v-else-if="item.checked"
            class="entity__check ml-auto flex items-center justify-center relative rounded-full bg-success"
        >
            <font-awesome-icon
                class="text-primary-content text-xs"
                icon="check"
            />
        </div>
    </div>
</template>

<script>
import { capitalize } from "lodash";
import { ENTITY_TYPE_NAMES } from "@shared/utils/constants.js";
import TrashIcon from "@shared/assets/icons/trash.svg";
const PersonIcon = () => import("@shared/assets/icons/entity/person.svg");
const CompanyIcon = () => import("@shared/assets/icons/entity/company.svg");

export default {
    components: {
        TrashIcon,
    },

    props: {
        /**
         * @typedef {Object} Item
         * @property {number} id
         * @property {string} type
         * @property {string} [name]
         * @property {boolean} [active]
         * @property {boolean} [checked]
         */
        /**
         * @type {Item}
         */
        item: {
            type: Object,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        selectable: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        entityTypesConfig() {
            return {
                [ENTITY_TYPE_NAMES.PERSON]: {
                    id: ENTITY_TYPE_NAMES.PERSON,
                    icon: PersonIcon,
                },
                [ENTITY_TYPE_NAMES.COMPANY]: {
                    id: ENTITY_TYPE_NAMES.COMPANY,
                    icon: CompanyIcon,
                },
            }
        },

        itemName() {
            return this.item?.name?.split(' ').map(i => capitalize(i)).join(' ') || '';
        }
    },

    methods: {
        onSelect() {
            if (this.selectable) this.$emit("select");
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/functions.scss';

.entity {
    $this: &;

    position: relative;

    &:hover {
        #{$this}__content-underlay {
            background-color: var(--theme-color-primary-100);
            transition: background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
        }
    }

    &__content-underlay {
        position: absolute;
        top: 10px;
        right: 0;
        bottom: 10px;
        left: 0;
        z-index: 0;
        border: 1px solid var(--theme-color-line);
        border-radius: 8px;
    }

    &__icon-wrapper, &__check {
        position: relative;
        z-index: 1;
        overflow: hidden;
        width: toRem(22px);
        height: toRem(22px);
        flex-shrink: 0;
    }

    &__icon-underlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: .2;
    }

    &__name {
        position: relative;
        z-index: 1;
        max-width: toRem(80px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__controls {
        position: relative;
        z-index: 1;
    }

    &__icon {
        fill: var(--theme-color-white-text);

        &.selected {
            fill: var(--theme-color-main);
        }
    }

    &__trash {
        fill: var(--theme-color-main) ;

        &.selected {
            fill: var(--theme-color-white-text);
        }
    }
}
</style>

export default {
    methods: {
        getToolsData(toolName) {
            let tabName = "tt"
            let dataRes= this.getAllToolsData;
            dataRes = dataRes?.find((n) => n["tool-name"] === toolName);
            if (dataRes) {
                const request_id = dataRes?.request_id;
                let match;
                if (tabName === "monitoring" || tabName === "monitoring-ro") match = this.toolSearchQueries?.find((el) => el._id === request_id && el.is_monitoring);
                else match = this.toolSearchQueries?.find((el) => el._id === request_id);
                if (match)
                    Object.entries(dataRes).forEach(([key, value]) => {
                        this[key] = value;
                    });
            }
            return dataRes;
        },

        setToolsData(toolName) {
            this.SET_ALL_TOOLS_DATA({
                ...this._data,
                "tool-name": toolName,
                entityId: this.currentEntity
            });
            // if (tabName === "tools") {
            //     this.SET_ALL_TOOLS_DATA({
            //         ...this._data,
            //         "tool-name": toolName,
            //     });
            // } else {
            //     this.SET_ALL_MONITORING_DATA({
            //         ...this._data,
            //         "tool-name": toolName,
            //     });
            // }
        },
    },
};

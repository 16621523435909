<template>
<modal
  name="referee-modal"
  ref="referee-modal"
  width="30%"
  height="auto"
  class="rounded-full"
  v-bind="$attrs"
  v-on="$listeners"
>
  <div v-if="data" class="flex flex-col justify-between min-h-full h-full bg bg-card-bg">
    <header class="modal-header h-10 2xl:h-12 flex items-center justify-between px-4 py-2.5 border-b border-0 border-solid border-gray-200">
      <span class="font-semibold text-base 2xl:text-xl tracking-wide text-white-text flex items-center justify-center gap-3">
        <component
          :is="header[mode].icon"
          class="modal-header__icon w-5 h-5 2xl:w-7 2xl:h-7"
        />
        <span>{{header[mode].title}}</span>
      </span>
      <!--Handle close svg-->
      <CloseIcon class="cursor-pointer w-3.5 h-3.5 2xl:w-6 2xl:h-6" @click="closeModal" />
    </header>
    <div
      v-if="mode === 'general'"
      class="container p-4 flex-grow w-full max-w-full overflow-y-auto scroll-bar"
    >
      <!-- form ui -->
      <div class="call-form-wrapper w-full h-full flex flex-col gap-4">
        <SearchField
          placeholder="Search"
          v-model="search"
          fieldClass="px-4 bg-gray-200"
        />
        <Field
          title="Google"
          placeholder="Search"
          v-model="generalData.google"
          fieldClass="px-4 bg-gray-200"
          disabled
        />
        <Field
          title="Company House"
          placeholder="Company House"
          v-model="generalData.company_house"
          fieldClass="px-4 bg-gray-200"
          disabled
        />
        <Field
          title="FCA Register"
          placeholder="FCA Register"
          v-model="generalData.fca_register"
          fieldClass="px-4 bg-gray-200"
          disabled
        />
        <div class="referee-directory w-full">
          <div class="referee-directory__label text-sm font-bold flex items-center justify-between w-full mb-2">
            <span>Referee Directory</span>
            <button
              @click="addToDirectory"
              class="referee-directory__button w-5 h-5 inline-flex items-center justify-center rounded font-bold cursor-pointer bg-primary"
            >+</button>
          </div>
          <div class="referee-directory__list flex flex-col gap-2">
            <DirectoryItem
              v-for="item in directoryList"
              :key="item.id"
              :data="item"
            />
          </div>
        </div>
      </div>
    </div>
    <template v-if="mode==='add'">
      <div class="container p-4 flex-grow w-full max-w-full overflow-y-auto scroll-bar">
        <div class="call-form-wrapper w-full h-full flex flex-col gap-4">
          <Field
            title="Referee Name"
            placeholder="Referee Name"
            v-model="refereeData.name"
            fieldClass="px-4 bg-gray-200"
          />
          <Field
            title="Company's Name"
            placeholder="Company's Name"
            v-model="refereeData.company_name"
            fieldClass="px-4 bg-gray-200"
          />
          <Field
            title="Positions"
            placeholder="Positions"
            v-model="refereeData.positions"
            fieldClass="px-4 bg-gray-200"
          />
          <Field
            title="Address"
            placeholder="Address"
            v-model="refereeData.address"
            fieldClass="px-4 bg-gray-200"
          />
          <Field
            title="Contact Number"
            placeholder="Contact Number"
            v-model="refereeData.phone"
            fieldClass="px-4 bg-gray-200"
          />
          <Field
            title="Referee Email"
            type="email"
            placeholder="Referee Email"
            v-model="refereeData.email"
            fieldClass="px-4 bg-gray-200"
          />
        </div>
      </div>
      <footer class="flex items-end justify-between py-4 px-7">
        <div class="flex justify-between align-center w-full gap-4">
          <button
            type="submit"
            class="transition rounded-lg bg-primary hover:bg-primary-focus focus:bg-primary-focus border-0 text-white-text text-sm h-10 font-semibold px-4 flex-grow"
            @click="back"
          >Back</button>
          <button
            type="submit"
            class="transition rounded-lg bg-success hover:bg-success focus:bg-success border-0 text-white-text text-sm h-10 font-semibold px-4 flex-grow"
            @click="saveDirectoryItem"
          >Add</button>
        </div>
      </footer>
    </template>
  </div>
</modal>
</template>

<script>
import Field from "@/components/sc-journey/field-modal.vue";
import SearchField from "@/components/sc-journey/search-field.vue";
import CloseIcon from "@/assets/icons/profile-view/close-icon.svg";
import ProfileIcon from "@/assets/icons/sc-journey/profile.svg";
import AddToDirectoryIcon from "@/assets/icons/sc-journey/add-to-directory.svg";
import DirectoryItem from "@/components/sc-journey/directory-item.vue";

export default {
  name: 'referee-modal',
  props: {
    data: {
      type: Object,
    },
  },
  components: {
    Field,
    CloseIcon,
    SearchField,
    DirectoryItem,
  },
  data() {
    return {
      mode: 'general',
      search: '',
      generalData: { // TODO: use prop 'data' instead
        google: '180000000',
        company_house: 'London',
        fca_register: '1234567890',
        referee_directory: [
          {
            id: '1',
            referee_name: 'Alex Smith',
            referee_phone: '18000000000',
            referee_email: 'alex@gmail.com',
          },
          {
            id: '2',
            referee_name: 'H. David',
            referee_phone: '19000000000',
            referee_email: 'david@gmail.com',
          },
        ],
      },
      refereeData: {
        name: '',
        company_name: '',
        positions: '',
        address: '',
        phone: '',
        email: '',
      },
      // calculated list which will be updated when new list item is created:
      directoryList: [],
    }
  },
  computed: {
    header() {
      return {
        general: {
          icon: ProfileIcon,
          title: `${this.data.name} Referee`,
        },
        add: {
          icon: AddToDirectoryIcon,
          title: 'Add to Referee Directory',
        }
      }
    },
  },
  created() {
    this.directoryList = [...this.generalData.referee_directory];
  },
  mounted(){
      this.closeModal();
  },
  methods: {
    closeModal() {
      this.$modal.hide('referee-modal');
    },
    saveForm() {
        let refereeData = this.refereeData
        const qs = Object.values(refereeData).filter(value => !value);
        if (qs.length > 0) {
          this.$toast.error("Please fill all mandatory fields")
        } else {
          this.$emit('submit', this.refereeData);
          // this.closeModal();
          // TODO: remove:
          this.directoryList.push({
            id: Date.now(),
            referee_name: this.refereeData.name,
            referee_phone: this.refereeData.phone,
            referee_email: this.refereeData.email,
          });
          this.resetReferee();
          this.back();
        }
    },
    resetReferee() {
        this.refereeData = {
          name: '',
          company_name: '',
          positions: '',
          address: '',
          phone: '',
          email: '',
      }
    },
    addToDirectory() {
      this.mode = 'add';
    },
    back() {
      this.mode = 'general';
    },
    saveDirectoryItem() {
      // TODO: save new data to the serve (use this.saveForm without modal closing)
      this.saveForm();
    },
  }
}
</script>

<style lang="scss" scoped>
.modal-header {
  &__icon {
    fill: white;
  }
}
.input-container {
  @apply flex flex-col gap-2;
}
.referee-directory {
  &__button {
    color: white;
  }
}
::v-deep {
  .formulate-input-element {
    input {
      @apply rounded-xl text-base-content;
      background-color: #F2F2F2;
      border: none;
    }
  }
}
</style>
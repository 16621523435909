<template>
    <svg
        :width="width"
        :height="height"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            :d="iconsPathsMap[type]"
            :fill="fill"
        />
    </svg>
</template>

<script>
export default {
    name: "MultientityIcon",

    props: {
        type: {
            type: String,
            required: true,
        },
        fill: {
            type: String,
            required: true,
        },
        width: {
            type: String,
            default: "24"
        },
        height: {
            type: String,
            default: "24"
        },
    },

    computed: {
        iconsPathsMap() {
            return {
                closeForms: "M12 24a12 12 0 1 0 0-24 12 12 0 0 0 0 24ZM8.203 8.203a1.12 1.12 0 0 1 1.59 0l2.202 2.203 2.203-2.203a1.125 1.125 0 0 1 1.59 1.59l-2.204 2.202 2.204 2.203a1.125 1.125 0 0 1-1.59 1.59l-2.203-2.204-2.203 2.204a1.125 1.125 0 0 1-1.59-1.59l2.204-2.203-2.203-2.203a1.12 1.12 0 0 1 0-1.589Z",
                openForms: "m22.837 4.434.62-.631c.76-.76.706-2.045-.054-2.805l-.399-.4c-.76-.76-2.043-.814-2.803-.054l-.63.621 3.266 3.27Zm-3.84 7.558a1 1 0 0 0-1 1v8.006c0 .551-.448 1-1 1H3c-.553 0-1-.449-1-1V6.988c0-.551.447-1 1-1h7.999a1 1 0 0 0 0-2.002h-8C1.347 3.986 0 5.333 0 6.988v14.01A3.004 3.004 0 0 0 3 24h13.998c1.654 0 3-1.347 3-3.002v-8.006a1 1 0 0 0-1-1Zm-10.004-.22-.026.027-1.957 5.034c-.048.124.076.248.205.205l5.019-1.97L22.346 4.947l-3.272-3.275L8.988 11.767l.005.005Zm.13.572L8.13 14.88l1.024 1.025 2.55-.999a.803.803 0 0 1-.378-.205.793.793 0 0 1-.215-.658l.015-.145-.145.016a.783.783 0 0 1-.657-.216.823.823 0 0 1-.222-.664l.016-.145-.145.016a.795.795 0 0 1-.657-.216.737.737 0 0 1-.195-.345Zm10.43-7.786-.145-.145-7.67 7.678.145.145 7.67-7.678Z",
                addForm: "M10 .313A9.686 9.686 0 0 0 .312 10 9.686 9.686 0 0 0 10 19.688 9.686 9.686 0 0 0 19.688 10 9.686 9.686 0 0 0 10 .312Zm5.625 10.78a.47.47 0 0 1-.469.47h-3.594v3.593a.47.47 0 0 1-.468.469H8.906a.47.47 0 0 1-.469-.469v-3.594H4.845a.47.47 0 0 1-.469-.468V8.906a.47.47 0 0 1 .469-.469h3.593V4.845a.47.47 0 0 1 .47-.469h2.187a.47.47 0 0 1 .469.469v3.593h3.593a.47.47 0 0 1 .469.47v2.187Z",
            };
        },
    },
}
</script>

<style>

</style>
<template>
    <div class='mb-20 px-5 outputModalScreen'>
        <div class="flex justify-between items-center">
            <div class="flex gap-2 p-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10 17.4166L14.1247 13.2917C16.4028 11.0137 16.4028 7.32026 14.1247 5.0422C11.8467 2.76414 8.15327 2.76414 5.87521 5.0422C3.59715 7.32026 3.59715 11.0137 5.87521 13.2917L10 17.4166ZM10 19.7736L4.6967 14.4703C1.76777 11.5413 1.76777 6.79262 4.6967 3.86369C7.62563 0.934759 12.3743 0.934759 15.3033 3.86369C18.2323 6.79262 18.2323 11.5413 15.3033 14.4703L10 19.7736ZM10 10.8337C10.9205 10.8337 11.6667 10.0875 11.6667 9.16699C11.6667 8.24652 10.9205 7.50033 10 7.50033C9.0795 7.50033 8.33333 8.24652 8.33333 9.16699C8.33333 10.0875 9.0795 10.8337 10 10.8337ZM10 12.5003C8.15905 12.5003 6.66667 11.0079 6.66667 9.16699C6.66667 7.32604 8.15905 5.83366 10 5.83366C11.8409 5.83366 13.3333 7.32604 13.3333 9.16699C13.3333 11.0079 11.8409 12.5003 10 12.5003Z" fill="#0D69D5"/>
                </svg>
                <span>{{ outputData?.[0]?.details?.address?.address }}</span>
                <div class="flex ml-7 gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M7.50032 0.833984V2.50065H12.5003V0.833984H14.167V2.50065H17.5003C17.9606 2.50065 18.3337 2.87375 18.3337 3.33398V16.6673C18.3337 17.1276 17.9606 17.5006 17.5003 17.5006H2.50033C2.04009 17.5006 1.66699 17.1276 1.66699 16.6673V3.33398C1.66699 2.87375 2.04009 2.50065 2.50033 2.50065H5.83366V0.833984H7.50032ZM16.667 9.16732H3.33366V15.834H16.667V9.16732ZM5.83366 4.16732H3.33366V7.50065H16.667V4.16732H14.167V5.83398H12.5003V4.16732H7.50032V5.83398H5.83366V4.16732Z" fill="#0D69D5"/>
                    </svg>
                    <span>{{ outputData?.[0]?.details?.national_id }}</span>
                </div>
            </div>
            <span @click="expandCard('all')" class="expandAllButton">{{ expandText }} all</span>
        </div>
        <!---------- for Details card  ----------->
        <div v-if="!isDeatilsCard" class="personalInfoAccordian cursor-pointer flex justify-between rounded-lg p-4 mt-4" @click="expandCard('details')">
            <span class="text-blue-600 text-base font-medium">Details</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M9.99984 11.6668L6.6665 8.3335H13.3332L9.99984 11.6668Z" fill="black"/>
            </svg>
        </div>
        <div v-if="(currentCard=='details' && isDeatilsCard) || isDeatilsCard" class="expandedCard rounded-lg p-4 bg-white mt-4">
            <div @click="expandCard('details')" class="flex justify-between cursor-pointer">
                <div class="flex justify-between w-64"> 
                    <span class="text-blue-600 text-base font-medium">Details</span>
                </div>
                <div>  
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M9.99984 11.6668L6.6665 8.3335H13.3332L9.99984 11.6668Z" fill="black"/>
                    </svg>
                </div>  
            </div>
            <div class="p-2 border-b"></div>
            <div class="mt-4 justify-start">
                <div v-if="allCodeData?.length > 0">
                    <table class="w-full">
                        <thead class="detailHeadings">
                            <th>
                                Field
                            </th>
                            <th>
                                Value
                            </th>
                            <th class="matchType">
                                Match Type
                            </th>
                            <th class="sourceName">
                                Source Name
                            </th>
                            <th>
                                Description
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="(item, idx) in allCodeData" :key="idx" class="detailData">
                                <td>{{ fieldName(item?.code) }}</td>
                                <td>{{ fieldValue(item?.code) }}</td>
                                <td>{{ matchType(item?.code) }}</td>
                                <td>{{ sourceName(item?.code) }}</td>
                                <td>{{ item?.value ? item?.value : '-'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else class="flex justify-center">
                    No details found
                </div>
            </div>
        </div>
        <!---------- for address card  ----------->
        <div v-if="!isAddressCard" class="personalInfoAccordian flex justify-between rounded-lg p-4 mt-3" @click="expandCard('address')">
            <span class="text-blue-600 text-base font-medium">Address</span>
            <div class="flex items-center gap-2">
                <span>Reliability Score: <span :class="showCardStatus(outputData[0]?.details?.address)">{{showReliability(outputData[0]?.details?.address)}}</span></span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M9.99984 11.6668L6.6665 8.3335H13.3332L9.99984 11.6668Z" fill="black"/>
                </svg>
            </div>
        </div>
        <div v-if="(currentCard=='address' && isAddressCard) || isAddressCard" class="expandedCard rounded-lg p-4 bg-white mt-3">
            <div class="flex justify-between" @click="expandCard('address')">
                <div class="flex justify-between w-64"> 
                    <span class="text-blue-600 text-base font-medium">Address</span>
                    <!-- <span>Reliability Score: <span :class="showCardStatus(outputData[0]?.details?.address)">{{showReliability(outputData[0]?.details?.address)}}</span></span> -->
                </div>
                <div class="flex items-center gap-2">
                <span>Reliability Score: <span :class="showCardStatus(outputData[0]?.details?.address)">{{showReliability(outputData[0]?.details?.address)}}</span></span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M9.99984 11.6668L6.6665 8.3335H13.3332L9.99984 11.6668Z" fill="black"/>
                </svg>
            </div>  
            </div>
            <div class="p-2 border-b"></div>
            <div class="flex mt-2 justify-start">
                <span class="opacity-50 w-3/4">Complete Name:</span>
                <div class="ml-5 w-full flex flex-col">
                    <span>{{this.outputData[0]?.details?.completename ? this.outputData[0]?.details?.completename : '--'}}</span>
                    <!-- <span class="opacity-50 matchedStatus">{{this.outputData[0]?.complete_details?.identity?.messages.find(el => el.toLowerCase().includes('complete name'))}}</span> -->
                </div>
            </div>
            <!-- <div class="flex mt-2 justify-start">
                <span class="opacity-50 w-3/4">House Number:</span>
                <div class="ml-5 w-full flex flex-col">
                    <span>{{this.outputData[0]?.complete_details?.address?.houseNumber ? this.outputData[0]?.complete_details?.address?.houseNumber : '--'}}</span> -->
                    <!-- <span class="opacity-50 matchedStatus">{{this.outputData[0]?.complete_details?.identity?.messages.find(el => el.toLowerCase().includes('house number'))}}</span> -->
                <!-- </div>
            </div>
            <div class="flex mt-2 justify-start">
                <span class="opacity-50 w-3/4">Thoroughfare:</span>
                <div class="ml-5 w-full flex flex-col">
                    <span>{{this.outputData[0]?.complete_details?.address?.thoroughfare ? this.outputData[0]?.complete_details?.address?.thoroughfare : '--'}}</span> -->
                    <!-- <span class="opacity-50 matchedStatus">{{this.outputData[0]?.complete_details?.identity?.messages.find(el => el.toLowerCase().includes('thoroughfare'))}}</span> -->
                <!-- </div>
            </div> -->
            <div class="flex mt-2 justify-start">
                <span class="opacity-50 w-3/4">Postal Code:</span>
                <div class="ml-5 w-full flex flex-col">
                    <span>{{this.outputData[0]?.complete_details?.address?.postalCode ? this.outputData[0]?.complete_details?.address?.postalCode : '--'}}</span>
                    <!-- <span class="opacity-50 matchedStatus">{{this.outputData[0]?.complete_details?.identity?.messages.find(el => el.toLowerCase().includes('postal code'))}}</span> -->
                </div>
            </div>
            <div class="flex mt-2 justify-start">
                <span class="opacity-50 w-3/4">Locality:</span>
                <div class="ml-5 w-full flex flex-col">
                    <span>{{this.outputData[0]?.complete_details?.address?.locality ? this.outputData[0]?.complete_details?.address?.locality : '--'}}</span>
                    <!-- <span class="opacity-50 matchedStatus">{{this.outputData[0]?.complete_details?.identity?.messages.find(el => el.toLowerCase().includes('locality'))}}</span> -->
                </div>
            </div>
            <div class="flex mt-2 justify-start">
                <span class="opacity-50 w-3/4">District:</span>
                <div class="ml-5 w-full flex flex-col">
                    <span>{{this.outputData?.[0]?.complete_details?.address?.district ? this.outputData?.[0]?.complete_details?.address?.district : '--'}}</span>
                </div>
            </div>
            <div class="flex mt-2 justify-start">
                <span class="opacity-50 w-3/4">Province:</span>
                <div class="ml-5 w-full flex flex-col">
                    <span>{{this.outputData[0]?.complete_details?.address?.province ? this.outputData[0]?.complete_details?.address?.province : '--'}}</span>
                    <!-- <span class="opacity-50 matchedStatus">{{this.outputData[0]?.complete_details?.identity?.messages.find(el => el.toLowerCase().includes('province'))}}</span> -->
                </div>
            </div>
            <div class="flex mt-2 justify-start">
                <span class="opacity-50 w-3/4">Country Name:</span>
                <div class="ml-5 w-full flex flex-col">
                    <span>{{this.outputData[0]?.complete_details?.address.countryName ? this.outputData[0]?.complete_details?.address.countryName : '--'}}</span>
                    <!-- <span class="opacity-50 matchedStatus">{{this.outputData[0]?.complete_details?.address?.messages.find(el => el.toLowerCase().includes('country name'))}}</span> -->
                </div>
            </div>
            <div class="flex mt-2 justify-start">
                <span class="opacity-50 w-3/4">Formal Country Name:</span>
                <div class="ml-5 w-full flex flex-col">
                    <span>{{this.outputData[0]?.complete_details?.address.formalCountryName ? this.outputData[0]?.complete_details?.address.formalCountryName : '--'}}</span>
                    <!-- <span class="opacity-50 matchedStatus">{{this.outputData[0]?.complete_details?.address?.messages.find(el => el.toLowerCase().includes('formal country name'))}}</span> -->
                </div>
            </div>
            <div class="flex mt-2 justify-start">
                <span class="opacity-50 w-3/4">Country Code:</span>
                <div class="ml-5 w-full flex flex-col">
                    <span>{{this.outputData[0]?.complete_details?.address.countryCode ? this.outputData[0]?.complete_details?.address.countryCode : '--'}}</span>
                    <!-- <span class="opacity-50 matchedStatus">{{this.outputData[0]?.complete_details?.address?.messages.find(el => el.toLowerCase().includes('country code'))}}</span> -->
                </div>
            </div>
            <div class="flex mt-2 justify-start">
                <span class="opacity-50 w-3/4">Country Number:</span>
                <div class="ml-5 w-full flex flex-col">
                    <span>{{this.outputData[0]?.complete_details?.address.countryNumber ? this.outputData[0]?.complete_details?.address.countryNumber : '--'}}</span>
                    <!-- <span class="opacity-50 matchedStatus">{{this.outputData[0]?.complete_details?.address?.messages.find(el => el.toLowerCase().includes('country number'))}}</span> -->
                </div>
            </div>
            <div class="flex mt-2 justify-start">
                <span class="opacity-50 w-3/4">Country ISO3:</span>
                <div class="ml-5 w-full flex flex-col">
                    <span>{{this.outputData[0]?.complete_details?.address.countryISO3 ? this.outputData[0]?.complete_details?.address.countryISO3 : '--'}}</span>
                    <!-- <span class="opacity-50 matchedStatus">{{this.outputData[0]?.complete_details?.address?.messages.find(el => el.toLowerCase().includes('country iso3'))}}</span> -->
                </div>
            </div>
            <div class="flex mt-2 justify-start">
                <span class="opacity-50 w-3/4">Address Line 1:</span>
                <div class="ml-5 w-full flex flex-col">
                    <span>{{this.outputData[0]?.complete_details?.address.addressLine1 ? this.outputData[0]?.complete_details?.address.addressLine1 : '--'}}</span>
                    <!-- <span class="opacity-50 matchedStatus">{{this.outputData[0]?.complete_details?.address?.messages.find(el => el.toLowerCase().includes('address line 1'))}}</span> -->
                </div>
            </div>
            <div class="flex mt-2 justify-start">
                <span class="opacity-50 w-3/4">Address Line 2:</span>
                <div class="ml-5 w-full flex flex-col">
                    <span>{{this.outputData[0]?.complete_details?.address.addressLine2 ? this.outputData[0]?.complete_details?.address.addressLine2 : '--'}}</span>
                    <!-- <span class="opacity-50 matchedStatus">{{this.outputData[0]?.complete_details?.address?.messages.find(el => el.toLowerCase().includes('address line 2'))}}</span> -->
                </div>
            </div>
        </div>

        <!---------- for email card  ----------->
        <div v-if="!isEmailCard" class="personalInfoAccordian flex justify-between rounded-lg p-4 mt-4" @click="expandCard('email')" :class="{'disableSection' : !checkEmail}">
            <span class="text-blue-600 text-base font-medium">Email</span>
            <div class="flex items-center gap-2">
                <span>Reliability Score: <span :class="showCardStatus(outputData[0]?.details?.email)">{{!checkEmail ? "--" : showReliability(outputData[0]?.details?.email)}}</span></span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M9.99984 11.6668L6.6665 8.3335H13.3332L9.99984 11.6668Z" fill="black"/>
                </svg>
            </div>
        </div>
        <div v-if="(currentCard=='email' && isEmailCard) || isEmailCard" class="expandedCard rounded-lg p-4 bg-white mt-4">
            <div class="flex justify-between" @click="expandCard('email')">
                <div class="flex justify-between w-64"> 
                    <span class="text-blue-600 text-base font-medium">Email</span>
                </div>
                <div class="flex items-center gap-2">
                <span>Reliability Score: <span :class="showCardStatus(outputData[0]?.details?.email)">{{showReliability(outputData[0]?.details?.email)}}</span></span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M9.99984 11.6668L6.6665 8.3335H13.3332L9.99984 11.6668Z" fill="black"/>
                </svg>
            </div>  
            </div>
            <div class="p-2 border-b"></div>
            <div class="flex mt-4 justify-start">
                <span class="opacity-50 w-3/4">Email Address : </span>
                <div class="ml-5 w-full flex flex-col">
                    <span>{{this.outputData?.[0]?.details?.email_id ? this.outputData?.[0]?.details?.email_id : '--'}}</span>
                    <span class="opacity-50 matchedStatus">{{this.outputData[0]?.complete_details?.email?.messages.find(el => el.toLowerCase().includes('email address'))}}</span>
                </div>
                
            </div>
        </div>

        <!---------- for identity card  ----------->
        <div v-if="!isIdentityCard" class="personalInfoAccordian flex justify-between rounded-lg p-4 mt-4" @click="expandCard('identity')">
            <span class="text-blue-600 text-base font-medium">Identity</span>
            <div class="flex items-center gap-2">
                <span>Reliability Score: <span :class="showCardStatus(outputData[0]?.details?.identity)">{{showReliability(outputData[0]?.details?.identity)}}</span></span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M9.99984 11.6668L6.6665 8.3335H13.3332L9.99984 11.6668Z" fill="black"/>
                </svg>
            </div>
        </div>
        <div v-if="(currentCard=='identity' && isIdentityCard) || isIdentityCard" class="expandedCard rounded-lg p-4 bg-white mt-4">
            <div class="flex justify-between" @click="expandCard('identity')">
                <div class="flex justify-between w-64"> 
                    <span class="text-blue-600 text-base font-medium">Identity</span>
                </div>
                <div class="flex items-center gap-2">
                <span>Reliability Score: <span :class="showCardStatus(outputData[0]?.details?.identity)">{{showReliability(outputData[0]?.details?.identity)}}</span></span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M9.99984 11.6668L6.6665 8.3335H13.3332L9.99984 11.6668Z" fill="black"/>
                </svg>
            </div>  
            </div>
            <div class="p-2 border-b"></div>
            <div class="flex mt-4 justify-start">
                <span class="opacity-50 w-3/4">First Name : </span>
                <div class="ml-5 w-full flex flex-col">
                    <span>{{ this.outputData[0]?.complete_details?.identity?.givenfullname ? this.outputData[0]?.complete_details?.identity?.givenfullname : '--'}}</span>
                    <!-- <span class="opacity-50 matchedStatus">{{this.outputData[0]?.complete_details?.identity?.messages.find(el => el.toLowerCase().includes('first name'))}}</span> -->
                </div>
            </div>  
            <div class="flex mt-4 justify-start">
                <span class="opacity-50 w-3/4">Middle Name : </span>  
                <div class="ml-5 w-full flex flex-col">
                    <span>{{ getMiddleName() ? getMiddleName() : '--'}}</span>
                    <!-- <span class="opacity-50 matchedStatus">{{this.outputData[0]?.complete_details?.identity?.messages.find(el => el.toLowerCase().includes('middle name'))}}</span> -->
                </div>
            </div>
            <div class="flex mt-4 justify-start">
                <span class="opacity-50 w-3/4">Last Name : </span>
                <div class="ml-5 w-full flex flex-col">
                    <span>{{ this.outputData[0]?.complete_details?.identity?.surname_first ? this.outputData[0]?.complete_details?.identity?.surname_first : '--'}}</span>
                    <!-- <span class="opacity-50 matchedStatus">{{this.outputData[0]?.complete_details?.identity?.messages.find(el => el.toLowerCase().includes('last name'))}}</span> -->
                </div>
            </div>
            <div class="flex mt-4 justify-start">
                <span class="opacity-50 w-3/4">National ID : </span>
                <div class="ml-5 w-full flex flex-col">
                    <span>{{ this.outputData[0]?.complete_details?.identity?.nationalid ? this.outputData[0]?.complete_details?.identity?.nationalid : '--'}}</span>
                    <!-- <span class="opacity-50 matchedStatus">{{outputData[0]?.complete_details?.identity?.messages.find(el => el.toLowerCase().includes(item?.idName.toLowerCase()))}}</span> -->
                </div>
            </div>
            <div class="flex mt-4 justify-start">
                <span class="opacity-50 w-3/4">Date of Birth : </span>
                <div class="ml-5 w-full flex flex-col">
                    <span>{{this.outputData?.[0]?.complete_details?.identity?.dob ? convertData(this.outputData?.[0]?.complete_details?.identity?.dob) : '--'}}</span>
                    <!-- <span class="opacity-50 matchedStatus">{{this.outputData[0]?.complete_details?.identity?.messages.find(el => el.toLowerCase().includes('date of birth'))}}</span> -->
                </div>
            </div>
        </div>
        
        <!---------- for phone number card  ----------->
        <div v-if="!isPhoneNoCard" class="personalInfoAccordian flex justify-between rounded-lg p-4 mt-4" @click="expandCard('phoneNo')" :class="{'disableSection' : !checkPhone}">
            <span class="text-blue-600 text-base font-medium">Phone Number</span>
            <div class="flex items-center gap-2">
                <span>Reliability Score: <span :class="showCardStatus(outputData[0]?.details?.phone)">{{ !checkPhone ? "--" : showReliability(outputData[0]?.details?.phone)}}</span></span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M9.99984 11.6668L6.6665 8.3335H13.3332L9.99984 11.6668Z" fill="black"/>
                </svg>
            </div>
        </div>
        <div v-if="(currentCard=='phoneNo' && isPhoneNoCard) || isPhoneNoCard" class="expandedCard rounded-lg p-4 bg-white mt-4">
            <div class="flex justify-between" @click="expandCard('phoneNo')">
                <div class="flex justify-between w-64"> 
                    <span class="text-blue-600 text-base font-medium">Phone Number</span>
                </div>
                <div class="flex items-center gap-2"> 
                    <span>Reliability Score: <span :class="showCardStatus(outputData[0]?.details?.phone)">{{showReliability(outputData[0]?.details?.phone)}}</span></span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M9.99984 11.6668L6.6665 8.3335H13.3332L9.99984 11.6668Z" fill="black"/>
                    </svg>
                </div>  
            </div>
            <div class="p-2 border-b"></div>
            <div class="flex mt-4 mb-4 justify-start">
                <span class="opacity-50 w-3/4">Phone Number : </span>
                <div class="ml-5 w-full flex flex-col">
                    <span>{{this.outputData?.[0]?.details?.phone_number ? this.outputData?.[0]?.details?.phone_number : '--'}}</span>
                    <!-- <span class="opacity-50 matchedStatus">{{this.outputData[0]?.complete_details?.identity?.messages.find(el => el.toLowerCase().includes('phone number'))}}</span> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FormatDateNew } from "@shared/plugins/functions";

export default {
    title: "Expanded Output",
    name: "gdc-check-expanded-output",
    props: {
        outputData:  {
            type: Array
        },
        updatedFilteredData:  {
            type: Object
        },
        gdcListedCountries: {
            type: Array
        }
    },
    data() {
        return {
            currentCard: "",
            isAddressCard: false,
            isEmailCard: false,
            isIdentityCard: false,
            isPhoneNoCard: false,
            isDeatilsCard: true,
            allCodeData: [],
            fullMatchCodes: ['1MT', '1AU', '1BZ'],
            partialMatchCodes: ['3MT', '3AU', '3BZ'],
            noMatchCodes: ['4MT', '2AU', '2BZ'],
            isExpendClicked: false,
            completeName: '',
        }
    },
    mounted() {
        this.getDetailTableData();
        this.completeName = this.outputData[0]?.complete_details?.identity?.completename
    },
    computed: {
        expandText() {
            if (this.isExpendClicked) return 'Collapse';
            else return 'Expand';
        },
        checkEmail() {
            if (this.outputData?.[0]?.details?.email_id && this.outputData?.[0]?.details?.email_id != '') return true;
            else return false;
        },
        checkPhone() {
            if (this.outputData?.[0]?.details?.phone_number && this.outputData?.[0]?.details?.phone_number != '') return true;
            else return false;
        },
    },
    methods: {
        FormatDateNew,
        matchType(codeData) {
            let codeArray = codeData?.split('-')
            let matchValue = codeArray?.[0]
            if (this.fullMatchCodes?.includes?.(matchValue)) {
                return 'Full Match';
            } else if (this.partialMatchCodes?.includes?.(matchValue)) {
                return 'Partial Match';
            } else if (this.noMatchCodes?.includes?.(matchValue)) {
                return 'No Match';
            } else {
                return matchValue;
            }
        },
        sourceName(codeData) {
            let codeArray = codeData?.split('-')
            let sourceValue = codeArray?.[1]
            let sourceName = codeArray?.[2]
            if (sourceValue) {
                let currentCountry = this.gdcListedCountries?.find(item => item?.country_code == sourceValue)
                if (currentCountry) {
                    const findSourceName = currentCountry?.source_id_details?.find(item =>  item?.data_source_name == sourceName);
                    // let findSourceName = currentCountry?.source_id_details?.find(item => item?.data_source_name == sourceValue?.length == 2 ? codeArray?.[2] : codeArray?.[1])
                    if (findSourceName && (findSourceName != 'undefiend' || findSourceName !== undefined)) {
                        return findSourceName?.data_source_description || '';
                    } else {
                        return sourceName;
                    }
                }
                return "";
            } else {        
                return '-';
            }
        },
        fieldName(codeData) {
            let codeArray = codeData?.split('-');
            let fieldValue = codeArray?.[1]?.length == 2 ? codeArray?.[3] : codeArray?.[2];
            if (fieldValue) {
                return fieldValue;
            } else {
                return '-';
            }
        },
        fieldValue(data) {
            let fieldName = this.fieldName(data);
            let fieldValueData = '--';
            switch (fieldName) {
                case 'COMPLETENAME':
                    fieldValueData = this.outputData?.[0]?.details?.completename
                    break;
                case 'FIRSTINITIAL':
                    fieldValueData = '--'
                    break;
                case 'FIRSTNAME':
                    fieldValueData = this.updatedFilteredData?.['Name']?.[0]?.first_name;
                    break;
                case 'LASTNAME':
                    fieldValueData = this.updatedFilteredData?.['Name']?.[0]?.last_name;
                    break;
                case 'ADDRESS':
                    fieldValueData = this.outputData?.[0]?.details?.address?.address;
                    break;
                case 'PROVINCE':
                    fieldValueData = this.outputData?.[0]?.complete_details?.address?.province;
                    break;
                case 'HOUSENUMBER':
                    fieldValueData = this.outputData?.[0]?.complete_details?.address?.houseNumber;
                    break;
                case 'THOROUGHFARE':
                    fieldValueData = this.outputData?.[0]?.complete_details?.address?.thoroughfare;
                    break;
                case 'LOCALITY':
                    fieldValueData = this.outputData?.[0]?.complete_details?.address?.locality;
                    break;
                case 'DISTRICT':
                    fieldValueData = this.outputData?.[0]?.complete_details?.address?.district;
                    break;
                case 'POSTALCODE':
                    fieldValueData = this.outputData?.[0]?.complete_details?.address?.postalCode;
                    break;
                case 'NATIONALID':
                    fieldValueData = this.outputData?.[0]?.complete_details?.identity?.nationalid;
                    break;
                case 'PHONENUMBER':
                    fieldValueData = this.outputData?.[0]?.details?.phone_number;
                    break;
                case 'DATEOFBIRTH':
                    fieldValueData = this.outputData?.[0]?.details?.dob;
                    break;
                case 'DAYOFBIRTH':
                    fieldValueData = this.outputData?.[0]?.details?.day_of_birth;
                    break;
                case 'MONTHOFBIRTH':
                    fieldValueData = this.outputData?.[0]?.details?.month_of_birth;
                    break;
                case 'YEAROFBIRTH':
                    fieldValueData = this.outputData?.[0]?.details?.year_of_birth;
                    break;
                default:
                    break;
            }
            return fieldValueData;
        },
        getDetailTableData() {
            let addressCodeData = this.outputData?.[0]?.complete_details?.address?.codes
            let identityCodeData = this.outputData?.[0]?.complete_details?.identity?.codes
            let emailCodeData = this.outputData?.[0]?.complete_details?.email?.codes
            let phoneCodeData = this.outputData?.[0]?.complete_details?.phone?.codes
            this.allCodeData = [...addressCodeData?.messages, ...identityCodeData?.messages, ...emailCodeData?.messages, ...phoneCodeData?.messages];
            this.allCodeData = this.allCodeData?.filter(item => item?.code != 'Codes' && item?.code != 'WV');
        },
        expandCard(card) {
            this.currentCard = card
            if(this.isAddressCard) this.isAddressCard = true;
            if(card=='address') this.isAddressCard = !this.isAddressCard;
            if(this.isEmailCard) this.isEmailCard = true;
            if(card=='email' && this.checkEmail) this.isEmailCard = !this.isEmailCard;
            if(this.isIdentityCard) this.isIdentityCard = true;
            if(card=='identity') this.isIdentityCard = !this.isIdentityCard;
            if(this.isPhoneNoCard) this.isPhoneNoCard = true;
            if(card=='phoneNo' && this.checkPhone) this.isPhoneNoCard = !this.isPhoneNoCard;
            if(card=='details') this.isDeatilsCard = !this.isDeatilsCard;  
            if(card == 'all') {
                if (!this.isExpendClicked) {
                    this.isIdentityCard = true;
                    this.isAddressCard = true;
                    if (this.checkEmail) this.isEmailCard = true;
                    if(this.checkPhone) this.isPhoneNoCard = true;
                    this.isDeatilsCard = true;
                } else {
                    this.isIdentityCard = false;
                    this.isAddressCard = false;
                    if (this.checkEmail) this.isEmailCard = false;
                    if(this.checkPhone) this.isPhoneNoCard = false;
                    this.isDeatilsCard = false;
                }
                this.isExpendClicked = !this.isExpendClicked;
            }    
        },
        showReliability(cardDetails) {
            if (cardDetails?.reliability == '30') return 'Low';
            if (cardDetails?.reliability == '20') return 'Medium';
            if (cardDetails?.reliability == '10') return 'High';
        },
        showCardStatus(cardData) {
            if (cardData?.reliability == '30') return 'lowMatch';
            if (cardData?.reliability == '20') return 'mediumMatch';
            if (cardData?.reliability == '10') return 'highMatch';
        },
        getFirstName(){
            let firstName = this.updatedFilteredData['Name']?.find(el => el.checked)?.first_name
            return firstName
        },
        getMiddleName(){
            const nameParts = this.completeName.split(" ");
            let middleName = '';
            if (nameParts.length > 2) {
                middleName = nameParts[nameParts.length-2];
            }
            return middleName
        },
        getLastName(){
            let lastName = this.updatedFilteredData['Name']?.find(el => el.checked)?.last_name
            return lastName
        },
        convertData(date) {
            let newDate = new Date(date);
            let convertedDate = this.FormatDateNew(newDate);
            return convertedDate;
        },
    }
}
</script>

<style scoped lang='scss'>

.personalInfoAccordian{
    // width: 405px; 
    border: 0.5px solid #CAD4E8; 
    &:hover {
        background-color: #F6F8FC;
    } 
}
.expandedCard{
    // width: 405px;  
    border: 0.5px solid #CAD4E8;
}
.lowMatch {
    color: #F00;
}
.mediumMatch {
    color: #FFB800;
}
.highMatch {
    color: #3E9F3C;
}
.matchedStatus {
    font-size: 13px;
}
.expandAllButton {
    cursor: pointer;
    margin-right: 10px;
    color: #0D69D5;
}
.detailHeadings {
    border: 1px solid #DBDDF1;
    background: #F9FAFB;
    th{
        color: #6B737F;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        border-top: 1px solid #DBDDF1;
        border-bottom: 1px solid #DBDDF1;
        border-left: 1px solid #DBDDF1;
        height: 32px;
        padding: 12px 24px;
        align-items: center;
        gap: 10px;
        align-self: stretch;
    }
}
.sourceName {
    width: 35%;
}
.detailData {
    td {
        padding: 5px 15px 5px 15px;
        border: 1px solid #DBDDF1;
        // text-align: center;
    }
}
.outputModalScreen {
    max-height: 90%;
    overflow-y: scroll;
}
.disableSection {
    background: #f5f6f8;
    cursor: not-allowed;
    &:hover{
        background: #f5f6f8;
    }
}
.matchType {
    min-width: 130px;
}
</style>



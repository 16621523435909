<template>
    <div style="height:100%" class="rounded-md relative" v-if="activeImage">

        <img style="object-fit:contain;" class="h-full w-full"  :src="activeImage.link" :alt="activeImage.name" />

        <div class="navs_dots flex justify-between px-2 absolute top-1/2 w-full">
            <button :class="isFirst ? 'opacity-50 cursor-not-allowed bg-gray-200' : 'bg-gray-300 hover:bg-gray-400' " :disabled="isFirst" @click="handlePrev" class="left h-8 w-8  cursor-pointer rounded-full flex items-center justify-center text-lg"><font-awesome-icon icon="caret-left" /></button>
            <button :class="isLast ? 'opacity-50 cursor-not-allowed bg-gray-200' : 'bg-gray-300 hover:bg-gray-400' " :disabled="isLast" @click="handleNext" class="right h-8 w-8 cursor-pointer rounded-full flex items-center justify-center"><font-awesome-icon icon="caret-right" /></button>
        </div>
    </div>
</template> 

<style scoped>

</style>

<script>
export default {
    name:"gallery-view",
    components:{},
    data(){
        return{
            activeImage: null,
            activeImageNo: 0,
        }
    },
    props: ["images"],
    computed:{
        getImages(){
            return this.images
        },
        isFirst(){
            if(this.activeImageNo === 0) return true
            else return false
        },
        isLast(){
            if(this.activeImageNo === (this.getImages.length -1)) return true
            else return false
        }
    },
    mounted(){
        if(this.images && this.images.length){
            this.activeImage = this.images[this.activeImageNo]
        }
    },
    methods:{
        handleNext(){
            if(this.getImages && this.getImages.length > 1){
                if(this.getImages.length > this.activeImageNo ){
                    this.activeImageNo += 1
                    this.activeImage = this.getImages[this.activeImageNo]
                }
            }
        },
        handlePrev(){
            if(this.getImages && this.getImages.length > 0 && this.activeImageNo > 0){
                this.activeImageNo -= 1
                this.activeImage = this.getImages[this.activeImageNo]
            }
        }
    }
}
</script>

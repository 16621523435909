export const CASE_CREATION_STEPS = {
    ENTITY_DETAILS: 'entity_details',
    COLLECT_INFO: 'collect_info',
    CHECKS: 'checks',
    SUBMIT: 'submit',
};

export const CHANGE_STATUSES = {
    ADDED: 'added',
    UPDATED: 'updated',
    DELETED: 'deleted',
};

export const CASE_CREATION_STEPS_LIST  = [
    {
      id: CASE_CREATION_STEPS.ENTITY_DETAILS,
      label: "Entity Details",
    },
    {
      id: CASE_CREATION_STEPS.COLLECT_INFO,
      label: "Collect Info",
    },
    {
      id: CASE_CREATION_STEPS.CHECKS,
      label: "Checks",
    },
    {
      id: CASE_CREATION_STEPS.SUBMIT,
      label: "Submit",
    },
];

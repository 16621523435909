import axios from "@/axios";

export async function getCaseEntities(caseId) {
  const url = `case/${caseId}/entity`;
  const { data } = await axios.get(url);
  return data
}

export async function getChecksLogs(refChecksList = []) {
  const checksPromiseList = refChecksList.map(({ id }) => axios.get(`workflow/${id}/log`));
  const checksResponse = await Promise.all(checksPromiseList);

  return checksResponse
      .map(resp => resp?.data?.data)
      .map((resp, index) => {
          return resp.map(item => ({
              ...item,
              check_name: refChecksList[index]?.check_name,
          }));
      })
      .flat();
}

import { render, staticRenderFns } from "./analystComment.vue?vue&type=template&id=0bb59113&scoped=true"
import script from "./analystComment.vue?vue&type=script&lang=js"
export * from "./analystComment.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bb59113",
  null
  
)

export default component.exports
<template>
  <div class="flex cursor-pointer justify-between gap-0  overflow-hidden text-gray-500 w-28 bg-gray-50 rounded-full relative p-0.5" 
    :class="{'opacity-70 pointer-events-none' : disabled, 'bg-green-500 text-green-700' : getValue === 'correct' ,
    'bg-red-500 text-red-700' :  getValue === 'incorrect', 
    'bg-base-300 text-gray-600' : getValue === 'unselected'} "
    >
        <Transition name="slide-fade">
            <div class="flex justify-between  w-full  items-center" v-if="getValue == 'correct' || getValue === 'unselected' " @click.stop="setVal('correct')">
                <!-- <span  class="check-box-label flex items-center gap-x-1"> -->
                    <small class="flex-grow text-center  text-xs px-1 font-bold text-white-text " v-if="getValue !== 'unselected'">
                        Correct
                    </small>
                    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0">
                        <circle cx="16" cy="16.1875" r="16" fill="currentColor"/>
                        <path d="M12.5119 19.7955L11.4529 20.8578L13.5775 22.9758L14.6365 21.9135L12.5119 19.7955ZM23.9403 12.5808C24.5252 11.9941 24.5238 11.0444 23.9371 10.4595C23.3504 9.87462 22.4006 9.87609 21.8157 10.4628L23.9403 12.5808ZM14.6365 21.9135L23.9403 12.5808L21.8157 10.4628L12.5119 19.7955L14.6365 21.9135Z" fill="white"/>
                        <path d="M12.5136 21.9152L13.5742 22.9758L15.6955 20.8545L14.6349 19.7938L12.5136 21.9152ZM9.72606 14.885C9.14027 14.2992 8.19053 14.2992 7.60474 14.885C7.01895 15.4708 7.01895 16.4205 7.60474 17.0063L9.72606 14.885ZM14.6349 19.7938L9.72606 14.885L7.60474 17.0063L12.5136 21.9152L14.6349 19.7938Z" fill="white"/>
                    </svg>
                <!-- </span> -->
            </div>
        </Transition>

        <Transition name="slide-fade-left">
            <div class=" flex items-center   justify-between" v-if="getValue == 'incorrect'  || getValue === 'unselected'  " @click.stop="setVal('incorrect')">
                <!-- <span  class="check-box-label flex items-center gap-x-1" > -->
                    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0">
                        <circle cx="16" cy="16.1875" r="16" fill="currentColor"/>
                        <path d="M10 22.459L22.2332 10.1879" stroke="white" stroke-width="3" stroke-linecap="round"/>
                        <path d="M10 10.1875L22.2711 22.4207" stroke="white" stroke-width="3" stroke-linecap="round"/>
                    </svg>
                    <small class="flex-grow text-center  text-xs px-1 font-bold text-white-text " v-if="getValue !== 'unselected'">
                        Incorrect
                    </small>
                <!-- </span> -->
            </div>
        </Transition>

  </div>
</template>

<script>
export default {
    name: "refree-chekbox",
    components: {  },
    props: {
        value: {
            default: 'unselected',
            type: String
        },
        id: {
            default: "default-checkbox",
            type: String
        },
        disabled: {
            type: Boolean,   
            default: false
        }
    },
    computed: {
        getValue: {
            get() {
                return this.value
            },
            set(val) {
                console.log(val);
                this.$emit('change',val)
            }
        }
    },
    methods: {
        setVal(val) {
            if (!this.disabled && val) {
                if (val === this.getValue) {
                    this.getValue = this.getValue === 'unselected' ? 'correct' :  this.getValue === 'correct' ? 'incorrect' : 'correct'
                } else this.getValue = val
            }
        }
    }
};
</script>

<style>
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active,.slide-fade-left-enter-active {
  transition: all 0.01s linear;
}

.slide-fade-leave-active, .slide-fade-left-leave-active  {
  transition: all 0.01s linear;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.slide-fade-left-enter-from,
.slide-fade-left-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}
</style>
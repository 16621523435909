<template>
    <modal name="call-log-modal" ref="call-log-modal" width="60%" height="65%" class="rounded-full" v-bind="$attrs" v-on="$listeners">
        <div class="flex flex-col justify-between min-h-full h-full bg-card-bg">
            <div class="w-full min-h-12 flex items-center justify-between px-6 bg-primary">
                <h3 class="font-semibold text-white-text flex items-center justify-center gap-2">
                    <CallIconWhite width="20px" height="20px" />
                    <span>Log Call</span>
                </h3>
                <span class="flex flex-col translate-x-full relative" role="button" @click="closeModal">
                    <CloseIcon />
                </span>
            </div>

            <div class="container px-6 flex-grow w-full max-w-full overflow-y-auto scroll-bar">
                <!-- form ui -->
                <div class="call-form-wrapper w-full h-full">
                    <!-- <div class="flex items-center justify-end gap-2"></div> buttons to be added in this div-->
                    <FormulateForm class="call-form-container flex flex-col py-4 gap-4 w-full h-full">
                        <div class="flex flex-wrap gap-4 gap-y-3 items-center">
                            <Field class="w-48" title="Contacted" v-model="callLogData.contacted">
                                <template #icon>
                                    <ProfileIcon />
                                </template>
                            </Field>
                            <Field class="w-40" title="Phone No" v-model="callLogData.phone">
                                <template #icon>
                                    <CallIcon />
                                </template>
                            </Field>
                            <Field class="w-40" title="Date" @input="handleDateChange" type="date" v-model="callLogData.date">
                                <template #icon>
                                    <DateIcon />
                                </template>
                            </Field>
                            <!-- <Field class="w-40" title="Time" type="time" v-model="callLogData.time">
<template #icon>
<TimeIcon />
</template>
</Field> -->

                            <Field class="w-40" type="time" title="Time" :show-minute="false" :show-second="false" format="HH" v-model="callLogData.time">
                                <template #icon>
                                    <TimeIcon />
                                </template>
                            </Field>
                        </div>
                        <div class="note-text-area gap-5 h-full flex flex-col flex-1 items-start w-full">
                            <quill-editor v-model="callLogData.content" ref="myQuillEditor" :options="editorOptions" />
                        </div>
                    </FormulateForm>
                </div>
            </div>
            <footer class="flex items-end justify-between pb-4 px-6">
                <!-- TODO -->
                <!-- <div
class="footer-left-actions h-14 border-none py-4 px-9 cursor-pointer rounded text-sm flex items-center gap-4 bg-primary text-white-text">
<label for="mail_attachments" class="cursor-pointer relative w-8 h-8 grid place-content-center">
<EncloseIcon />
<span v-if="attachedFiles.length"
class="absolute -top-1 right-0 text-black rounded-full w-3 h-3 grid place-content-center">
{{ attachedFiles.length }}
</span>
</label>

        <input @change="fetchFileName('mail_attachments')" id="mail_attachments" type="file" name="mail_attachments"
          multiple="multiple" class="hidden" />
        <span>
          <DelIcon @click="resetAttachments" /></span>
      </div> -->
                <div class="ml-auto relative" v-if="checkPermission('case.actions_internal')">
                    <button type="submit" class="transition rounded-lg bg-blue-600 hover:bg-blue-700 focus:bg-gray-400 border-0 text-white-text-600 text-sm h-9 font-semibold px-4" @click="logActivity">Log Activity</button>
                </div>
            </footer>
        </div>
    </modal>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.snow.css";
// import Button from "@/components/button/index.vue";
import Field from "@/components/sc-journey/field-modal.vue";
import CloseIcon from "@/assets/icons/email-admin/icon-crossbt.svg";
import CallIconWhite from "@/assets/icons/sc-journey/phone-white.svg";
import CallIcon from "@/assets/icons/sc-journey/phone.svg";
import DateIcon from "@/assets/icons/dashboard/date.svg";
import ProfileIcon from "@/assets/icons/sc-journey/profile.svg";
import TimeIcon from "@/assets/icons/dashboard/clock.svg";
import { checkPermission } from "@shared/utils/functions";
// import { validatePhoneNumber } from "@/plugins/functions";
export default {
    name: "call-log-modal",
    components: {
        quillEditor,
        // "neo-button": Button,
        CallIconWhite,
        CallIcon,
        Field,
        CloseIcon,
        DateIcon,
        ProfileIcon,
        TimeIcon,
    },
    data() {
        return {
            editorOptions: {
                debug: "info",
                placeholder: "type your text here",
                theme: "snow",
                modules: {
                    toolbar: [[{ size: ["small", false, "large", "huge"] }], ["bold", "italic", "underline", "strike"]],
                },
            },
            callLogData: {
                contacted: "",
                phone: "",
                date: "",
                time: "",
                content: "",
            },
        };
    },
    mounted() {
        this.closeModal();
        this.assignDateFirst();
    },
    methods: {
        checkPermission,
        assignDateFirst() {
            let date = new Date();
            const year = date.toLocaleString("default", { year: "numeric" });
            const month = date.toLocaleString("default", { month: "2-digit" });
            const day = date.toLocaleString("default", { day: "2-digit" });
            this.callLogData.date = year + "-" + month + "-" + day;
            this.callLogData.time = new Date(new Date().setHours(new Date().getHours() + 1, 0, 0));
        },
        closeModal() {
            this.callLogData = {
                contacted: "",
                phone: "",
                date: "",
                time: "",
                content: "",
            }
            this.$modal.hide("call-log-modal");
        },
        logActivity() { 
            this.callLogData.contacted = this.callLogData.contacted.trim();
            this.callLogData.content = this.callLogData.content.trim();
            if(this.callLogData.contacted.length === 0 || this.callLogData.content.length == 0){
                this.$toast.error("Empty Spaces are not allowded");
                return
            }
            // TODO validate form
            let callLogData = this.callLogData;
            const qs = Object.keys(callLogData)
                .filter((key) => callLogData[key] === "")
                .map((key) => `${key}`);
            if (qs.length > 0) {
                this.$toast.error("Please fill all mandatory fields");
            } else {
                this.$emit("logActivity", this.callLogData);
                this.closeModal();
                this.resetForm();
            }
        },
        resetFormCall() {
            this.callLogData = {
                contacted: "",
                phone: "",
                date: "",
                time: "",
                content: "",
            };
        },
        handleDateChange() {
            if (this.callLogData.date) {
                let dueDate = new Date(new Date(this.callLogData.date).setHours(0, 0, 0, 0)).getTime();
                let today = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
                if (dueDate === today) {
                    this.callLogData = { ...this.callLogData, time: new Date(new Date().setHours(new Date().getHours() + 1, 0, 0)) };
                } else {
                    this.callLogData = { ...this.callLogData, time: new Date(new Date().setHours(0, 0, 0)) };
                }
            }
        },
        // DisableDate(date) {
        // return date < new Date(new Date().setHours(0, 0, 0, 0));
        // },
        // disabledHours(date) {
        // let dueDate = new Date(new Date(this.callLogData.date).setHours(0, 0, 0, 0)).getTime();
        // let today = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
        // if (dueDate === today) {
        // return date.getHours() <= new Date().getHours();
        // }
        // },
    },
};
</script>

<style lang="scss" scoped>
.modal-header {
    &__icon {
        fill: white;
        * {
            fill: white;
        }
    }
}
</style>

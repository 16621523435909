<template>
<div class="referee-directory-item flex items-center justify-between rounded-lg overflow-hidden bg-gray-100 h-12 pr-5 gap-2">
  <div
    class="side-indicator w-2 self-stretch flex-shrink-0"
    :class="{
      'bg-primary': selected,
      'bg-gray-300': !selected,
    }"
  ></div>
  <div class="referee-directory-item__name text-sm flex-grow w-1/2">
    {{data.referee_name}}
  </div>
  <div class="referee-directory-item__contacts text-primary text-xxs flex flex-col gap-2 flex-grow w-1/2">
    <div class="phone gap-1 2xl:gap-2 flex items-center">
      <PhoneIcon class="phone__icon w-3 h-3"/>
      <div class="phone__value">{{ data.referee_phone }}</div>
    </div>
    <div class="email gap-1 2xl:gap-2 flex items-center"> 
      <EmailIcon class="email__icon w-3 h-3"/>
      <div class="email__value">{{ data.referee_email }}</div>
    </div>
  </div>
  <EditIcon
    class="w-4 h-4 cursor-pointer flex-shrink-0"
  />
</div>
</template>

<script>
import PhoneIcon from '@/assets/icons/sc-journey/phone.svg';
import EmailIcon from '@/assets/icons/sc-journey/email.svg';
import EditIcon from '@/assets/icons/sc-journey/edit.svg';

export default {
  name: 'referee-directory-item',
  components: {
    EmailIcon,
    PhoneIcon,
    EditIcon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    selected: Boolean,
  }
}
</script>

<style lang="scss" scoped>
.referee-directory-item {
  &__name {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
<template>
    <RadialProgressBar :diameter="size" :completed-steps="radialValue" :total-steps="total" :startColor="strokeColor" :stopColor="strokeColor" :innerStrokeColor="innerStrokeColor" >
        <p v-if="showTotal" class="text-lg font-bold text-gray-500">{{ radialValue }} / {{total}} </p>
        <p v-else class="text-2xl font-bold text-gray-500">{{ radialValue }} </p>
    </RadialProgressBar>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress'

export default {
    name:"radial-progress",
    components:{
        RadialProgressBar,
    },
    props:{
        value: {
            type: Number,
            default: 0,
        },
        total: {
            type: Number,
            default: 0,
        },
        color: {
            type: String,
            default: 'primary',
        },
        size:{
            type: Number,
            default: 100,
        },
        showTotal:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            innerStrokeColor: "rgb(238, 238, 238)",
            colors: {
                primary: "#0D69D5",
                success: "#34d399",
                error: "#ff0102",
                warning: "#FFB800",
            }
        }
    },
    computed:{
        radialValue(){
            return this.value
        },
        strokeColor(){
            if(this.color){
                return this.colors[this.color] || this.colors.primary
            }else{
                return this.colors.primary
            }
        },
    }
}
</script>


<template>
    <div class='ml-10 mt-4'>
        <button class="px-3 py-2 rounded-lg bg-primary text-white text-sm font-medium" @click="backToRunCheck">Back to Search</button>
        <div v-show='false' class='filterIcon' @click="toggleFilter" :class="{'bg-primary' : showFilter, 'bg-white' : !showFilter}">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" :fill="!showFilter ? '#0D69D5' : '#FFFFFF'">
                <g clip-path="url(#clip0_8403_4428)">
                  <path d="M11.4369 0H0.563153C0.0635131 0 -0.188604 0.606234 0.165419 0.960258L4.49999 5.29549V10.125C4.49999 10.3085 4.58955 10.4805 4.73992 10.5858L6.61492 11.8979C6.98484 12.1568 7.49999 11.8944 7.49999 11.437V5.29549L11.8347 0.960258C12.188 0.606938 11.9376 0 11.4369 0Z" :fill="!showFilter ? '#0D69D5' : '#FFFFFF'"/>
                </g>
                <defs>
                  <clipPath id="clip0_8403_4428">
                    <rect width="12" height="12" :fill="!showFilter ? '#0D69D5' : '#FFFFFF'"/>
                  </clipPath>
                </defs>
            </svg>
        </div>
        <div class="flex">
            <div v-if="showFilter" class="px-4 mt-3 overflow-y-auto scroll-bar flex flex-col">
                <div v-for="(item, id, currIndex) in userData" :key="currIndex" class="px-2 py-2 bg-white flex justify-between filterDropdown">
                    <div v-if="item?.values?.length > 0" class="flex flex-col">
                        <div class="flex">
                            <div class="flex items-center" @click="showPersonalData(currIndex)">
                                <svg v-if="!showMultipleFields.includes(currIndex)" xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                    <g opacity="0.5">
                                        <path d="M4.5 9.51649V3.48602C4.5 3.06883 5.00391 2.86024 5.29922 3.15555L8.31562 6.16961C8.49844 6.35243 8.49844 6.65008 8.31562 6.8329L5.29922 9.84696C5.00391 10.1423 4.5 9.93368 4.5 9.51649Z" fill="black"/>
                                    </g>
                                </svg>
                                <svg v-else xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                    <g opacity="0.5">
                                        <path d="M2.98351 5H9.01398C9.43117 5 9.63976 5.50391 9.34445 5.79922L6.33039 8.81562C6.14757 8.99844 5.84992 8.99844 5.6671 8.81562L2.65304 5.79922C2.35773 5.50391 2.56632 5 2.98351 5Z" fill="black"/>
                                    </g>
                                </svg>
                            </div>
                            <span class="font-semibold px-2 ml-3"> {{item?.keyName}} </span>
                        </div>
                        <div v-if="showMultipleFields.includes(currIndex)">
                            <div v-for="(data, index) in item?.values" :key="index" class="p-2 rounded-lg flex justify-between">
                                <div class="flex w-full ml-4">
                                    <input type="checkbox" :checked="data?.checked" @input="applyFilter($event, id, data, index)" class="checkbox checkbox-primary runCheckCheckbox"/>
                                    <span class="innerFilters px-2 w-3/5 ml-3"> {{data.value}} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex"> 
                <div class="mainCardDataSec mt-3">
                    <div :class="{'allCardsWithFilter' : showFilter, 'setOutputWidth' : showOutputScreen}" class="mb-3 p-4 flex bg-white allCards justify-between" v-for="(cardItem, cardIdx) in outputData" :key="cardIdx">
                        <div class="flex" :class="{'flex-wrap' : showOutputScreen}">
                            <div class="flex" :class="{'nameAndAddress' : showOutputScreen}">
                                <div class="flex flex-col justify-center p-2 mr-2 w-36">
                                    <div class="namedata flex">
                                        <svg class='mr-3' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M3.3335 18.3333C3.3335 14.6513 6.31826 11.6666 10.0002 11.6666C13.6821 11.6666 16.6668 14.6513 16.6668 18.3333H15.0002C15.0002 15.5718 12.7616 13.3333 10.0002 13.3333C7.23874 13.3333 5.00016 15.5718 5.00016 18.3333H3.3335ZM10.0002 10.8333C7.23766 10.8333 5.00016 8.59575 5.00016 5.83325C5.00016 3.07075 7.23766 0.833252 10.0002 0.833252C12.7627 0.833252 15.0002 3.07075 15.0002 5.83325C15.0002 8.59575 12.7627 10.8333 10.0002 10.8333ZM10.0002 9.16658C11.8418 9.16658 13.3335 7.67492 13.3335 5.83325C13.3335 3.99159 11.8418 2.49992 10.0002 2.49992C8.1585 2.49992 6.66683 3.99159 6.66683 5.83325C6.66683 7.67492 8.1585 9.16658 10.0002 9.16658Z" fill="#0D68D7"/>
                                        </svg>
                                        <span :title="cardItem?.details.full_name">{{cardItem?.details.full_name ? cardItem?.details.full_name.length > 8 ? cardItem?.details.full_name.substring(0,8)+'...' : cardItem?.details.full_name : '--'}}</span> 
                                    </div>
                                    <div class="mt-4 flex">
                                        <svg class='mr-3' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M7.49984 0.833252V2.49992H12.4998V0.833252H14.1665V2.49992H17.4998C17.9601 2.49992 18.3332 2.87302 18.3332 3.33325V16.6666C18.3332 17.1268 17.9601 17.4999 17.4998 17.4999H2.49984C2.0396 17.4999 1.6665 17.1268 1.6665 16.6666V3.33325C1.6665 2.87302 2.0396 2.49992 2.49984 2.49992H5.83317V0.833252H7.49984ZM16.6665 9.16658H3.33317V15.8332H16.6665V9.16658ZM5.83317 4.16658H3.33317V7.49992H16.6665V4.16658H14.1665V5.83325H12.4998V4.16658H7.49984V5.83325H5.83317V4.16658Z" fill="#0D69D5"/>
                                        </svg>
                                     
                                        {{cardItem?.details?.dob ? convertData(cardItem?.details?.dob) : '--'}}
                                    </div>
                                </div>
                                <div class="items-center flex w-36">
                                    <div>
                                        <svg  class='mx-3 -mt-8' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M10 17.4163L14.1247 13.2915C16.4028 11.0135 16.4028 7.32001 14.1247 5.04196C11.8467 2.7639 8.15327 2.7639 5.87521 5.04196C3.59715 7.32001 3.59715 11.0135 5.87521 13.2915L10 17.4163ZM10 19.7733L4.6967 14.4701C1.76777 11.5411 1.76777 6.79238 4.6967 3.86345C7.62563 0.934515 12.3743 0.934515 15.3033 3.86345C18.2323 6.79238 18.2323 11.5411 15.3033 14.4701L10 19.7733ZM10 10.8334C10.9205 10.8334 11.6667 10.0872 11.6667 9.16675C11.6667 8.24627 10.9205 7.50008 10 7.50008C9.0795 7.50008 8.33333 8.24627 8.33333 9.16675C8.33333 10.0872 9.0795 10.8334 10 10.8334ZM10 12.5001C8.15905 12.5001 6.66667 11.0077 6.66667 9.16675C6.66667 7.3258 8.15905 5.83341 10 5.83341C11.8409 5.83341 13.3333 7.3258 13.3333 9.16675C13.3333 11.0077 11.8409 12.5001 10 12.5001Z" fill="#0D69D5"/>
                                        </svg>
                                    </div >
                                    <span class="adddata" :title="cardItem?.details?.address.address">
                                        {{cardItem?.details?.address?.address.length > 28 ? cardItem?.details?.address?.address.substring(0,28)+'...' : cardItem?.details?.address?.address}}
                                    </span>
                                </div>
                            </div>
                            <div class="flex reliabilityScoreBlock" :class="{'usersStatus': showOutputScreen}"> 
                                <div class="flex flex-col relTest justify-center" :class="{'cardsStatus': showOutputScreen}">
                                    <span class="allStatusType" :class="showCardStatus(cardItem?.details?.address)">{{ getUpdatedAddress(cardItem?.complete_details?.address) ? showReliability(cardItem?.details?.address) : "--"}}</span>
                                    <span class="allStatusText" :class="{'statusReadability': showOutputScreen}">Reliability for <br/><span class="text-primary">Address</span></span>
                                </div>
                                <div class="flex flex-col relTest justify-center" :class="{'cardsStatus': showOutputScreen}">
                                    <span :class="showCardStatus(cardItem?.details?.identity)" class="allStatusType">{{ getUpdatedIdentity(cardItem?.complete_details?.identity) ? showReliability(cardItem?.details?.identity) : "--"}}</span>
                                    <span class="allStatusText" :class="{'statusReadability': showOutputScreen}">Reliability for  <br/><span class="text-primary">Identity</span></span>
                                </div>
                                <div class="flex flex-col relTest justify-center" :class="{'cardsStatus': showOutputScreen}">
                                    <span :class="showCardStatus(cardItem?.details?.email)" class="allStatusType">{{ getUpdatedEmail(cardItem?.complete_details?.email) ?  showReliability(cardItem?.details?.email) : "--"}}</span>
                                    <span class="allStatusText" :class="{'statusReadability': showOutputScreen}">Reliability for  <br/><span class="text-primary">Email</span></span>
                                </div>
                                <div class="flex flex-col relTest justify-center" :class="{'cardsStatus': showOutputScreen}">
                                    <span :class="showCardStatus(cardItem?.details?.phone)" class="allStatusType">{{ getUpdatedPhone(cardItem?.complete_details?.phone) ? showReliability(cardItem?.details?.phone) : "--"}}</span>
                                    <span class="allStatusText" :class="{'statusReadability': showOutputScreen}">Reliability for  <br/><span class="text-primary">Phone</span></span>
                                </div>  
                            </div>
                        </div>
                        <div v-if="!showFilter" class="flex items-start">
                            <div v-show=false class="rounder-lg matchData px-2 mr-2">{{cardItem?.matched_data_count}}</div>
                            <div v-show=false class="px-2 roundedData mr-2">-</div>
                            <div v-show=false class="mr-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 23 20" fill="none">
                                    <path opacity="0.2" d="M13.6075 1.22205L22.5306 16.4089C22.7448 16.769 22.8575 17.1784 22.8571 17.5952C22.8568 18.012 22.7434 18.4212 22.5285 18.7809C22.3086 19.1549 21.9911 19.4649 21.6087 19.6791C21.2262 19.8933 20.7926 20.004 20.3521 19.9999H2.50607C2.06526 20.0044 1.63117 19.8938 1.24834 19.6796C0.86551 19.4654 0.54773 19.1552 0.327608 18.7809C0.113064 18.421 0 18.0117 0 17.5949C0 17.1781 0.113064 16.7688 0.327608 16.4089L9.25062 1.22205C9.4736 0.849982 9.79192 0.541479 10.174 0.327123C10.5562 0.112767 10.9888 0 11.4291 0C11.8694 0 12.302 0.112767 12.6841 0.327123C13.0662 0.541479 13.3846 0.849982 13.6075 1.22205Z" fill="#EB3131"/>
                                    <path d="M11.4358 8.31492C11.1396 8.31492 10.8912 8.21928 10.6906 8.02802C10.49 7.83676 10.3896 7.59996 10.3896 7.31761C10.3896 7.03527 10.49 6.79847 10.6906 6.60721C10.8912 6.41594 11.1396 6.32031 11.4358 6.32031C11.7224 6.32031 11.966 6.41594 12.1666 6.60721C12.3673 6.79847 12.4676 7.03527 12.4676 7.31761C12.4676 7.59996 12.3673 7.83676 12.1666 8.02802C11.966 8.21928 11.7224 8.31492 11.4358 8.31492ZM12.2383 9.31222V15.671H10.6046V9.31222H12.2383Z" fill="#EB3131"/>
                                </svg>
                            </div>
                            <div @click="expandOutput">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 21 20" fill="none">
                                    <path d="M7.52344 2.5V4.16667H4.1901V7.5H2.52344V2.5H7.52344ZM2.52344 17.5V12.5H4.1901V15.8333H7.52344V17.5H2.52344ZM19.1901 17.5H14.1901V15.8333H17.5234V12.5H19.1901V17.5ZM19.1901 7.5H17.5234V4.16667H14.1901V2.5H19.1901V7.5Z" fill="#0D69D5"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="showOutputScreen" class="ml-5"> 
                    <ExpandedOutputScreen :outputData="outputData" :showOutputScreen="showOutputScreen" :updatedFilteredData="updatedFilteredData" @expandOutput="expandOutput" :gdcListedCountries="gdcListedCountries"/> 
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
// import Loader from "@/components/loader";
import ExpandedOutputScreen from "./idOutputModal.vue"
import { FormatDateNew } from "@shared/plugins/functions";

export default {
    title: "Output",
    name: "gdc-check-output",
    components: {
        // Loader,
        ExpandedOutputScreen,
    },
    props: {
        outputData:  {
            type: Array
        },
        updatedFilteredData:  {
            type: Object
        },
        gdcListedCountries: {
            type: Array
        }
    },
    data() {
        return { 
            showFilter: false,
            userData: {
                name: {
                    keyName: 'Name',
                    values: [ 
                        {
                            title: 'Name 1',
                            value: 'A KORHONEN',
                            checked: false,
                        },
                        {
                            title: 'Name 2',
                            value: 'B KORHONEN',
                            checked: false,
                        },
                        {
                            title: 'Name 3',
                            value: 'C KORHONEN',
                            checked: false,
                        },
                    ],
                },
                dob: {
                    keyName: 'Run Date',
                    values: [
                        {
                            value: "01 jan 1995",
                            checked: false,
                        }
                    ],
                },
                address: {
                    keyName: 'Address',
                    values: [
                        {
                            title: 'Address 1',
                            value: 'SOMMAROSUNDVAGEN 89, FINLAND, F1',
                            checked: false,
                        },
                        {
                            title: 'Address 2',
                            value: 'SOMMAROSUNDVAGEN 89, FINLAND, F2',
                            checked: false,
                        },
                        {
                            title: 'Address 3',
                            value: 'SOMMAROSUNDVAGEN 89, FINLAND, F3',
                            checked: false,
                        },
                    ],
                },
                id_status: {
                    keyName: 'ID',
                    values: [
                        {
                            title: 'Adhaar Number',
                            value: '234194850136',
                            checked: false,
                        },
                        {
                            title: 'License Number',
                            value: 'DL234291162023',
                            checked: false,
                        },
                    ]
                }
            },
            cardData: [
                {
                    name: 'A KORHONEN',
                    address: 'SOMMAROSUNDVAGEN 89, FINLAND, F1',
                    dob: '22/01/1992',
                    address_status: 'Medium',
                    id_status: 'Medium',
                    mail_status: 'High',
                    phone_status: 'Low',
                    matched_data_count: '2',
                },
                {
                    name: 'B KORHONEN',
                    address: 'SOMMAROSUNDVAGEN 89, FINLAND, F2',
                    dob: '22/01/1992',
                    address_status: 'Medium',
                    id_status: '-',
                    mail_status: '-',
                    phone_status: 'Low',
                    matched_data_count: '2',
                },
                {
                    name: 'C KORHONEN',
                    address: 'SOMMAROSUNDVAGEN 89, FINLAND, F3',
                    dob: '22/01/1992',
                    address_status: '-',
                    id_status: 'Medium',
                    mail_status: 'High',
                    phone_status: '-',
                    matched_data_count: '2',
                },
                {
                    name: 'A KORHONEN',
                    address: 'SOMMAROSUNDVAGEN 89, FINLAND, F1',
                    dob: '22/01/1992',
                    address_status: '-',
                    id_status: 'Medium',
                    mail_status: 'High',
                    phone_status: '-',
                    matched_data_count: '2',
                },
                {
                    name: 'A KORHONEN',
                    address: 'SOMMAROSUNDVAGEN 89, FINLAND, F1',
                    dob: '22/01/1992',
                    address_status: '-',
                    id_status: 'Medium',
                    mail_status: 'High',
                    phone_status: '-',
                    matched_data_count: '2',
                },
            ],
            filteredData: [],
            showMultipleFields: [],
            currentUpdateData: [],
            usedFilter: {
                name: [],
                dob: [],
                address: [],
                id_status: [],
            },
            showOutputScreen: false,
        };
    },
    mounted() {
        this.filteredData = this.outputData;    
    },
    methods: {
        FormatDateNew,
        toggleFilter() {
            this.showFilter = !this.showFilter
        },
        showPersonalData(index) {
            if(this.showMultipleFields.includes(index)){
                let currentIndex = this.showMultipleFields.indexOf(index)
                if(currentIndex != -1){
                    this.showMultipleFields.splice(currentIndex,1)
                }
            }
            else {
                this.showMultipleFields.push(index);
            }
        },
        showCardStatus(cardData) {
            if (cardData.reliability == '30') return 'lowMatch';
            if (cardData.reliability == '20') return 'mediumMatch';
            if (cardData.reliability == '10') return 'highMatch';
        },
        showReliability(cardDetails) {
            if (cardDetails.reliability == '30') return 'Low';
            if (cardDetails.reliability == '20') return 'Medium';
            if (cardDetails.reliability == '10') return 'High';
        },
        applyFilter(event, filterkey, filterVal, filterIndex) {
            this.userData[filterkey].values[filterIndex].checked = event?.target?.checked
            if (!event?.target?.checked) {
                this.usedFilter[filterkey] = this.usedFilter?.[filterkey]?.filter(el => el?.value != filterVal?.value)
            } else {
                let currFilter = this.userData[filterkey]?.values?.filter(fltEl => fltEl?.checked)
                if (currFilter && currFilter?.length > 0) {
                    currFilter?.map(currflt => {
                        if(!this.usedFilter?.[filterkey]?.some(el => el.title == currflt?.title) && currflt?.checked) {
                         this.usedFilter?.[filterkey]?.push(currflt);
                        }
                    });
                }
            }
            if (this.usedFilter && (this.usedFilter?.[filterkey]?.length > 0 || this.usedFilter?.name?.length > 0 || this.usedFilter?.address?.length > 0 || this.usedFilter?.dob?.length > 0 || this.usedFilter?.id_status?.length > 0)) {
                let currFilteredData = this.cardData;
                const nameValuesToCheck = this.usedFilter?.name?.map(item => item.value);
                const addressValuesToCheck = this.usedFilter?.address?.map(item => item.value);
                const dobValuesToCheck = this.usedFilter?.dob?.map(item => item.value);
                const idValuesToCheck = this.usedFilter?.id_status?.map(item => item.value);

                currFilteredData = currFilteredData.filter(obj =>
                    Object.values(obj).some(val => nameValuesToCheck.includes(val)) ||
                    Object.values(obj).some(val => addressValuesToCheck.includes(val)) ||
                    Object.values(obj).some(val => dobValuesToCheck.includes(val)) ||
                    Object.values(obj).some(val => idValuesToCheck.includes(val))
                );
                this.filteredData = currFilteredData
            } else {
                this.filteredData = this.cardData
            }
        },
        convertData(date) {
            let newDate = new Date(date);
            let convertedDate = this.FormatDateNew(newDate);
            return convertedDate;
        },
        expandOutput(){
            this.showOutputScreen = !this.showOutputScreen;
        },
        getUpdatedAddress(item) {
            return item.addressLine1 || item.addressLine2;
        },
        getUpdatedIdentity(item) {
            return item.completename;
        },
        getUpdatedEmail(item) {
            return item.fullEmailAddress;
        },
        getUpdatedPhone(item) {
            return item.phoneNumber;
        },
        backToRunCheck(){
            this.$emit('backToSearch',true);
        },
    },
};

</script>
<style scoped lang='scss'>
 .filterIcon {
     display: inline-flex;
     padding: 11px;
     align-items: center;
     gap: 10px;
     border-radius: 5px;
     box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.08);
 }
 .rotateDropdownIcon{
    transform: rotate(180deg);
}
.filterDropdown {
    width: 220px;
}
.adddata {
    word-break: break-all;
    margin-right: 20px;
    width: 100px;
    font-weight: 500;
}
.namedata {
    // word-break: break-word;
    font-weight: 500;
}
.reliabilityScoreBlock{
    margin-right: 50px;
}
.relTest {
    width: 100px;
    word-break: break-all;
    margin:0px 15px;
    text-align: center;
}
.mainCardDataSec {
    height: 62vh;
}
.allCards {
    width: 100%;
    height: 150px;
    border-radius: 7px;
    border: 1px solid #D8D8D8;
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
}
.allCardsWithFilter {
    width: 45vw;
}
.allStatusText {
    font-size: 14px;
    margin-top: 10px;
    span {
        color: #0D69D5;
    }
}
.allStatusType {
    font-size: 20px;
}
.matchData {
    border-radius: 4px;
    background: var(--Light-Green, #E1F3D3);
}
.roundedData {
    border-radius: 15px;
    background: var(--Line-Grey, #D7D7D7);
}
.mediumMatch {
    color: #FFB800;
}
.highMatch {
    color: #3E9F3C;
}
.lowMatch {
    color: #F00;
}
.innerFilters {
    font-weight: 400;
    font-size: 13px;
}
.setOutputWidth{
    width: 450px;
    height: 240px;
    min-height: 240px;
    max-height: 260px
}
.adjustLowMatch,.adjustMediumMatch,.adjustHighMatch {
    font-size: 16px;
    font-weight: 500;
}
.adjustLowMatch {
    color: #F00;
}
.adjustMediumMatch {
    color: #FFB800;
}
.adjustHighMatch {
    color: #3E9F3C;
}
.nameAndAddress{
    width: 300px;
}
.statusReadability {
    font-size: 13px;
}
.cardsStatus {
    margin-right: 0;
}
.usersStatus {
    width: 385px;
}
</style>
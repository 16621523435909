<template>
    <Button
        class="plus-icon__wrapper bg-primary flex items-center justify-center w-16 h-full rounded-l-lg rounded-r-none cursor-pointer"
        type="primary"
        :disabled="disabled"
        v-on="$listeners"
    >
        <font-awesome-icon
            class="plus-icon__icon text-3xl text-primary-content"
            :icon="['fa', 'plus']"
        />
    </Button>
</template>

<script>
import Button from "@shared/components/button/index.vue";

export default {
    components: {
        Button,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
</style>
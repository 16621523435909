export default {
  name: "icon-list",
  props: {
    list: {
      type: Array,
      default: [],
    },
    // activeItem: {
    //   type: String,
    //   default: "",
    // },
  },
  methods: {
    click(listItem) {
      // this.activeItem = key;
      this.$emit('click', listItem);
    },
  },
};

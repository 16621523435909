<template>
  <div class="w-full h-full pb-3 pl-3 overflow-y-auto scroll-bar">
    <div class="w-full h-full min-h-full flex items-center justify-center bg-white flex-1" v-if="isHooyuCheckResultsFetching">
		<Loader  height="16px" width="16px" :loading="isHooyuCheckResultsFetching" />
	</div>
    <div class="flex flex-1 gap-6 layout max-h-full overflow-y-auto scroll-bar min-h-full" style="height:136vh;" v-else>
        <template v-if="HooyResultData && hasHooyuRun">
            <div class="flex flex-col gap-6 left-section max-h-full" v-if="HooyResultData.raw && HooyResultData.raw.payload">
                <profileCardHooyu :data="HooyResultData.raw.payload" />
                <button @click="handleOpenHooyuModal" class="border border-primary text-primary py-2 px-3 rounded-full">
                    View Report
                </button>
            </div>
            <div class="max-h-full min-h-full overflow-y-auto right-section pr-2 scroll-bar w-full">
                <div class="flex gap-6">
                    <statusHooyu classList="bg-green-400" :status="HooyResultData.raw.payload.status" />
                    <statusHooyu :classList="HooyResultData.raw.payload.result == 'FAIL' ? 'bg-red-500' : 'bg-green-400'" :status=" 'Result: ' + HooyResultData.raw.payload.result" />
                </div>

                <div class="status_section mt-6" v-if="HooyResultData.raw.payload.scores">
                    <h3 class="text-xl text-gray-600 font-semibold mb-4">Scores</h3>
                    <Scores :scores="HooyResultData.raw.payload.scores" />
                </div>

                <!-- Badges -->
                <h3 class="text-xl text-gray-600 font-semibold mb-2 mt-6">Badges</h3>
                <div class="flex-1 rounded-md shadow p-4 space-y-4 bg-white">
                    <div class="flex items-center">
                        <strong>Right to Work</strong>
                        <span v-if="HooyResultData.raw.payload?.badges.includes('right-to-work')" class="ml-4 bg-green-400 text-white-text h-4 w-4 rounded-full text-xs flex items-center justify-center">
                            <font-awesome-icon icon="check" />
                        </span>
                        <span class="ml-4" v-else>Not available</span>
                    </div>
                    <div class="flex items-center">
                        <strong>Right to Rent</strong>
                        <span v-if="HooyResultData.raw.payload?.badges.includes('right-to-rent')" class="ml-4 bg-green-400 text-white-text h-4 w-4 rounded-full text-xs flex items-center justify-center">
                            <font-awesome-icon icon="check" />
                        </span>
                        <span class="ml-4" v-else>Not available</span>
                    </div>
                    <div class="flex items-center">
                        <strong>DBS Basic</strong>
                        <span v-if="HooyResultData.raw.payload?.badges.includes('dbs-basic')" class="ml-4 bg-green-400 text-white-text h-4 w-4 rounded-full text-xs flex items-center justify-center">
                            <font-awesome-icon icon="check" />
                        </span>
                        <span class="ml-4" v-else>Not available</span>
                    </div>
                    <div class="flex items-center">
                        <strong>Identity Profile</strong>
                        <span v-if="HooyResultData.raw.payload?.idScreenResult?.identityProfile" class="ml-4 px-2 py-1 rounded-md bg-blue-500 text-white-text font-bold">{{ HooyResultData.raw.payload?.idScreenResult?.identityProfile }}</span>
                        <span class="ml-4" v-else>Not available</span>
                    </div>
                </div>

                <!-- IdentityScreenResult -->
                <h3 class="text-xl text-gray-600 font-semibold mb-2 mt-6">ID Screen Results</h3>
                <div class="idScreenResult_section flex-1">
                    <template v-if="HooyResultData.raw.payload.idScreenResult">
                        <div class="flex gap-4">
                            <div class="flex-1 rounded-md shadow p-4 space-y-2 bg-white">
                                <h4 class="text-lg text-gray-500 font-semibold">Score</h4>
                                <div class="border border-solid border-gray-300 rounded-md">
                                    <div class="flex flex-col">
                                        <div class="flex items-center border-b border-gray-300">
                                            <div class="flex-1 font-semibold text-gray-400 border-r border-gray-300 px-4 py-2"> Strength </div>
                                            <div class="justify-center flex flex-1 gap-2 w-6 h-6" v-if="HooyResultData.raw.payload.idScreenResult.results.STRENGTH"> 
                                                <span v-for="(str, si) in HooyResultData.raw.payload.idScreenResult.results.STRENGTH.split(',')" :key="si"
                                                    class="rounded-full px-2 py-0.25 text-base" :class="str === '3' ? 'bg-success' : (str === '2' ? 'bg-warning': 'bg-error')">
                                                    {{ str }}
                                                </span>
                                            </div>
                                            <div v-else class="flex flex-1 justify-center"> N/A </div>
                                        </div>
                                        <div class="flex items-center border-b border-gray-300">
                                            <div class="flex-1 font-semibold text-gray-400 border-r border-gray-300 px-4 py-2"> Validity </div>
                                            <div class="justify-center flex flex-1 gap-2 w-6 h-6" v-if="HooyResultData.raw.payload.idScreenResult.results.VALIDITY"> 
                                                <span v-for="(str, si) in HooyResultData.raw.payload.idScreenResult.results.VALIDITY.split(',')" :key="si"
                                                    class="rounded-full px-2 py-0.25 text-base" :class="str === '3' ? 'bg-success' : (str === '2' ? 'bg-warning': 'bg-error')">
                                                    {{ str }}
                                                </span>
                                            </div>
                                            <div v-else class="flex flex-1 justify-center"> N/A </div>
                                        </div>
                                        <div class="flex items-center border-b border-gray-300">
                                            <div class="flex-1 font-semibold text-gray-400 border-r border-gray-300 px-4 py-2"> Fraud </div>
                                            <div class="justify-center flex flex-1 gap-2 w-6 h-6" v-if="HooyResultData.raw.payload.idScreenResult.results.IDENTITY_FRAUD"> 
                                                <span v-for="(str, si) in HooyResultData.raw.payload.idScreenResult.results.IDENTITY_FRAUD.split(',')" :key="si"
                                                    class="rounded-full px-2 py-0.25 text-base" :class="str === '3' ? 'bg-success' : (str === '2' ? 'bg-warning': 'bg-error')">
                                                    {{ str }}
                                                </span>
                                            </div>
                                            <div v-else class="flex flex-1 justify-center"> N/A </div>
                                        </div>
                                        <div class="flex items-center border-b border-gray-300">
                                            <div class="flex-1 font-semibold text-gray-400 border-r border-gray-300 px-4 py-2"> Activity </div>
                                            <div class="justify-center flex flex-1 gap-2 w-6 h-6" v-if="HooyResultData.raw.payload.idScreenResult.results.ACTIVITY_HISTORY"> 
                                                <span v-for="(str, si) in HooyResultData.raw.payload.idScreenResult.results.ACTIVITY_HISTORY.split(',')" :key="si"
                                                    class="rounded-full px-2 py-0.25 text-base" :class="str === '3' ? 'bg-success' : (str === '2' ? 'bg-warning': 'bg-error')">
                                                    {{ str }}
                                                </span>
                                            </div>
                                            <div v-else class="flex flex-1 justify-center"> N/A </div>
                                        </div>
                                        <div class="flex items-center">
                                            <div class="flex-1 font-semibold text-gray-400 border-r border-gray-300 px-4 py-2"> Verification </div>
                                            <div class="justify-center flex flex-1 gap-2 w-6 h-6" v-if="HooyResultData.raw.payload.idScreenResult.results.VERIFICATION"> 
                                                <span v-for="(str, si) in HooyResultData.raw.payload.idScreenResult.results.VERIFICATION.split(',')" :key="si"
                                                    class="rounded-full px-2 py-0.25 text-base" :class="str === '3' ? 'bg-success' : (str === '2' ? 'bg-warning': 'bg-error')">
                                                    {{ str }}
                                                </span>
                                            </div>
                                            <div v-else class="flex flex-1 justify-center"> N/A </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-1 rounded-md shadow p-4 space-y-2 bg-white">
                                <h4 class="text-lg text-gray-500 font-semibold">Fraud Cautions</h4>
                                <div class="border border-solid border-gray-300 rounded-md">
                                    <div class="flex flex-col">
                                        <div class="flex items-center border-b border-gray-300">
                                            <div class="flex-1 font-semibold text-gray-400 border-r border-gray-300 px-4 py-2"> Fraudulent ID </div>
                                            <div class="justify-center flex flex-1 gap-2 w-6 h-6" v-if="HooyResultData.raw.payload.idScreenResult.results.SIRA_FRAUD"> 
                                                <span class="rounded-full px-2 py-0.25 text-base">
                                                    {{ HooyResultData.raw.payload.idScreenResult.results.SIRA_FRAUD }}
                                                </span>
                                            </div>
                                            <div v-else class="flex flex-1 justify-center"> N/A </div>
                                        </div>
                                        <div class="flex items-center border-b border-gray-300">
                                            <div class="flex-1 font-semibold text-gray-400 border-r border-gray-300 px-4 py-2"> Impersonated ID previously </div>
                                            <div class="justify-center flex flex-1 gap-2 w-6 h-6" v-if="HooyResultData.raw.payload.idScreenResult.results.SIRA_IMPERSONATION"> 
                                                <span class="rounded-full px-2 py-0.25 text-base">
                                                    {{ HooyResultData.raw.payload.idScreenResult.results.SIRA_IMPERSONATION }}
                                                </span>
                                            </div>
                                            <div v-else class="flex flex-1 justify-center"> N/A </div>
                                        </div>
                                        <div class="flex items-center border-b border-gray-300">
                                            <div class="flex-1 font-semibold text-gray-400 border-r border-gray-300 px-4 py-2"> Registered as deceased </div>
                                            <div class="justify-center flex flex-1 gap-2 w-6 h-6" v-if="HooyResultData.raw.payload.idScreenResult.results.DDRI"> 
                                                <span class="rounded-full px-2 py-0.25 text-base">
                                                    {{ HooyResultData.raw.payload.idScreenResult.results.DDRI }}
                                                </span>
                                            </div>
                                            <div v-else class="flex flex-1 justify-center"> N/A </div>
                                        </div>
                                        <div class="flex items-center border-b border-gray-300">
                                            <div class="flex-1 font-semibold text-gray-400 border-r border-gray-300 px-4 py-2"> Lost/stolen documents </div>
                                            <div class="justify-center flex flex-1 gap-2 w-6 h-6" v-if="HooyResultData.raw.payload.idScreenResult.results.AMBERHILL"> 
                                                <span class="rounded-full px-2 py-0.25 text-base">
                                                    {{ HooyResultData.raw.payload.idScreenResult.results.AMBERHILL }}
                                                </span>
                                            </div>
                                            <div v-else class="flex flex-1 justify-center"> N/A </div>
                                        </div>
                                        <div class="flex items-center">
                                            <div class="flex-1 font-semibold text-gray-400 border-r border-gray-300 px-4 py-2"> Information found </div>
                                            <div class="justify-center flex flex-1 gap-2 w-6 h-6" v-if="HooyResultData.raw.payload.idScreenResult.results.INFORMATION_FOUND"> 
                                                <span class="rounded-full px-2 py-0.25 text-base">
                                                    {{ HooyResultData.raw.payload.idScreenResult.results.INFORMATION_FOUND }}
                                                </span>
                                            </div>
                                            <div v-else class="flex flex-1 justify-center"> N/A </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </template>
                    <template v-else>
                        Not Available
                    </template>
                </div>
                
                <div class="status_section mt-6">
                    <h3 class="text-xl text-gray-600 font-semibold mb-4">Data</h3>
                    <div class="flex flex-col space-y-4">
                        <div class="flex-1 rounded-md shadow p-4 space-y-2 bg-white">
                            <div class="flex items-center">
                                <strong>Primary Phone:</strong>
                                {{ HooyResultData.raw.payload?.primaryPhone }}
                                <span v-if="HooyResultData.raw.payload?.primaryPhoneVerified" class="ml-4 bg-green-400 text-white-text h-4 w-4 rounded-full text-xs flex items-center justify-center">
                                    <font-awesome-icon icon="check" />
                                </span>
                            </div>
                            <div class="flex items-center">
                                <strong>Known Phones:</strong>
                                {{ HooyResultData.raw.payload?.knownPhones }}
                            </div>
                            <div class="flex items-center mt-4">
                                <strong>Primary Email:</strong>
                                {{ HooyResultData.raw.payload?.primaryEmail }}
                                <span v-if="HooyResultData.raw.payload?.primaryEmailVerified" class="ml-4 bg-green-400 text-white-text h-4 w-4 rounded-full text-xs flex items-center justify-center">
                                    <font-awesome-icon icon="check" />
                                </span>
                            </div>
                            <div class="flex items-center">
                                <strong>Known Emails:</strong>
                                {{ HooyResultData.raw.payload?.knownEmails }}
                            </div>
                            <div class="flex items-center">
                                <strong>Known Name Aliases:</strong>
                                {{ HooyResultData.raw.payload?.knownNameAliases.join(", ") }}
                            </div>
                            <div class="flex items-center mt-4">
                                <strong>Primary Location:</strong>
                                {{ HooyResultData.raw.payload?.primaryLocation }}
                                <span v-if="HooyResultData.raw.payload?.primaryLocationVerified" class="ml-4 bg-green-400 text-white-text h-4 w-4 rounded-full text-xs flex items-center justify-center">
                                    <font-awesome-icon icon="check" />
                                </span>
                            </div>
                            <div class="flex items-center">
                                <strong>Known Locations:</strong>
                                {{ HooyResultData.raw.payload?.knownLocations?.join(", ") }}
                            </div>
                            <div class="flex items-center mt-4">
                                <strong>Nationality:</strong>
                                {{ HooyResultData.raw.payload?.nationality }}
                                <span v-if="HooyResultData.raw.payload?.nationalityVerified" class="ml-4 bg-green-400 text-white-text h-4 w-4 rounded-full text-xs flex items-center justify-center">
                                    <font-awesome-icon icon="check" />
                                </span>                                
                            </div>
                            <div class="mt-4">
                                <strong>Addresses:</strong>
                                <template v-for="address, index in HooyResultData.raw.payload?.addresses ?? {}">
                                    <div :key="index">{{ address }}</div>
                                </template>
                            </div>
                        </div>
                        
                    </div>
                    <h3 class="text-xl text-gray-600 font-semibold mb-2 mt-6">Documents</h3>
                    <div class="flex-1 rounded-md shadow p-4 space-y-4 bg-white">
                        <div v-if="HooyResultData.raw.payload?.documents?.Passport">
                            <strong class="mb-1">Passport</strong>
                            <div>
                                Name: {{ HooyResultData.raw.payload?.documents?.Passport?.firstname }} {{ HooyResultData.raw.payload?.documents?.Passport?.surname }}
                            </div>
                            <div>
                                DOB: {{ HooyResultData.raw.payload?.documents?.Passport?.dateOfBirth }}
                            </div>
                            <div>
                                Gender: {{ HooyResultData.raw.payload?.documents?.Passport?.mrz?.gender }}
                            </div>
                            <div>
                                Date of Expiry: {{ HooyResultData.raw.payload?.documents?.Passport?.dateOfExpiry }}
                            </div>
                            <div>
                                Document Number: {{ HooyResultData.raw.payload?.documents?.Passport?.mrz?.documentNumber }}
                            </div>
                            <div>
                                Place of Issue: {{ HooyResultData.raw.payload?.documents?.Passport?.mrz?.issuingStateOrOrganization }}
                            </div>
                            <div>
                                MRZ Lines:
                                <template v-for="line, index in HooyResultData.raw.payload?.documents?.Passport?.mrz?.mrzLines">
                                    <div :key="index">
                                        {{ line }}
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div v-if="HooyResultData.raw.payload?.documents?.DrivingLicence">
                            <strong class="mb-1">Driving Licence</strong>
                            <div>
                                Name: {{ HooyResultData.raw.payload?.documents?.DrivingLicence?.firstname }} {{ HooyResultData.raw.payload?.documents?.DrivingLicence?.surname }}
                            </div>
                            <div>
                                DOB: {{ HooyResultData.raw.payload?.documents?.DrivingLicence?.dateOfBirth }}
                            </div>
                            <div>
                                Date of Expiry: {{ HooyResultData.raw.payload?.documents?.DrivingLicence?.dateOfExpiry }}
                            </div>                            
                            <div>
                                Address: {{ HooyResultData.raw.payload?.documents?.DrivingLicence?.address?.address1 }} {{ HooyResultData.raw.payload?.documents?.DrivingLicence?.address?.address2 }} {{ HooyResultData.raw.payload?.documents?.DrivingLicence?.address?.town }} {{ HooyResultData.raw.payload?.documents?.DrivingLicence?.address?.country }} {{ HooyResultData.raw.payload?.documents?.DrivingLicence?.address?.postcode }}
                            </div>                            
                        </div>
                        <div v-if="HooyResultData.raw.payload?.documents?.ProofAddress">
                            <strong class="mb-1">Proof of Address</strong>
                            <div>
                                Name: {{ HooyResultData.raw.payload?.documents?.ProofAddress?.firstname }} {{ HooyResultData.raw.payload?.documents?.ProofAddress?.surname }}
                            </div>                                                  
                            <div>
                                Address: {{ HooyResultData.raw.payload?.documents?.ProofAddress?.address?.address1 }} {{ HooyResultData.raw.payload?.documents?.ProofAddress?.address?.address2 }} {{ HooyResultData.raw.payload?.documents?.ProofAddress?.address?.town }} {{ HooyResultData.raw.payload?.documents?.ProofAddress?.address?.country }} {{ HooyResultData.raw.payload?.documents?.ProofAddress?.address?.postcode }}
                            </div>                            
                        </div>
                    </div>
                </div>

                <!-- Documents Section -->
                <div class="document_section mt-6 ">
                    <h3 class="text-xl text-gray-600 font-semibold mb-4">Proof Identification</h3>
                    <div class="grid grid-cols-2 gap-6">
                        <div v-for="document in HooyResultData.docs.images" :key="document.doc_type">
                            <DocumentView :document="document" />
                        </div>
                    </div>
                </div>  
            
            </div>
        </template>
        <div v-else class="flex justify-center items-center min-h-full flex-1">
            Hooyu journey has not been completed yet.
        </div>
                
    </div>

    <modal name="hooyu-result" width="65%" height="90%">
		<div class="header bg-primary py-4 px-8 text-white-text text-lg font-bold flex items-center justify-between  ">
			<span>Report</span>
            <font-awesome-icon icon="times" class="cursor-pointer " @click="$modal.hide('hooyu-result')" />
		</div>
		<div class="w-full h-full flex items-center justify-center bg-white flex-1" v-if="isHooyuCheckResultsFetching">
			<Loader  height="16px" width="16px" :loading="isHooyuCheckResultsFetching" />
		</div>
		<div class="w-full min-h-full bg-white flex-1 justify-center flex " v-else>
            <PdfView :urlLink="HooyResultData.docs.pdf" v-if="HooyResultData && HooyResultData.docs && HooyResultData.docs.pdf" class="flex-1" />
			<Empty text="Report not found" v-else />
			
		</div>
	</modal>
    
    <!-- Pdf View Section -->

    <!-- <div class="flex min-h-full">
      <div
        class="w-full min-h-full flex items-center justify-center bg-white"
        v-if="isHooyuCheckResultsFetching"
      >
        <Loader size="10" />
      </div>
      <div v-else class="flex min-h-full min-w-full">
        <PdfView v-if="HooyuCheckResults" :urlLink="HooyuCheckResults" />
        <div v-else class="flex min-h-full min-w-full justify-center"> <Empty :showIcon="true" text="Hooyu Result not found " /> </div>
      </div>
    </div> -->

    <!-- End PDF View Section -->

  </div>
</template>


<script>
import profileCardHooyu from "./hooyu-components/profile-card.vue"
import statusHooyu from "./hooyu-components/hooyu-status.vue"
// import infoCardHooyu from "./hooyu-components/info-card.vue"
import DocumentView from "./hooyu-components/documentView.vue"
import PdfView from "../pdf-view/index.vue";
import Loader from "../../loader/";
import axios from "@/axios";
import Empty from "../../empty"
// import RadialProgress from "../radial-progress"
import Scores from "./hooyu-components/scores.vue"

export default {
  name: "HooyuCheckResultsViewer",
  components: {
    profileCardHooyu,
    statusHooyu,
    // infoCardHooyu,
    DocumentView,
    PdfView,
    Loader,
    Empty,
    // RadialProgress,
    Scores
  },
  props: ["caseId"],
  data() {
    return {
      hooyuData: {
        hooyu_geolocations: "data.geoLocations",
        hooyu_primary_phone: "data.primaryPhone",
        hooyu_primary_phone_verified: "data.primaryPhoneVerified",
        hooyu_primary_email: "data.primaryEmail",
        hooyu_primary_email_verified: "data.primaryEmailVerified",
        hooyu_name: "data.name",
        hooyu_first_name: "data.firstname",
      },
      isHooyuCheckResultsFetching: false,
      HooyResultData: null,
      hasHooyuRun: true,
    };
  },
  async created() {
    if (this.caseId) {
      this.getHooyuCheckResults();
    }
  },
  methods: {
    // async fetchHooyuData(){
    //     const case_id = "134"
    //     const url = `http://localhost:8000/integrations/hooyu/{case_id}`

    //     try{
    //         await axios.get(url)
    //     }catch(error){
    //         console.log(error)
    //     }

    // },
    async getHooyuCheckResults() {
      this.isHooyuCheckResultsFetching = true;
      try {
        let url = `/integrations/hooyu/${this.caseId}`;
        let { data } = await axios.get(url);
        // to do : remove this dummy link
        if (data === null || Object.keys(data).length === 0)
            this.hasHooyuRun = false;
            this.$emit('getHooyuData', this.hasHooyuRun)
        this.HooyResultData = data;
        this.$emit('getHooyuData', this.hasHooyuRun)
      } catch (error) {
        this.$toast.error(
          error.response.data.detail ||
            "Error While fetching Hooyu Check results"
        );
        console.log("error :>> ", error);
      }
      this.isHooyuCheckResultsFetching = false;
    },
    handleOpenHooyuModal(){
        this.$modal.show('hooyu-result')
    }
  },
};
</script>

<style scoped>
</style>


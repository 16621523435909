<template>
    <div>
        <div
            v-if="loading"
            class="p-10 flex items-center justify-center w-full"
        >
            <Loader />
        </div>

        <entity-tool-bar
            v-else
            :items="computedEntitiesList"
            :plus-disabled="plusDisabled"
            @select="handleEntityClick($event)"
            @add="$emit('add')"
        />
    </div>
</template>

<script>
import EntityToolBar from "../components/EntityToolBar";
import Loader from "@/components/loader";

export default {
    name: "EntityToolBarContainer",

    components: {
        EntityToolBar,
        Loader,
    },

    props: {
        caseEntitiesData: {
            type: Object,
            required: true,
        },
        // used to work with temporary data tah isn't present in original caseEntitiesData
        items: {
            type: Array,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        // enables "add" ("plus") button:
        plusDisabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        caseId() {
            return this.$route.query.caseid;
        },
        entityId() {
            return this.$route.query.entityId;
        },
        computedEntitiesList() {
            const { case_entities, case_entity_type_info } = this.caseEntitiesData;

            const list = this.items ?? case_entities;

            return list?.map(entity => ({
                id: entity.id,
                data: entity,
                collect_data: entity.collect_data,
                name: entity.entity_name,
                type: case_entity_type_info?.[entity.entity_type_id]?.name,
                selectable: true,
                readonly: true,
                disabled: false,
                selected: entity.id === this.entityId,
                checked: false,
            }));
        },
        caseEntity() {
            return this.computedEntitiesList?.find(item => item.id === this.entityId);
        },
    },

    watch: {
        caseEntitiesData: {
            handler(val) {
                if (!this.$route.query.entityId) {
                    return this.redirectToEntity(val?.case_entities?.[0]?.id);
                }

                this.setCaseEntities();
            },
            deep: true,
        },
    },

    methods: {
        setCaseEntities() {
            if (this.entityId !== this.caseEntity?.id) {
                this.redirectToEntity(this.caseEntity?.id);
            }
        },

        redirectToEntity(entityId) {
            const query = {
              ...this.$route.query,
              entityId,
            }

            this.$router.push({
              query,
            });
        },

        async handleEntityClick(item) {
            // if (this.entityId === item.id) return;

            // emit original entity data
            this.$emit("onEntityTabClick", item?.data);
        },
    },
}
</script>

export class EntityForm {
    constructor(caseFormData = null) {
        this.formData = caseFormData;
        this.isSubmitted = false;
    }
    submit(payload = true) {
        this.isSubmitted = payload;
    }
    populateForm(payload = null) {
        this.formData = payload;
    }
}
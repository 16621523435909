<template>
<label
    class="checkbox-side"
    :class="{'checkbox-side--checked': modelValue, 'pointer-events-none': disabled}"
>
    <checkboxIcon class="icon" />
    <input
        class="hidden"
        type="checkbox"
        v-model="modelValue"
        @change="select"
        :disabled="disabled"
    >
</label>
</template>

<script>
import checkboxIcon from "@/assets/icons/sc-journey/checkbox.svg";

export default {
    name: 'CheckboxSide',
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        checkboxIcon,
    },
    data() {
        return {
            modelValue: false,
        }
    },
    computed: {
        // modelValue: {
        //     get() {
        //         return this.value;
        //     },
        //     set(value) {
        //         this.$emit('change', value);
        //     }
        // }
    },
    created() {
        this.modelValue = this.value;
    },
    methods: {
        select(e) {
            if (this.disabled) return
            this.$emit('change', e);
            
        }
    },
}
</script>

<style lang="scss" scoped>
.checkbox-side {
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #C6C6C6;
    .icon {
        width: 100%;
        height: 100%;
        fill: #ADA9A9;
    }
    &--checked {
        @apply bg-primary;
        .icon {
            fill: #fff;
        }
    }
}
</style>
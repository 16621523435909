<template>
    <div class="flex flex-col flex-1 w-full min-h-full overflow-y-auto scrollable">
        <iframe :src="urlLink" frameborder="0" class="min-h-full w-full" :class="classList" v-if="urlLink"></iframe>
    </div>
</template>

<script>
export default {
    name:"pdf-view",
    components:{},
    props:['urlLink', 'classList'],
    data(){
        return {
            
        }
    },
}
</script>

<style>

</style>
<template>
    <div class="w-full flex flex-col items-stretch gap-5">
        <UIContentBox
            v-if="addOptions.length"
            class="flex-1 flex items-center justify-center gap-5"
        >
            <div  v-for="item in addOptions" :key="item.name">
                <Button
                    :loader="item.loading"
                    type="primary"
                    class="capitalize"
                    @click="$emit('add', item)"
                    :text="`Add ${item.name}`"
                    :disabled="item.disabled"
                />
            </div>
        </UIContentBox>
        <UIContentBox
            v-if="allowNext || allowSubmit"
            class="flex items-center justify-center gap-5"
        >
            <Button
                v-if="allowNext"
                class="bg-success text-white"
                @click="$emit('next')"
                text="Next"
            />
            <Button
                v-else-if="allowSubmit"
                class="bg-success text-white"
                @click="$emit('submit')"
                text="Submit"
            />
        </UIContentBox>
    </div>
</template>

<script>
import Button from "@shared/components/button";

export default {
    components: {
        Button,
    },
    props: {
        allowNext: {
            type: Boolean,
            default: false,
        },
        allowSubmit: {
            type: Boolean,
            default: false,
        },
        /**
         * @typedef {Object} Option
         * @property {string} name
         * @property {boolean} loading
         */
        /**
         * @type {Option[]}
         */
        addOptions: {
            type: Array,
            default: () => [],
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
<template>
  <modalContent
    name="preview_form_modal" 
    ref="preview_form_modal"
    title="Form Preview"
    width="70%"
    darkContent
    close
  >
    <template #content>
      <!-- <Loader v-if="isCloning" loadingText="Cloning Field..." /> -->
      <FormBuilder
        :previewForm="true"
      />
    </template>
    <template #footer>
      <footer class="flex items-center w-full justify-between pt-4 border-t border-0 border-solid border-gray-200">
        <button
          @click="submitModal"
          class="h-9  cursor-pointerborder-none rounded-xl text-base text-white-text flex justify-center items-center font-semibold ml-auto w-32"
          :class="[isSubmitDisabled ? 'bg-gray-500 cursor-not-allowed pointer-events-none' :'bg-primary cursor-pointer hover:bg-primary-600']"
          :disabled="isSubmitDisabled"
        >
          Submit
        </button>
      </footer>
    </template>
  </modalContent>
</template>

<script>
// const loader = () => import("@shared/loader");
const modalContent = () => import("@shared/modal-content");
const FormBuilder = () => import("./dynamicFormBuilder.vue");

export default {
  components: {
    // loader,
    modalContent,
    FormBuilder,
  },
  props: {
    isSubmitDisabled: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    showModal() {
      this.$refs.preview_form_modal.showModal('preview_form_modal');
    },
    hideModal() {
      this.$refs.preview_form_modal.hideModal('preview_form_modal');
    },
    submitModal() {
      this.$emit('submit');
      this.hideModal();
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
export class DataCollection {
    constructor(formData) {
        this.data = formData;
        this.updated = false;
    }
    flag(payload = true) {
        this.updated = payload;
    }
    update(payload = {}) {
        Object.assign(this.data, payload);
        this.flag();
    }
}

<template>
  <div class="main-wrapper inline-block rounded-2xl p-5">
    <div v-if="systemIntegratedCheckName?.check_category=='Neotas Integrated Check'" class="main-container flex items-center gap-4">
      <div class="companyLogo main-image rounded-full flex-shrink-0 flex items-center text-3xl text-base-content-600 justify-center bg-white">
        <svg width="45" height="45" viewBox="0 0 38 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M34 26.6667H37.3334V30H0.666687V26.6667H4.00002V1.66667C4.00002 1.22464 4.17562 0.800716 4.48818 0.488155C4.80074 0.175595 5.22466 0 5.66669 0H22.3334C22.7754 0 23.1993 0.175595 23.5119 0.488155C23.8244 0.800716 24 1.22464 24 1.66667V26.6667H30.6667V13.3333H27.3334V10H32.3334C32.7754 10 33.1993 10.1756 33.5119 10.4882C33.8244 10.8007 34 11.2246 34 11.6667V26.6667ZM7.33335 3.33333V26.6667H20.6667V3.33333H7.33335ZM10.6667 13.3333H17.3334V16.6667H10.6667V13.3333ZM10.6667 6.66667H17.3334V10H10.6667V6.66667Z" fill="#0D69D5"/>
        </svg>
      </div>
      <div class="main-details-container flex flex-col gap-3 overflow-hidden flex-1 max-w-full text-base-content">
        <div :title="company_name=='null' ? '' : company_name" class="main-title font-semibold text-lg capitalize truncate">
          {{ company_name ? company_name : '---' }}
        </div>
        <div class="main-details-group gap-3 flex flex-col">
          <div class="flex-1 flex gap-3">
            <svg width="13" height="16" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.254 11.25H9.78528V0.5625C9.78528 0.251836 9.53344 0 9.22278 0H1.34778C1.03711 0 0.785278 0.251836 0.785278 0.5625V11.25H0.316528C0.161208 11.25 0.0352783 11.3759 0.0352783 11.5312V12H10.5353V11.5312C10.5353 11.3759 10.4093 11.25 10.254 11.25ZM3.03528 1.78125C3.03528 1.62593 3.16121 1.5 3.31653 1.5H4.25403C4.40935 1.5 4.53528 1.62593 4.53528 1.78125V2.71875C4.53528 2.87407 4.40935 3 4.25403 3H3.31653C3.16121 3 3.03528 2.87407 3.03528 2.71875V1.78125ZM3.03528 4.03125C3.03528 3.87593 3.16121 3.75 3.31653 3.75H4.25403C4.40935 3.75 4.53528 3.87593 4.53528 4.03125V4.96875C4.53528 5.12407 4.40935 5.25 4.25403 5.25H3.31653C3.16121 5.25 3.03528 5.12407 3.03528 4.96875V4.03125ZM4.25403 7.5H3.31653C3.16121 7.5 3.03528 7.37407 3.03528 7.21875V6.28125C3.03528 6.12593 3.16121 6 3.31653 6H4.25403C4.40935 6 4.53528 6.12593 4.53528 6.28125V7.21875C4.53528 7.37407 4.40935 7.5 4.25403 7.5ZM6.03528 11.25H4.53528V9.28125C4.53528 9.12593 4.66121 9 4.81653 9H5.75403C5.90935 9 6.03528 9.12593 6.03528 9.28125V11.25ZM7.53528 7.21875C7.53528 7.37407 7.40935 7.5 7.25403 7.5H6.31653C6.16121 7.5 6.03528 7.37407 6.03528 7.21875V6.28125C6.03528 6.12593 6.16121 6 6.31653 6H7.25403C7.40935 6 7.53528 6.12593 7.53528 6.28125V7.21875ZM7.53528 4.96875C7.53528 5.12407 7.40935 5.25 7.25403 5.25H6.31653C6.16121 5.25 6.03528 5.12407 6.03528 4.96875V4.03125C6.03528 3.87593 6.16121 3.75 6.31653 3.75H7.25403C7.40935 3.75 7.53528 3.87593 7.53528 4.03125V4.96875ZM7.53528 2.71875C7.53528 2.87407 7.40935 3 7.25403 3H6.31653C6.16121 3 6.03528 2.87407 6.03528 2.71875V1.78125C6.03528 1.62593 6.16121 1.5 6.31653 1.5H7.25403C7.40935 1.5 7.53528 1.62593 7.53528 1.78125V2.71875Z" fill="#0D69D5"/>
            </svg>
            <span class="text-sm truncate">{{ tel != "---" && tel != 'null' && company_number=='null' ? tel : company_number == 'null' ? '---': company_number}}</span>
          </div>
          <div class="flex flex-1 gap-3">
            <svg width="13" height="15" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9539 0.0824668L1.20902 4.58013C0.0847282 5.10489 0.459494 6.75396 1.65879 6.75396H5.78168V10.8768C5.78168 12.0761 7.43074 12.4511 7.9555 11.3266L12.4532 1.58176C12.8279 0.681998 11.8534 -0.292533 10.9539 0.0824668V0.0824668Z" fill="#0D69D5"/>
            </svg>
            <span class="text-sm">{{ country == 'null' ? '---' : country}}</span>
          </div>
          <div class="flex-1 flex gap-3 ">
            <MailIcon class="w-4 text-primary fill-current" />
            <span :title="email=='null'? '' : email" class="text-sm flex-1 truncate">{{ email == 'null' ? '---' : email }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="main-container flex items-center gap-4">
      <div class="main-image rounded-full flex-shrink-0  w-16 h-16 flex items-center text-3xl text-base-content-600 justify-center" :class="getRandomColors()">
        <!-- <img
        src=""
        alt=""
        class="profile-image rounded-full w-28 h-28"
      /> -->
        {{nameToShort(name)}} 
      </div>
      <div class="main-details-container flex flex-col gap-3 overflow-hidden flex-1 max-w-full text-base-content">
        <div :title="name=='null' || name=='null null' ? '' : name" class="main-title font-semibold text-lg capitalize truncate">
          {{ name == 'null' || name == 'null null' ? '--' : name }}
        </div>
        <div class="main-details-group gap-2 flex flex-col">
          <div class="flex flex-1 gap-3">
            <CakeIcon class="w-4 text-primary fill-current" />
            <span class="text-sm">DD/MM/YY</span>
          </div>
          <div class="flex-1 flex gap-3 ">
            <MailIcon class="w-4 text-primary fill-current" />
            <span :title="email=='null'? '' : email"  class="text-sm flex-1 truncate">{{ email == 'null' ? '--' : email }}</span>
          </div>
          <div class="flex-1 flex gap-3">
            <TelIcon class="w-4 text-primary fill-current" />
            <span class="text-sm">{{ tel  == 'null' ? '--' : tel }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CakeIcon from "@/assets/icons/svg/birthday.svg";
import MailIcon from "@/assets/icons/svg/email.svg";
import TelIcon from "@/assets/icons/svg/tel.svg";
import axios from "@/axios";
import {getRandomColors, nameToShort} from "@/plugins/functions"
export default {
  name: "ProfileCard",
  props: {
    isSystemIntegratedCheck: {
      type: Boolean
    },
    systemIntegratedCheckName: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      id: "",
      name: "---",
      email: "---",
      tel: "---",
      company_name: "---",
      country: "---",
      company_number: "---"
    };
  },
  components: {
    CakeIcon,
    MailIcon,
    TelIcon,
  },
  async mounted() {
    if (this.$route.query["caseid"]) {
      this.id = this.$route.query["caseid"];
    }
    // this.id = "224f67c8-e6e1-4542-a91a-9aba7b91bca6";
    await this.getCaseDetails();
  },
  methods: {
    nameToShort,
    getRandomColors,
    async getCaseDetails() {
      try {
        const url = `/case/candidate/${this.id}`;
        let data = await axios.get(url);
        if(data.data.data.neo_case_id){
          this.$emit("getNeoCaseID", data.data.data.neo_case_id);
        }
        console.log("data --->",data.data.data.case_id)
        const { first_name, last_name, email, phone, company_name, country, company_number } = data.data.data;
        this.name = `${first_name} ${last_name}`;
        this.company_name = `${company_name}`;
        this.email = `${email}`;
        this.tel = `${phone}`;
        this.company_number = `${company_number}`
        this.country = `${country}`;
        this.$router.push(`/case/${data.data.data.case_id}/profile-view?caseid=${this.id}&clientId=${data.data.data.client_id}`).catch(err => {
          // Ignore the vuex err regarding  navigating to the page they are already on.
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            // But print any other errors to the console
            console.error(err);
          }
        });
        this.$emit("getCaseDetails", data?.data?.data)
      } catch (error) {
        console.log('error :>> ', error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main-wrapper {
  background-color: #f2f2f2;
}

.companyLogo{
  width: 110px;
  height: 110px;
}
</style>

import mainAxios from "@shared/light-ray/axios";
import { mapGetters, mapActions } from "vuex";
import { cloneDeep } from "lodash";
export default {
  computed: {
    ...mapGetters([
      "getToolsData",
      "getAnalysisToolData",
      "getNeoCaseId",
      "searchedQueries",
      "getNeoCaseId"
    ]),
    fetchSearchedQueries() {
      let queries = cloneDeep(this.searchedQueries).map((query) => ({
        ...query,
        total_results:this.queriesResultsCount.find((mq) => mq?.query_id === query._id)?.result_count || 0
      })); // merge searchedQueries and monitoring queries with same id
      queries = queries.map((query) => {
        if (
          query.tool === "lexis-bridger-tool" &&
          query.query.entity_type === "Individual"
        ) {
          let list = [];
          list.push(query.query.person_entity.first_name);
          list.push(query.query.person_entity.middle_name);
          list.push(query.query.person_entity.last_name);
          query.query.person_entity.full_name = list
            .filter((el) => el)
            .join(" ");
          return query;
        } else return query;
      });
      return queries?.filter((n) => n.tool == this.$route.query.check_name);
    },
  },
  methods: {
    ...mapActions([
      "getAnalysisDropdownData",
      "fetchCasePersonalData",
      "getToolsStatus",
    ]),
    getIntegratedToolName(checkName) {
      const check = checkName.toLowerCase()
      let toolName = ''
      if (check.includes('corporate records')) {
        toolName = 'company-name'
      }
      else if (check.includes('lexis bridger')) {
        toolName = 'lexis-bridger-tool' 
      }
      else if(check.includes('search engine')){
        toolName = 'search-engine-tool'
      }
      return toolName
    },
    async getQueriesResultsCount(toolName) {
        let url = `/service/query/results-count`;
            let data = {
                case_id: this.getNeoCaseId,
                tool: toolName,
            };
            try {
                // let response = await axios.post(url, data);
                let response = await mainAxios.post(url, data);
                if (response?.status === 200 && response?.data?.data) {
                    this.queriesResultsCount = response?.data?.data;
                }
            } catch (error) {
                console.error(error);
            }
    },
    async handleRefreshQueries() {
      const { search_queries } = this.$refs;
      await search_queries.handleRefreshQueries();
    },
    async getToolsSavedData() {
      await this.getAnalysisDropdownData({
        url: `service/${this.getNeoCaseId}/tools/profiles`,
      });
      this.toolData = this.getAnalysisToolData;
    },
    async handleSearchedQueries() {
      try {
        let body = { case_id: this.getNeoCaseId };
        const { data: toolsStatus } = await mainAxios.post(
          `service/tools-status`,
          body
        );
        return toolsStatus.data;
      } catch (error) {
        console.error(error);
      }
    },
    async handleSearchQueryClicked(query) {
      this.checkSearched = false;
      const { tools } = this.$refs;
      let query_id = "";
      let sel_query = null;
      if (query._id) {
        query_id = query._id;
        sel_query = this.fetchSearchedQueries?.find((el) => el._id === query_id)
          ?.query;
      }
      tools.request_id = query_id;
      switch (this.currentCheckName) {
        case "company-name": {
          const sortedQuery = Object.keys(sel_query)
            .sort((a, b) => a.localeCompare(b))
            .reduce((acc, key) => {
              acc[key] = sel_query[key];
              return acc;
            }, {});
          let keys = Object.keys(sortedQuery);

          if (keys.includes("address_locality")) {
            keys.shift();
          }
          if (keys.includes("address_region")) {
            keys.shift();
          }
          if (keys.includes("code")) {
            keys.shift();
          }
          if (keys.includes("duns")) {
            keys.shift();
          }
          if (keys.includes("email")) {
            keys.shift();
          }
          if (keys.includes("mode")) {
            keys.shift();
          }
          tools.showSearch = true;
          tools.showResult = false;
          tools.request_id = query_id;
          switch (keys[0]) {
            case "company": {
              tools.entityCount = 1;
              tools.queries = [];
              tools.officer = "";
              tools.address = "";
              tools.searchKeyword = sel_query?.company ?? "";
              tools.selectVal = sel_query?.company ? [sel_query?.company] : [];
              tools.address = sel_query?.street_address_line1 || "";
              tools.postCode = sel_query?.postal_code || "";
              tools.locality = sel_query?.address_locality || "";
              tools.region = sel_query?.address_region || "";
              tools.registrationNumber = sel_query?.registration_number || "";
              tools.phone = sel_query?.telephone_number || "";
              tools.duns = sel_query?.duns || "";
              tools.email = sel_query?.email || "";
              let jurisdictionComp = null;
              if (sel_query.code) {
                jurisdictionComp = tools.jurisdictionList[0].categories.find(
                  (n) => n.value === sel_query.code
                );
              }
              tools.jurisdiction = jurisdictionComp ?? null;
              if (sel_query.mode) {
                let search_mode_find = tools.searchMode?.props?.options?.find(
                  (ele) => ele.value === sel_query.mode
                );
                tools.searchMode.value = search_mode_find;
              }
              tools.selectedItem = {
                value: sel_query?.company ?? "",
              };
              tools.fieldType = {
                text: "Company",
                value: "company",
              };
              if (sel_query.sources) {
                tools.openCorporatesCheck = sel_query.sources.includes(
                  "openCorporates"
                );
                tools.clarifiedByCheck = sel_query.sources.includes(
                  "clarifiedBy"
                );
                tools.companyWatchCheck = sel_query.sources.includes(
                  "companyWatch"
                );
                tools.bvdCheck = sel_query.sources.includes("bvd");
                tools.dnbCheck = sel_query.sources.includes("db");
              }
              break;
            }
            case "name": {
              tools.entityCount = 1;
              tools.queries = [];
              tools.officer = "";
              tools.address = "";
              tools.jurisdiction = "";
              tools.searchKeyword = sel_query?.name ?? "";
              tools.selectVal = sel_query?.name ? [sel_query?.name] : [""];
              let jurisdictionName = null;
              let dbJurisdictionName = null;
              if (
                sel_query.code &&
                sel_query.sources.includes("opencorporates")
              ) {
                jurisdictionName = tools.jurisdictionList[0].categories.find(
                  (n) => n.value === sel_query.code
                );
              }
              tools.jurisdiction = jurisdictionName ?? null;
              if (sel_query.code && sel_query.sources.includes("db")) {
                dbJurisdictionName = tools.dbJurisdictionList[0].categories.find(
                  (n) => n.country_code.toLowerCase() === sel_query.code
                );
              }
              tools.dbJurisdiction = dbJurisdictionName ?? null;
              tools.dbaddress = sel_query?.street_address_line1 ?? "";
              tools.postCode = sel_query?.postal_code ?? "";
              tools.locality = sel_query?.address_locality ?? "";
              tools.region = sel_query?.address_region ?? "";
              tools.registrationNumber = sel_query?.registration_number ?? "";
              tools.phone = sel_query?.telephone_number ?? "";
              tools.duns = sel_query?.duns ?? "";
              tools.email = sel_query?.email ?? "";
              let search_mode_find = null;
              if (
                sel_query?.street_address_line1 ||
                sel_query?.postal_code ||
                sel_query?.address_locality ||
                sel_query?.address_region ||
                sel_query?.registration_number ||
                sel_query?.telephone_number ||
                sel_query?.duns ||
                sel_query?.email
              ) {
                tools.isAdvancedSearch = true;
              }
              if (sel_query.mode) {
                search_mode_find = tools.searchMode?.props?.options?.find(
                  (ele) => ele.value === sel_query.mode
                );
                tools.searchMode.value = search_mode_find;
              }
              tools.selectedItem = {
                value: sel_query?.name ?? "",
              };
              tools.fieldType = {
                text: "Company",
                value: "company",
              };
              if (sel_query?.sources) {
                tools.openCorporatesCheck = sel_query.sources.includes(
                  "opencorporates"
                );
                tools.clarifiedByCheck = sel_query.sources.includes(
                  "clarifiedby"
                );
                tools.companyWatchCheck = sel_query.sources.includes(
                  "companywatch"
                );
                tools.bvdCheck = sel_query.sources.includes("bvd");
                tools.dnbCheck = sel_query.sources.includes("db");
              }
              break;
            }
            case "officer": {
              tools.entityCount = 1;
              tools.queries = [];
              tools.searchKeyword = "";
              tools.address = "";
              tools.jurisdiction = "";
              tools.officer = sel_query?.officer ?? "";
              tools.selectVal = sel_query?.officer
                ? [sel_query?.officer]
                : [""];
              tools.fieldType = {
                text: "Officer",
                value: "officer",
              };
              let jurisdictionName = null;
              if (sel_query.code) {
                jurisdictionName = tools.jurisdictionList[0].categories.find(
                  (n) => n.value === sel_query.code
                );
              }
              tools.jurisdiction = jurisdictionName ?? null;
              if (sel_query.mode) {
                let search_mode_find = tools.searchMode?.props?.options?.find(
                  (ele) => ele.value === sel_query.mode
                );
                tools.searchMode.value = search_mode_find;
              }

              if (sel_query?.sources) {
                tools.openCorporatesCheck = sel_query.sources.includes(
                  "opencorporates"
                );
                tools.clarifiedByCheck = sel_query.sources.includes(
                  "clarifiedby"
                );
                tools.companyWatchCheck = sel_query.sources.includes(
                  "companywatch"
                );
                tools.bvdCheck = sel_query.sources.includes("bvd");
              }
              break;
            }
            case "address":
              tools.entityCount = 1;
              tools.queries = [];
              tools.searchKeyword = "";
              tools.officer = "";
              tools.jurisdiction = "";
              tools.address = sel_query?.address ?? "";
              tools.fieldType = {
                text: "Address",
                value: "address",
              };

              break;
            default:
              break;
          }
          tools.showMoreInfo = false;
          tools.selectedQuery = null;
          tools.selectedCard = null;
          tools.triggerRoute = false;
          await tools.search();
          break;
        }
        case "lexis-bridger-tool": {
          await tools.resetFilter();
          tools.lexis_request_id = query_id;
          tools.showResult = false;
          tools.items_count = 10;
          // tools.fillYears();
          // tools.fetchAllJuridictions();
          var {
            entity_type,
            person_entity,
            company_entity,
            additional_info,
            ids,
            phones,
            addresses,
          } = sel_query;

          if (entity_type || additional_info || ids || phones || addresses) {
            if (entity_type === "Individual") {
              tools.entityType = "person";
              tools.fName = {
                name: person_entity.first_name,
                value: person_entity.first_name,
              };
              tools.mName = {
                name: person_entity.middle_name,
                value: person_entity.middle_name,
              };
              tools.lName = {
                name: person_entity.last_name,
                value: person_entity.last_name,
              };
            } else {
              tools.entityType = "company";
              tools.selectedItem = {
                name: company_entity.name,
                value: company_entity.name,
              };
            }

            if (ids) {
              for (let index in ids) {
                const type = ids[index].type;
                const value = ids[index].number;
                if (type === "National") tools.nationalId.value = value;
                else if (type === "DUNS") tools.dunsId.value = value;
                else tools[type.toLowerCase()].value = value;
              }
            }

            if (phones) {
              for (let index in phones) {
                const type = phones[index].type;
                const value = phones[index].number;
                tools[type.toLowerCase()].value = value;
              }
            }

            if (addresses) {
              for (let index in addresses) {
                const type = addresses[index].type;
                let key;
                if (type === "Current") key = "curr";
                else if (type === "Previous") key = "prev";
                else if (type === "Mailing") key = "mailing";
                tools[`${key}_address1`].value = addresses[index].street1;
                tools[`${key}_address2`].value = addresses[index].street2;
                tools[`${key}_city`].value = addresses[index].city;
                tools[`${key}_state`].value = addresses[index]
                  .stateprovincedistrict
                  ? tools[`${key}_state`].props.options?.[0].categories?.find(
                      (opt) =>
                        opt.value === addresses[index].stateprovincedistrict
                    )
                  : null;
                tools[`${key}_postalCode`].value = addresses[index].postalcode;
                tools[`${key}_lexisCountry`].value = addresses[index].country
                  ? tools[
                      `${key}_lexisCountry`
                    ].props.options?.[0].categories?.find(
                      (opt) => opt.value === addresses[index].country
                    )
                  : null;
              }
            }

            if (additional_info) {
              for (let index in additional_info) {
                const type = additional_info[index].type;
                const value = additional_info[index].number || additional_info[index].value;
                if (type === "Citizenship")
                  tools.citizenship.value = value
                    ? tools.citizenship.props.options?.[0].categories?.find(
                        (opt) => opt.value === value
                      )
                    : null;
                else if (type !== "DOB")
                  tools[type.toLowerCase()].value = value;
                else {
                  tools["lexisDateOfBirth"].drops[0].value = additional_info[
                    index
                  ].date.date
                    ? parseInt(additional_info[index].date.date, 10).toString()
                    : "";
                  tools["lexisDateOfBirth"].drops[1].value = additional_info[
                    index
                  ].date.month
                    ? tools["lexisDateOfBirth"].drops[1].props.options[
                        parseInt(additional_info[index].date.month, 10) - 1
                      ]
                    : "";
                  tools["lexisDateOfBirth"].drops[2].value =
                    additional_info[index].date.year;
                }
              }
            }
            await tools.mapInputFilters();
            tools.triggerRoute = false;
            // tools.clearFilters();
            await tools.search();
          }
          break;
        }
      }
    },
  },
  watch: {
    async "$route.query.query_id"(to, from) {
        //Had to do this because of not getting data from tools-status API.
      setTimeout(async()=>{
        if (to !== from) {
          if (this.$route.query.check_name && this.$route.query.query_id) {
            await this.handleRefreshQueries();
            let query_id = this.$route.query.query_id;
            let selected_query;
            if (this.tabName === "monitoring" || this.tabName === "monitoring-ro")
              selected_query = this.fetchSearchedQueries?.find(
                (el) => el._id === query_id && el.is_monitoring
              );
            else
              selected_query = this.fetchSearchedQueries?.find(
                (el) => el._id === query_id
              );
            const { search_queries } = this.$refs;
            if (selected_query) search_queries.handleClick(selected_query, true);
          } else if (
            this.$route.params.check_name &&
            !this.$route.query.query_id &&
            !this.isSearchQueryClicked
          ) {
            const { search_queries } = this.$refs;
            search_queries.handleClick();
          }
        }
      }, 3000)
    },
  },
};

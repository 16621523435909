const InputField = () => import("../components/form-components/input.vue");
const TextareaField = () => import("../components/form-components/textarea.vue");
const RadioField = () => import("../components/form-components/radio.vue");
const CheckboxField = () => import("../components/form-components/checkbox.vue");
const DropdownField = () => import("../components/form-components/dropdown.vue");
const ToggleField = () => import("../components/form-components/toggle.vue");
const DateField = () => import("../components/form-components/date.vue");
const DateRangeField = () => import("../components/form-components/date-range.vue");
const FileField = () => import("../components/form-components/file_upload.vue");
const MultiselectField = () => import("../components/form-components/multiselect.vue");
const SignatureField = () => import("../components/form-components/signature.vue");
const ConsentField = () => import("../components/form-components/consent.vue");
const Text = () => import("../components/form-components/text.vue");
const LinkedButton = () =>  import("../components/form-components/linkedbutton.vue");
const CameraCaptureField = () =>  import("../components/form-components/camera-capture");
const RichText = () => import("../components/form-components/rich-text.vue");
const PhoneField = () => import("../components/form-components/phone.vue");

import { email, helpers } from "vuelidate/lib/validators";
import { isJson } from ".";

const validators = {
  // date_range: () => true
  date_range: (val) => {
    let parsed = val;
    if (isJson(val)) parsed = JSON.parse(val);
    return !parsed || (!!parsed.from && !!parsed.to)
  },
}

// * Use lowercase for fields' type names (object keys)
// Both field_type and field_base_type can be set as a key.
// In the formField.vue component the field type config is searched first by field_type,
// and secondly by field_base_type.
const fieldTypes = {
  'linked button':{
    tag: LinkedButton
  },
  'label':{
    tag: Text,
    // skipValidation: true,
  },
  'label info rich text':{
    tag: RichText,
    customTemplate: 'label',
    // skipValidation: true,
  },
  'input': {
    tag: InputField,
  },
  'large text': {
    tag: TextareaField,
  },
  'number': {
    tag: InputField,
    props: {
      type: 'number',
    },
  },
  'radio': {
    tag: RadioField,
  },
  'radio group': {
    tag: RadioField,
  },
  'single select': {
    tag: DropdownField,
  },
  'multi select': {
    tag: MultiselectField,
  },
  'date': {
    tag: DateField,
  },
  'date range': {
    tag: DateRangeField,
    validators: {
      date_range: helpers.withParams({
        $message: 'Please enter a valid date range'
      }, validators.date_range),
    }
  },
  'date range (timeline)': {
    tag: DateRangeField,
    validators: {
      date_range: helpers.withParams({
        $message: 'Please enter a valid date range'
      }, validators.date_range),
    }
  },
  'file': {
    tag: FileField,
  },
  'file upload (multi)': {
    tag: FileField,
    props: {
      settings: {
        'allow-multiple': true,
      },
    },
  },
  'checkbox': {
    tag: CheckboxField,
  },
  'checkbox group': {
    tag: CheckboxField,
  },
  'toggle': {
    tag: ToggleField,
  },
  // this particular type 'consent' has additional handlers at form-builder.js:
  'consent': {
    tag: ConsentField,
    customTemplate: 'consent',
  },
  // but this consent doesn't require agreement:
  'optional consent': {
    tag: ConsentField,
    customTemplate: 'consent',
  },
  'signature': {
    tag: SignatureField,
  },
  'email': {
    tag: InputField,
    validators: {
      email: helpers.withParams({
        $message: 'Please enter a valid email'
      }, email)
    }
  },
  'webcapture': {
    tag: CameraCaptureField,
  },
  'candidate phone': {
    tag: PhoneField,
  },
}

export default fieldTypes;
<template>
    <div>
      <div
        v-for="(ent, entIndex) in CaseCheckFormPreviewData.sections"
        :key="entIndex"
      >
        <div v-for="(item, index) in ent.entities" :key="item + index">
          <div
            v-if="item.is_comment"
            class="bg-white p-5"
          >
            <span class="flex gap-x-4 text-black mb-5">
              <analystSvg /> Analyst
            </span>
            <span><b>{{ item.label }}</b><span style="color: red;">*</span></span>
            <FormulateInput
              v-if="item.field_type=='Large text'"
              class="rounded-2xl mt-2"
              type="textarea"
              :value="item.value"
              rows="7"
              style="background-color: rgb(237, 242, 247);"
              @change="handlemyComments($event, item)"
            />
            <FormulateInput
              v-else-if="item.field_type=='Input'"
              class="rounded-2xl mt-2"
              type="text"
              :value="item.value"
              style="background-color: rgb(237, 242, 247);"
              @change="handlemyComments($event, item)"
            />
            <FormulateInput
              v-else-if="item.field_type=='File'"
              class="rounded-2xl mt-2"
              type="file"
              :value="item.value"
              style="background-color: rgb(237, 242, 247);"
              @change="handlemyComments($event, item)"
            />
            <FormulateInput
              v-else-if="item.field_type=='Signature'"
              class="rounded-2xl mt-2"
              type="signature"
              :value="item.value"
              style="background-color: rgb(237, 242, 247);"
              @change="handlemyComments($event, item)"
            />
            <FormulateInput
              v-else-if="item.field_type=='Radio'"
              class="rounded-2xl mt-2"
              type="text"
              :value="item.value"
              style="background-color: rgb(237, 242, 247);"
              @change="handlemyComments($event, item)"
            />
            <select
                v-else-if="item.field_type == 'Single select'"
                class="select select-bordered w-full"
                :class="{
                  'border-box-stroke': !error,
                  'border-red-600': error,
                }"
                style="background-color: rgb(237, 242, 247)"
                :value="item.value"
                @change="handlemyComments($event, item)"
              >
                <option selected hidden value="">
                  {{ item.value }}
                </option>
                <option
                  :value="option.option_value"
                  v-for="(option, idx) in item.options"
                  :key="idx"
                  :title="option.option_label"
                >
                  {{
                    option.option_label.length > 50
                      ? option.option_label.substring(0, 50) + "..."
                      : option.option_label
                  }}
                </option>
              </select>
          </div>
          <div v-else>
            <div v-for="(inItem, index) in item.entities" :key="item + index">
              <div
                v-if="inItem.is_comment"
                class="bg-white p-5"
              >
                <span class="flex gap-x-4 text-black mb-5">
                  <analystSvg /> Analyst
                </span>
                <span><b>{{ inItem.label }}</b><span style="color: red;">*</span></span>
                <FormulateInput
                  v-if="inItem.field_type=='Large text'"
                  class="rounded-2xl mt-2"
                  type="textarea"
                  :value="inItem.value"
                  rows="7"
                  style="background-color: rgb(237, 242, 247);"
                  @change="handlemyComments($event, inItem)"
                />
                <FormulateInput
                  v-else-if="inItem.field_type=='Input'"
                  class="rounded-2xl mt-2"
                  type="text"
                  :value="inItem.value"
                  style="background-color: rgb(237, 242, 247);"
                  @change="handlemyComments($event, inItem)"
                />
                <FormulateInput
                  v-else-if="inItem.field_type=='File'"
                  class="rounded-2xl mt-2"
                  type="file"
                  :value="inItem.value"
                  style="background-color: rgb(237, 242, 247);"
                  @change="handlemyComments($event, inItem)"
                />
                <FormulateInput
                  v-else-if="inItem.field_type=='Signature'"
                  class="rounded-2xl mt-2"
                  type="signature"
                  :value="inItem.value"
                  style="background-color: rgb(237, 242, 247);"
                  @change="handlemyComments($event, inItem)"
                />
                <FormulateInput
                  v-else-if="inItem.field_type=='Radio'"
                  class="rounded-2xl mt-2"
                  type="text"
                  :value="inItem.value"
                  style="background-color: rgb(237, 242, 247);"
                  @change="handlemyComments($event, inItem)"
                />
                <FormulateInput
                  v-else-if="inItem.field_type=='Single select'"
                  class="rounded-2xl mt-2"
                  type="text"
                  :value="inItem.value"
                  style="background-color: rgb(237, 242, 247);"
                  @change="handlemyComments($event, inItem)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end mt-3 mb-3">
        <button
        class="sequence-save-btn inline-block px-6 py-2.5 pl-5 pr-5 h-10 w-32 text-white-text font-bold text-sm leading-tight rounded-xl shadow-md mt-1"
        style="background: #0d69d5"
        @click="saveSection"
      >
        Save
      </button>
      </div>
    </div>
  </template>
  


<script>
import analystSvg from "@/assets/svg/analyst.svg"
import axios from "@/axios";
export default {
    data() {
        return {
            comments: "",
            commentDetails: [],
            fileValue: '',
        };
    },
    components: {
        analystSvg,
    },
    props: {
        CaseCheckFormPreviewData: {
            type: Object,
            required: true,
        },
    },
    methods: {
        handlemyComments(e, item) {
          item.value = e.target.value;
        },
        async saveSection() {
            this.CaseCheckFormPreviewData.sections.map((ent)=>{
                ent.entities = ent.entities.map((item, index) => {
                if (item.form_entity_type == 'FIELD' && item.is_comment) {
                    ent.entities[index].field_fe_id = ent.entities[index].field_id
                    ent.entities[index].field_serial = 1
                    return { ...item, name: ent.entities[index].name };
                } else if (item.form_entity_type == 'BLOCK' && item.entities) {
                    const updatedEntity = item.entities.map((entityItem, entityIndex) => {
                      ent.entities[index].entities[entityIndex].field_serial = 1
                        if (entityItem.is_comment || !ent.entities[index].block_fe_id) {
                            ent.entities[index].block_fe_id = ent.entities[index].block_id
                            ent.entities[index].serial = 1
                            
                            return { ...entityItem, name: ent.entities[index].entities[entityIndex].name };
                        }
                        return entityItem;
                    });
                    return { ...item, entities: updatedEntity };
                } else {
                    return item;
                }
            });
            }) 
            let payload = this.CaseCheckFormPreviewData
            let sectionSaved = false;
            const case_id = this.$route.query.caseid;
            let url = `/case/form/${case_id}`;
            try {
                await axios.post(url, payload);
                sectionSaved = true;
                this.$toast.success(`Saved successfully!`);
            } catch (error) {
                const { error: resp } = error?.response?.data?.detail || {};
                console.log("error", resp)
                sectionSaved = false;
                throw error;
            }
            return sectionSaved;
        },
    },

}
</script>

<style scoped></style>